import React from 'react';
import styled from "styled-components";
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

import { isArrayExists } from '../../helpers/validation';

import theme from '../../theme';

class FormMultiSelect extends React.Component {

    handleChange = (event) => {
        const { name } = this.props;
        if ( this.props.onChange ) {
            this.props.onChange(event.target.value,name);
        }
    }

    renderValue = selected => {
        const { options } = this.props;
        var string = '';
        if ( selected && isArrayExists( selected ) ) {
            selected.forEach(value => {
                var item = _find( options, { value } );
                string += ( !_isEmpty( string ) ? ', ' : '' ) + ( item && item.label || '' );
            });
        }
        return string;
    }

    render() {
        const { id, label, value, options, disabled } = this.props;
        var newID = 'multi-select-' + _random(1,9999);
        return (
        <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
            <FormControl fullWidth={true}>
                <InputLabel htmlFor={( id || newID )} style={{ fontSize: "16px", fontWeight: "700", textTransform: "uppercase" }}>{label || '' }</InputLabel>
                <Select
                    multiple
                    value={( value && isArrayExists( value ) ? value : [] )}
                    onChange={this.handleChange}
                    input={ disabled ? <FilledInput id={( id || newID )} fullWidth={true} /> : <Input id={( id || newID )} fullWidth={true} />}
                    renderValue={this.renderValue}
                    disabled={( disabled || false )}
                    MenuProps={{
                        PaperProps: {
                            style: { width: 250, background: "#fff" }
                        }
                    }}>
                    { options && isArrayExists( options ) ? options.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            <Checkbox color="primary" checked={value && !_isEmpty( value ) && value.indexOf(option.value) > -1} />
                            <ListItemText primary={option.label} />
                        </MenuItem>
                    )) : null }
                </Select>
            </FormControl>
        </div>
        )
    }

}

export default FormMultiSelect