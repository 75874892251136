import _random from 'lodash/random';

import { 
    TOOLS_EXPORT_CONTACTS,
    TOOLS_IMPORT_CONTACTS,
    TOOLS_RESET_EXPORT,
    TOOLS_RESET_IMPORT
} from '../actions/types';

const initialState = {
    export_contacts: null,
    rand: false
};

export const tools = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TOOLS_EXPORT_CONTACTS:
            return {
                ...state,
                export_contacts: payload,
                rand: _random(1,9999)
            };
        case TOOLS_IMPORT_CONTACTS: 
            return {
                ...state,
                import_results: _random(1,9999)
            }; 
        case TOOLS_RESET_EXPORT:
            return {
                ...state,
                export_contacts: null,
                rand: false
            };   
        case TOOLS_RESET_IMPORT:
            return {
                ...state,
                import_results: null
            }; 
        default:
            return state;
    }
}