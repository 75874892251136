import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from "@material-ui/core/styles";
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _endsWith from 'lodash/endsWith';
import _replace from 'lodash/replace';
import _remove from 'lodash/remove';
import _map from 'lodash/map';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormCheckbox from '../../components/FormCheckbox';

import { InfoButton, ErrorButton, ButtonGroup, AInfoLink, SuccessButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';

import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSuperAdmin } from '../../helpers/auth';

import { addNewReplytoEmail, updateReplytoEmail, deleteReplytoEmail } from '../../actions/m_replyto_emails';


const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});

const AccessTag = styled.div`
    display: inline-block;
    padding: 5px 8px;
    margin: 0px 5px 5px 0px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 6px;
    color: #fff;
    background: ${props => (props.access && props.access == 'yes' ? green['500'] : grey['500'])};
`;

class Emails extends React.Component {
    
    state = {
        filterBy: 'all',
        sortBy: 'email-asc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handleFormUpdate = (newValue,key,status) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );

        if ( key == 'orgs' ) {
            var newOrgs = ( modalData.orgs && isArrayExists( modalData.orgs ) ? cloneCollections(modalData.orgs) : [] );
            if ( status == 'checked' ) {
                if ( !_find( newOrgs, { id: newValue } ) )
                    newOrgs.push({ id: newValue });
            } else {
                var pulled = _remove( newOrgs, { id: newValue } );
            } // end - status
            newData[key] = newOrgs;
        } else {
            newData[key] = newValue;
        }

        this.setState({ modalData: newData });
    }

    handleAddNew = () => {
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.orgs && isArrayExists( modalData.orgs ) ) ) {
            error = 'Please select at least one organisation(s)';
        } // end - modalData.orgs

        if ( !( modalData && modalData.label && !_isEmpty( modalData.label ) ) ) {
            error = 'Please insert a valid label';
        } // end - modalData.label

        if ( !( modalData && modalData.email && validateEmail( modalData.email ) ) ) {
            error = 'Please insert a valid email';
        } // end - modalData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addNewReplytoEmail(modalData));
        } // end - error
    }

    handleEdit = () => {
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.label && !_isEmpty( modalData.label ) ) ) {
            error = 'Please insert a valid label';
        } // end - modalData.label

        if ( !( modalData && modalData.email && validateEmail( modalData.email ) ) ) {
            error = 'Please insert a valid email';
        } // end - modalData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateReplytoEmail(modalData));
        } // end - error
    }

    handleAction = () => {
        const { modalType } = this.state;
        if ( modalType && modalType == 'new' ) {
            this.handleAddNew();
        } else if ( modalType && modalType == 'edit' ) {
            this.handleEdit();
        } // end - modalType
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteReplytoEmail(deleteModal.id));
    }

    reorganizeData() {
        const { filterBy, sortBy, perPage, page } = this.state;
        const { emails, authData } = this.props;
        var items = ( emails ? cloneCollections( emails ) : [] ),
            total = _size( items );

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['created_on'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, ['email'] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, ['email'] );
                    break;
            }
        } // end - sortBy

        // do filter
        if ( isSuperAdmin( authData ) ) {
            // only super admin can do filter for all the orgs
            if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
                items = _filter( items, (o) => ( o.orgs && isArrayExists( o.orgs ) && _find( o.orgs, { id: filterBy }) ? true : false ) );
                total = _size( items );
            }
        } else {
            // if is admin, then prefilter the org based on their org
            items = _filter( items, (o) => ( o.orgs && isArrayExists( o.orgs ) && authData.orgs && !_isEmpty( authData.orgs ) && _find( o.orgs, (p) => authData.orgs.indexOf(p.id) >= 0 ) ? true : false ) );
            total = _size( items );
        } // end - isSuperAdmin

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderViewForm = () => {
        const { authData, orgs } = this.props;
        const { modalData, modalType } = this.state;
        return (
        <div>
            <FormInput label="Email" type="email" name="email" value={( modalData.email || '' )} disabled={( modalType && modalType === 'edit' ? true : false )} onChange={this.handleFormUpdate} />
            <FormInput label="Label" name="label" value={( modalData.label || '' )} onChange={this.handleFormUpdate} />
            <Grid container spacing={0} style={{ padding: "0px 10px" }}>
            { orgs && isArrayExists( orgs ) ? _map( orgs, org => {
                var disabled = ( isSuperAdmin( authData ) ? false : ( authData.orgs && !_isEmpty( authData.orgs ) && authData.orgs.indexOf(org.id) >= 0 ? false : true ) );
                return (
                <Grid key={org.id} item xs={6}>
                    { modalType && modalType === 'new' && disabled ? null : <FormCheckbox label={org.label} name="orgs" 
                        value={org.id} 
                        checked={( modalData.orgs && isArrayExists( modalData.orgs ) && _find( modalData.orgs, { id: org.id } ) ? org.id : false )} 
                        disabled={disabled} // disabled it if current user is from different org
                        onChange={this.handleFormUpdate} /> }
                </Grid>
                );
            }) : null }
            </Grid>
            
        </div>
        );
    }

    renderTableActions = () => {
        const { authData, orgs } = this.props;
        const { sortBy, perPage, searchterms, filterBy } = this.state;
        return <TableBar
                show={['sort','entries','filter']}
                filterBy={filterBy}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Entries first' },
                    { value: 'date-asc', label: 'Oldest Entries first' },
                    { value: 'email-asc', label: 'Email ( A - Z)' },
                    { value: 'email-desc', label: 'Email ( Z - A )' }
                ]}
                filterByOptions={( authData && authData.type && authData.type == 'superadmin' ? getSelectOptions({ list: orgs, options: [{ value: 'all', label: 'All' }], keys: { value: 'id', label: 'label' }, sortBy: 'label' }) : false )}
                rightButtons={[
                    <InfoButton minWidth="128px" key="addnew" style={{ marginRight: "5px" }} onClick={() => this.setState({ openViewModal: true, modalData: { email: '', type: '', orgs: [] }, modalType: 'new' })}><i className="fa fa-plus-circle"></i>Add New</InfoButton>
                ]}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes, orgs } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell}>{ item.email ? <WrapWord>{item.email}</WrapWord> : '' }</TableCell>
                    <TableCell className={classes.bodycell}>{ item.label || '' }</TableCell>
                    <TableCell className={classes.bodycell}>{ item.orgs && isArrayExists( item.orgs ) ? item.orgs.map(org => {
                            var selected = _find( orgs, { id: org.id } );
                            return ( selected && selected.label ? <div key={org.id} style={{ fontSize: '0.875em', marginBottom: "4px" }}>{selected.label}</div> : null );
                        }) : '---' }</TableCell>
                    <TableCell className={classes.bodycell}>{ item.created_on ? getMomentTime( item.created_on, 'YYYY-MM-DD' ) : '-'}</TableCell>
                    <TableCell className={classes.bodycell}>
                        <ButtonGroup>
                            <InfoButton size="small" onClick={() => this.setState({ openViewModal: true, modalData: item, modalType: 'edit' })}><i className="fa fa-edit"></i>Edit</InfoButton>
                            <ErrorButton size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton>
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No Email(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell} style={{ width: "20%" }}>Email</TableCell>
                <TableCell className={classes.headcell}>Label</TableCell>
                <TableCell className={classes.headcell}>Organisation(s)</TableCell>
                <TableCell className={classes.headcell}>Created On</TableCell>
                <TableCell className={classes.headcell} style={{ width: "20%" }}>Actions</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { openDeleteModal, deleteModal, openViewModal, modalType } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title={ modalType && modalType == 'edit' ? "Edit Email" : "Add New Email" }
                actionLabel={ modalType && modalType == 'edit' ? "Update" : "Add New" }
                onClose={() => this.setState({ openViewModal: false, modalType: false, modalData: false })}
                doAction={this.handleAction}
                contents={this.renderViewForm()} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.email ? `Are you sure you want to delete this email ( ${deleteModal.email} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(Emails);