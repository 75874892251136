import * as firebase from 'firebase/app';
import shortid from 'shortid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { getIDToken } from './auth';
import { doPromise } from '../helpers/action';
import { isArrayExists } from '../helpers/validation';

/* helpers start */
// add contacts to pre-import queue
const addContactstoImportQueue = (formData) => {
    return new Promise((resolve,reject) => {

        var queue_id = shortid.generate(),
            queueCount = 0,
            contactCount = 0,
            updateData = {};

        if ( formData && isArrayExists( formData ) ) {
            queueCount++; // start queue at 1
            updateData["contacts_import_queue/"+queue_id+"/queue/q"+queueCount+"/queue_num"] = queueCount;

            _forEach( formData, contact => {
                contactCount++; // start contact count

                // separate by 200 contacts for each queue
                if ( contactCount > 200 ) {
                    contactCount = 1;
                    queueCount++;
                    updateData["contacts_import_queue/"+queue_id+"/queue/q"+queueCount+"/queue_num"] = queueCount;
                } // end - contactCount

                var contact_id = ( contact.id && !_isEmpty( contact.id ) ? contact.id : shortid.generate() );
                updateData["contacts_import_queue/"+queue_id+"/queue/q"+queueCount+"/contacts/"+contact_id+"/name"] = ( contact.name || '' );
                updateData["contacts_import_queue/"+queue_id+"/queue/q"+queueCount+"/contacts/"+contact_id+"/first_name"] = ( contact.first_name || '' );
                updateData["contacts_import_queue/"+queue_id+"/queue/q"+queueCount+"/contacts/"+contact_id+"/last_name"] = ( contact.last_name || '' );
                updateData["contacts_import_queue/"+queue_id+"/queue/q"+queueCount+"/contacts/"+contact_id+"/email"] = ( contact.email || '' );
                updateData["contacts_import_queue/"+queue_id+"/queue/q"+queueCount+"/contacts/"+contact_id+"/subscribed"] = ( contact.subscribed || '' );
                updateData["contacts_import_queue/"+queue_id+"/queue/q"+queueCount+"/contacts/"+contact_id+"/bounced"] = ( contact.bounced || '' );
            });
        } // end - formData

        updateData["contacts_import_queue/"+queue_id+"/total_queue"] = queueCount;

        firebase.database().ref().update( updateData, error => {
            if ( error ) {
                reject(error);
            } else {
                resolve(queue_id);
            }
        });

    })
}

const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_performImport = (formData,type) => {
    return new Promise((resolve,reject) => {

        addContactstoImportQueue(formData)
        .then( queue_id => {

            getIDToken()
            .then(idToken => {

                doPromise({
                    idToken,
                    method: 'post',
                    url: 'import',
                    params: [{ key: 'action', value: type }],
                    formData: { id: queue_id }
                })
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    reject(error);
                });

            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}