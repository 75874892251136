import _random from 'lodash/random';

import {
    M_GET_ORGS,
    M_ORGS_UPDATED,
	GLOBAL_ERROR_TRIGGER
} from './types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from './global';
import { triggerErrorAlert, triggerSuccessAlert } from '../helpers/alert';
import { appChangesReset } from './misc';

import { fb_getOrgs, fb_addOrg, fb_editOrg, fb_deleteOrg } from '../firebase/m_orgs';

export const getOrgs = () => {
	return dispatch => {

		fb_getOrgs(list => {
			dispatch({ 
                type: M_GET_ORGS, 
                payload: { list } 
            });
		});

	}
}

export const addOrg = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addOrg(formData)
		.then( results => {
			dispatch({ type: M_ORGS_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Organisation Added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new organisation' ) );
			triggerErrorAlert(errMsg);
		});
        
  }
}

export const editOrg = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_editOrg(formData)
		.then( results => {
			dispatch({ type: M_ORGS_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Organisation Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update organisation' ) );
			triggerErrorAlert(errMsg);
		});
        
  }
}

export const deleteOrg = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteOrg(id)
		.then( results => {
			dispatch({ type: M_ORGS_UPDATED });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Organisation Deleted");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete organisation' ) );
			triggerErrorAlert(errMsg);
		});

  	}
}