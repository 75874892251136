import { combineReducers } from "redux";

import { auth } from "./auth";
import { global } from "./global";
import { contacts } from "./contacts";
import { lists } from "./lists";
import { users } from "./users";
import { email_broadcasts } from './email_broadcasts';
import { reports } from './reports';
import { tools } from './tools';
import { emailtemplates } from './email_templates';
import { media_library } from './media_library';
import { maintenance } from './maintenance';
import { misc } from "./misc";

export default combineReducers({ 
    auth, 
    global,
    contacts,
    lists,
    users,
    email_broadcasts,
    reports,
    tools,
    emailtemplates,
    media_library,
    maintenance,
    misc
});