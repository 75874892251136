import * as firebase from 'firebase/app';

import { getIDToken } from './auth';
import { doPromise } from '../helpers/action';
import { isArrayExists } from '../helpers/validation';

export const fb_getUsersLive = (callback) => {
    
    firebase.firestore().collection("users").onSnapshot(snapshot => {
        
        var users  = [];

        if ( snapshot && !snapshot.empty ) {
            snapshot.forEach( doc => {
                var val = doc.data();
                users.push({
                    uid: doc.id,
                    name: val.name || '',
                    email: val.email || '',
                    status: val.status || 'disabled',
                    type: val.type || 'noaccess',
                    role: val.role || '',
                    orgs: val.orgs || [],
                    orgs_search: val.orgs_search || [],
                    created_on: val.created_on || 0
                })
            });
        } // end - snapshot
        
        callback(users);

    });

}

export const fb_getUsers = (orgs) => {
    return new Promise((resolve,reject) => {

        firebase.firestore().collection("users").get()
        .then(snapshot => {
        
            var users  = [];
    
            if ( snapshot && !snapshot.empty ) {
                snapshot.forEach( doc => {
                    var val = doc.data();
                    users.push({
                        uid: doc.id,
                        name: val.name || '',
                        email: val.email || '',
                        status: val.status || 'disabled',
                        type: val.type || 'noaccess',
                        role: val.role || '',
                        orgs: val.orgs || [],
                        orgs_search: val.orgs_search || [],
                        created_on: val.created_on || 0
                    })
                });
            } // end - snapshot
            
            resolve(users);
    
        })
        .catch(error => {
            reject(error);
        });
        

    })
}

export const fb_addUser = (formData) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'users',
                params: [{ key: 'action', value: 'add' }],
                formData
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_editUser = (formData) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'users',
                params: [{ key: 'action', value: 'update' }],
                formData
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteUser = (uid) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'users',
                params: [{ key: 'action', value: 'delete' }],
                formData: { uid }
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_syncUsers = () => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'sync',
                params: [{ key: 'action', value: 'sync_users' }]
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}