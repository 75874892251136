import React from 'react';
import ReactDOM from 'react-dom';
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/firestore";

import './reset.css';
import './styles.css';
import App from './app';
import * as serviceWorker from './serviceWorker';

// init fb
firebase.initializeApp({
    apiKey: "AIzaSyAf9J2VHr6Ky13RONiIabouxyzVN9WK7_c",
    authDomain: "dreamcatcher-mailing.firebaseapp.com",
    databaseURL: "https://dreamcatcher-mailing.firebaseio.com",
    projectId: "dreamcatcher-mailing",
    storageBucket: "dreamcatcher-mailing.appspot.com",
    messagingSenderId: "628042651213",
    appId: "1:628042651213:web:3bcffbe7a5bc7dfa"
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
