import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import _find from 'lodash/find';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _forEach from 'lodash/forEach';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormInput from '../../components/FormInput';

import { InfoButton, ErrorButton, ButtonGroup, AInfoLink } from '../../styles/button';
import { SuccessTag, ErrorTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isSuperAdmin } from '../../helpers/auth';
import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';

import { addNewContact, deleteContact } from '../../actions/contacts';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});


class Contacts extends React.Component {
    
    state = {
        searchterms: '',
        sortBy: 'date-desc',
        filterBy: 'all',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handleFormUpdate = (newValue,key) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    handleAddNew = () => {
        const { history } = this.props;
        const { modalData } = this.state;
        var error = false,
            formData = cloneCollections( modalData );

        if ( !( modalData && modalData.lists && isArrayExists( modalData.lists ) ) ) {
            error = 'Please select at least one list';
        } // end - modalData.email

        if ( !( modalData && modalData.email && validateEmail( modalData.email ) ) ) {
            error = 'Please enter a valid email address';
        } // end - modalData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            // re-compile the lists for API
            formData.lists = [];
            _forEach( modalData.lists, list => {
                formData.lists.push({ id: list });
            });

            this.props.dispatch(addNewContact(formData,history,this.getQueryProps()));
        } // end - error
    }

    handleEdit = (id,event) => {
        event.preventDefault();
        this.props.history.push("/contacts/"+id);
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deleteContact(deleteModal.id,this.getQueryProps()));
    }

    getQueryProps = () => {
        return false;
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { contacts, lists, authData } = this.props;
        var items = ( contacts ? cloneCollections( contacts ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email','subscribed'] );
			total = _size( items );
        } // end - searchterms

        // if is user / admin, then prefilter the org based on their org
        if ( !isSuperAdmin( authData ) ) {
            //items = _filter( items, (o) => ( o.org && authData.orgs && !_isEmpty( authData.orgs ) && authData.orgs.indexOf(o.org) >= 0 ? true : false ) );
            items = _filter( items, (o) => ( o.lists && isArrayExists( o.lists ) && lists && isArrayExists( lists ) && _find( o.lists, (l) => ( l.id && _find( lists, { id: l.id } ) ? true : false ) ) ? true : false ) );
            total = _size( items );
        } // end - isSuperAdmin

        // do filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
            items = _filter( items, (o) => { return ( o.lists && isArrayExists( o.lists ) && _find( o.lists, { id: filterBy } ) ? true : false ); });
            total = _size( items );
        } // end - filterBy

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['created_on'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, [(o) => o.email.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, [(o) => o.email.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderAddNewForm = () => {
        const { modalData } = this.state;
        const { lists } = this.props;
        return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <FormInput label="First Name" name="first_name" value={( modalData.first_name || '' )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={6}>
                    <FormInput label="Last Name" name="last_name" value={( modalData.last_name || '' )} onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>
            <FormInput label="Email (Required)" name="email" type="email" value={( modalData.email || '' )} onChange={this.handleFormUpdate} />
            <FormMultiSelect 
                id='new-contact-list'
                label="Lists (Required)"
                name='lists' 
                value={( modalData.lists || [] )} 
                options={getSelectOptions({ list: lists, keys: { value: 'id', label: 'name' }, sortBy: 'label' })}
                onChange={this.handleFormUpdate} />
        </div>
        );
    }

    renderTableActions = () => {
        const { lists } = this.props;
        const { sortBy, perPage, searchterms, filterBy } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                filterBy={filterBy}
                filterByOptions={getSelectOptions({ list: lists, options: [{ value: 'all', label: 'All Lists' }], keys: { value: 'id', label: 'name' }, sortBy: 'label' })}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Entries first' },
                    { value: 'date-asc', label: 'Oldest Entries first' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    { value: 'email-asc', label: 'Email ( A - Z)' },
                    { value: 'email-desc', label: 'Email ( Z - A )' }
                ]}
                rightButtons={[
                    <InfoButton minWidth="128px" key="addnew" style={{ marginRight: "5px" }} onClick={() => this.setState({ openViewModal: true, modalData: { name: '', email: '' } })}><i className="fa fa-plus-circle"></i>Add New</InfoButton>
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes, lists } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell}>
                        <WrapWord>{item.email || ''}</WrapWord>
                    </TableCell>
                    <TableCell className={classes.bodycell}>{( item.first_name || '' ) + ( item.last_name && !_isEmpty( item.last_name ) ? ' ' + item.last_name : '' )}</TableCell>
                    <TableCell className={classes.bodycell}>
                        { item.lists && isArrayExists( item.lists ) ? item.lists.map(list => {
                            var selected = _find( lists, { id: list.id } );
                            return ( selected && selected.name ? <div key={list.id} style={{ fontSize: '0.875em', marginBottom: "4px" }}>{selected.name}</div> : null );
                        }) : '---' }
                    </TableCell>
                    <TableCell className={classes.bodycell}>{item.subscribed && item.subscribed == 'yes' ? <SuccessTag>YES</SuccessTag> : <ErrorTag>NO</ErrorTag>}</TableCell>
                    <TableCell className={classes.bodycell}>{item.bounced && item.bounced == 'yes' ? <ErrorTag>YES</ErrorTag> : <SuccessTag>NO</SuccessTag>}</TableCell>
                    <TableCell className={classes.bodycell}>
                        <ButtonGroup>
                            <AInfoLink href={"/contacts/"+item.id} size="small" onClick={this.handleEdit.bind(this,item.id)}><i className="fa fa-edit"></i>Edit</AInfoLink>
                            <ErrorButton size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton>
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No Contact(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell}>Email</TableCell>
                <TableCell className={classes.headcell}>Name</TableCell>
                <TableCell className={classes.headcell}>List(s)</TableCell>
                <TableCell className={classes.headcell}>Subscribed?</TableCell>
                <TableCell className={classes.headcell}>Bounced?</TableCell>
                <TableCell className={classes.headcell} style={{ width: "20%" }}>Actions</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { openDeleteModal, deleteModal, openViewModal } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title="Add New Contact"
                onClose={() => this.setState({ openViewModal: false, modalData: false })}
                doAction={this.handleAddNew}
                contents={this.renderAddNewForm()} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this contact ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(Contacts);