import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormSelect from '../../components/FormSelect';
import FormInput from '../../components/FormInput';

import { InfoButton, ErrorButton, ButtonGroup, AInfoLink, InverseButton } from '../../styles/button';
import { SuccessTag, AmberTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';
import { getMomentTime, getMomentTimestamp } from '../../helpers/date';
import { isSuperAdmin, getUserFirstOrg } from '../../helpers/auth';

import { addNewList, deleteList } from '../../actions/lists';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});


class Lists extends React.Component {
    
    state = {
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh();
    }

    handleFormUpdate = (newValue,key) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    handleAddNew = () => {
        const { history } = this.props;
        const { modalData } = this.state;
        var error = false;

        if ( !( modalData && modalData.org && !_isEmpty( modalData.org ) ) ) {
            error = 'Please select a valid organisation';
        } // end - modalData.email

        if ( !( modalData && modalData.name && !_isEmpty( modalData.name ) ) ) {
            error = 'Please enter a valid name';
        } // end - modalData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addNewList(modalData,history));
        } // end - error
    }

    handleEdit = (id,event) => {
        event.preventDefault();
        this.props.history.push("/lists/"+id);
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deleteList(deleteModal.id));
    }

    getSelectableOrgs = (firstOption) => {
        const { orgs, authData } = this.props;
        var options = ( firstOption && !_isEmpty( firstOption ) ? [ firstOption ] : [{ value: '', label: 'Select an Option' }] );
        if ( isSuperAdmin( authData ) ) {
            options = getSelectOptions({ list: orgs, options, keys: { value: 'id', label: 'label' }, sortBy: 'label' });
        } else {
            options = getSelectOptions({ list: _filter( orgs, (o) => authData.orgs && !_isEmpty( authData.orgs ) && authData.orgs.indexOf(o.id) >= 0 ), options, keys: { value: 'id', label: 'label' }, sortBy: 'label' });
        } // end - isSuperAdmin
        return options;
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { lists, authData } = this.props;
        var items = ( lists ? cloneCollections( lists ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name'] );
			total = _size( items );
        } // end - searchterms

        // if is user / admin, then prefilter the org based on their org
        if ( !isSuperAdmin( authData ) ) {
            items = _filter( items, (o) => ( o.org && authData.orgs && !_isEmpty( authData.orgs ) && authData.orgs.indexOf(o.org) >= 0 ? true : false ) );
            total = _size( items );
        } // end - isSuperAdmin

        // do filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
            items = _filter( items, { org: filterBy });
            total = _size( items );
        }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    //items = _sortBy( items, [(o) => ( o.created_on && !_isEmpty( o.created_on ) ? getMomentTimestamp( o.created_on ) : 0 )] );
                    items = _sortBy( items, ['sort_date'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    //items = _sortBy( items, [(o) => ( o.created_on && !_isEmpty( o.created_on ) ? getMomentTimestamp( o.created_on ) : 0 )] );
                    items = _sortBy( items, ['sort_date'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderAddNewForm = () => {
        const { modalData } = this.state;
        return (
        <div>
            <FormInput label="Name (Required)" name="name" value={( modalData.name || '' )} onChange={this.handleFormUpdate} />
            <FormInput label="Description" name="description" value={( modalData.description || '' )} onChange={this.handleFormUpdate} />
            <div style={{ marginTop: '8px' }}><FormSelect 
                label="Organisation (Required)" 
                name="org" 
                value={( modalData.org || '' )} 
                options={this.getSelectableOrgs()}
                onChange={this.handleFormUpdate} /></div>
        </div>
        );
    }

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, filterBy, perPage, searchterms } = this.state;
        return <TableBar
                filterBy={filterBy}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Modified first' },
                    { value: 'date-asc', label: 'Oldest Modified first' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' }
                ]}
                filterByOptions={this.getSelectableOrgs({ value: 'all', label: 'All' })}
                rightButtons={[
                    <InfoButton minWidth="128px" key="addnew" style={{ marginRight: "5px" }} onClick={() => this.setState({ openViewModal: true, modalData: { name: '', description: '', org: getUserFirstOrg(authData) } })}><i className="fa fa-plus-circle"></i>Add New</InfoButton>,
                    <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
                ]}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes, orgs } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                var selected_org = ( orgs && isArrayExists( orgs ) ? _find( orgs, { id: item.org } ) : false );
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell}>{ item.status && item.status === 'importing' ? <AmberTag>Updating</AmberTag> : <SuccessTag>Ready</SuccessTag>}</TableCell>
                    <TableCell className={classes.bodycell}>{item.name || ''}</TableCell>
                    <TableCell className={classes.bodycell}><div style={{ fontSize: '0.875em' }}>{item.description || '-'}</div></TableCell>
                    <TableCell className={classes.bodycell}>{item.members_count || '0'}</TableCell>
                    <TableCell className={classes.bodycell}>{( selected_org && selected_org.label ? selected_org.label : '' )}</TableCell>
                    <TableCell className={classes.bodycell}>{ item.sort_date ? getMomentTime( item.sort_date, 'YYYY-MM-DD' ) : '-'}</TableCell>
                    <TableCell className={classes.bodycell}>
                        <ButtonGroup>
                            <AInfoLink href={"/lists/"+item.id} size="small" onClick={this.handleEdit.bind(this,item.id)}><i className="fa fa-edit"></i>Edit</AInfoLink>
                            <ErrorButton size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton>
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No List(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell}>Status</TableCell>
                <TableCell className={classes.headcell}>Name</TableCell>
                <TableCell className={classes.headcell}>Description</TableCell>
                <TableCell className={classes.headcell}>Total Contact(s)</TableCell>
                <TableCell className={classes.headcell}>Organisation</TableCell>
                <TableCell className={classes.headcell}>Last Modified On</TableCell>
                <TableCell className={classes.headcell} style={{ width: "20%" }}>Actions</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { openDeleteModal, deleteModal, openViewModal } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title="Add New List"
                onClose={() => this.setState({ openViewModal: false, modalData: false })}
                doAction={this.handleAddNew}
                contents={this.renderAddNewForm()} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this list ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(Lists);