import React from 'react';
import styled from "styled-components";
import Button from '@material-ui/core/Button';

class Controller extends React.Component {

    handleClick = action => event => {
        event.preventDefault();
        if ( this.props.onClick )
            this.props.onClick(action);
    }

    render() {
        const { step, lastStep } = this.props;
        return (
        <div style={{ textAlign: 'center', paddingTop: "45px" }}>
            <Button 
                disabled={step === 0} 
                style={{ fontSize: "1.75rem", paddingLeft: "25px", paddingRight: "25px" }} 
                onClick={this.handleClick('back')}>Back</Button>
            <Button
                variant="contained"
                color="primary"
                style={{ fontSize: "1.75rem" }}
                onClick={this.handleClick('next')}>
                { lastStep ? 'Done' : 'Next'}
            </Button>
        </div> 
        );
    }

}

export default Controller;