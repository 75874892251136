import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _random from 'lodash/random';

import AppWrapper from '../../components/AppWrapper';

import BroadcastStepper from './stepper';
import StepSettings from './step_settings';
import StepCreate from './step_create';
import StepLists from './step_lists';
import StepSummary from './step_summary';

import { cloneCollections } from '../../helpers/data';
import { isArrayExists } from '../../helpers/validation';
import { isSuperAdmin } from '../../helpers/auth';

import { getLists } from '../../actions/lists';
import { getEmailTemplates } from '../../actions/m_email_templates';
import { getReplytoEmails } from '../../actions/m_replyto_emails';
import { appChangesMade } from '../../actions/misc';
import { createBroadcast, getBroadcast } from '../../actions/email_broadcast';
import { resetRedux } from '../../actions/misc';

const defaultFormData = {
    status: 'draft',
    delivery_time: null,
    label: '',
    subject_line: '',
    from_id: 'none',
    from: '',
    from_label: '',
    template_id: 'none',
    template_html: '',
    template_css: '',
    template_js: '',
    lists: []
};

class EmailBroadcastNew extends React.Component {

    state = {
        step: 0,
        reuse_id: false,
        formData: defaultFormData,
        randNum: _random(1,9999)
    };

    componentDidMount() {
        const { emailTemplates, mailingLists, replyToEmailsList } = this.props;

        // get existing broadcast if re-use ID is avaliable 
        if ( this.props.location.search && !_isEmpty( this.props.location.search ) ) {
            const { reuse_id } = queryString.parse( this.props.location.search );
            if ( reuse_id && !_isEmpty( reuse_id ) ) {
                this.setState({ formData: false, reuse_id });
                // retrieve broadcast data via dispatch
                this.props.dispatch(getBroadcast(reuse_id));
            } // end - reuse_id
        } // end - location.search

        // get templates
        if ( !emailTemplates ) {
            this.props.dispatch(getEmailTemplates());
        }

        // get lists
        this.props.dispatch(getLists());

        // get reply-to emails
        if ( !replyToEmailsList ) {
            this.props.dispatch(getReplytoEmails());
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { emailBroadcast, randNum } = this.props;
        if ( emailBroadcast && randNum && randNum != this.state.randNum ) {
            this.setState({ 
                formData: ( emailBroadcast ? cloneCollections( emailBroadcast ) : false ),
                randNum
            });
        } // end - emailBroadcast
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('email_broadcast'));
    }

    handleSummaryAction = submitData => {
        const { dispatch, history } = this.props;
        // do dispatch
        dispatch(createBroadcast(submitData,history));
    }

    handleFormUpdate = (newData) => {
        this.setState({ formData: ( newData ? cloneCollections( newData ) : defaultFormData ) });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    handleBack = () => {
        const { step } = this.state;
        this.setState({ step: step-1 });
    }

    handleNext = () => {
        const { step } = this.state;
        this.setState({ step: step+1 });
    }

    getListOptions = () => {
        const { authData, mailingLists } = this.props;
        var lists = ( mailingLists && isArrayExists( mailingLists ) ? cloneCollections( mailingLists ) : [] );

        // make sure the list wasn't in importing mode
        lists = _filter( lists, (o) => {
            return ( o.status && o.status === 'importing' ? false : true );
        });

        // check if the current user have the access to selected lists
        if ( !isSuperAdmin( authData ) ) {
            lists = _filter( lists, (o) => {
                return ( o.org && !_isEmpty( o.org ) && authData.orgs && authData.orgs.indexOf( o.org ) >= 0 ? true : false );
            });
        } // end - mailingLists

        return lists;
    }

    getTemplateOptions = () => {
        const { authData, emailTemplates } = this.props;
        var options = ( emailTemplates && isArrayExists( emailTemplates ) ? cloneCollections( emailTemplates ) : [] );
        if ( !isSuperAdmin( authData ) ) {
            options = _filter( options, (o) => {
                return ( o.orgs && isArrayExists( o.orgs ) && _find( o.orgs, (i) => authData.orgs && authData.orgs.indexOf( i.id ) >= 0 ) ? true : false );
            });
        } // end - emailTemplates
        return options;
    }

    getEmailOptions = () => {
        const { authData, replyToEmailsList } = this.props;
        var options = ( replyToEmailsList && isArrayExists( replyToEmailsList ) ? cloneCollections( replyToEmailsList ) : [] );
        if ( !isSuperAdmin( authData ) ) {
            options = _filter( options, (o) => {
                return ( o.orgs && isArrayExists( o.orgs ) && _find( o.orgs, (i) => authData.orgs && authData.orgs.indexOf( i.id ) >= 0 ) ? true : false );
            });
        } // end - emailTemplates
        return options;
    }

    renderStep = () => {
        const { dispatch, history, authData } = this.props;
        const { step, formData, randNum, reuse_id } = this.state;
        var stepData = {
            dispatch,
            history,
            step,
            formData,
            authData,
            onFormUpdate: this.handleFormUpdate,
            goBack: this.handleBack,
            goNext: this.handleNext
        };
        switch( step ) {
            case 3:
                return <Fade in={true} timeout={350}><StepSummary lists={this.getListOptions()} randNum={_random(1,9999)} onAction={this.handleSummaryAction} {...stepData} /></Fade>;
            case 2:
                return <Fade in={true} timeout={350}><StepLists lists={this.getListOptions()} {...stepData} /></Fade>;
            case 1:
                return <Fade in={true} timeout={350}><StepCreate randNum={randNum} {...stepData} /></Fade>;
            case 0:
            default:
                return <Fade in={true} timeout={350}><StepSettings templates={this.getTemplateOptions()} emails={this.getEmailOptions()} currentMode={( reuse_id ? 'edit' : 'new' )} {...stepData} /></Fade>;
        }
    }

    renderStepper = () => {
        const { step } = this.state;
        return <BroadcastStepper 
            activeStep={step}
            steps={[
                { id: 'settings', label: 'Settings' },
                { id: 'email', label: 'Content' },
                { id: 'lists', label: 'Lists' },
                { id: 'summary', label: 'Summary' }
            ]} />
    }

    renderContents() {
        return (
        <div>

            {this.renderStepper()}
            {this.renderStep()}

        </div>
        );
    }

    render() {
        const { formData } = this.state;
        const { emailTemplates, mailingLists } = this.props;
        return <AppWrapper 
                title="Create New Broadcast"
                subtitle="Email Broadcasts"
                back="/broadcast"
                breadcrumbs={[
                    { url: '/broadcast', label: 'Email Broadcasts' },
                    { label: 'Create New' }
                ]}
                onLoad={( !( formData && emailTemplates && mailingLists ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        emailBroadcast: state.email_broadcasts && state.email_broadcasts.broadcast || null,
        randNum: state.email_broadcasts && state.email_broadcasts.rand || null,
        replyToEmailsList: state.maintenance && state.maintenance.replyto_emails || null,
        emailTemplates: state.emailtemplates && state.emailtemplates.list || null,
        mailingLists: state.lists && state.lists.lists || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(EmailBroadcastNew);