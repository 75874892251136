import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import _isEmpty from 'lodash/isEmpty';

import AppWrapper from '../../components/AppWrapper';
import DotsLoader from '../../components/DotsLoader';
import FormInput from '../../components/FormInput';
 
import Stats from '../ReportBroadcastPage/stats';

import { isAdmin, hasAccessRights } from '../../helpers/auth';

import { getRecentEmailBroadCastReport } from '../../actions/reports';

const useStyles = theme => ({
    paper: {
        background: '#fff',
        padding: '40px 20px'
    },
    heading: {
        margin: theme.spacing(1),
        marginBottom: "20px",
        textTransform: "uppercase",
        fontWeight: "700"
    },
    button: {
        margin: theme.spacing(1),
        width: '100%',
        padding: "15px 10px",
        fontSize: "14px",
        fontWeight: "700"
    }
});

class DashboardPage extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        const { authData } = this.props;
        // get recent report
        if ( authData && authData.email )
            this.props.dispatch(getRecentEmailBroadCastReport(authData.email));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { recentBroadcast, randNum } = this.props;
        if ( recentBroadcast && randNum && randNum !== this.state.randNum )
            this.setState({ randNum });
    }

    handleLink = (url,event) => {
        const { history } = this.props;
        event.preventDefault();
        history.push(url);
    }

    renderBroadcastReport = () => {
        const { recentBroadcast } = this.props;
        return (
        <div style={{ marginBottom: "20px" }}>
            <FormInput label="Message Label" value={ recentBroadcast.label || '' } disabled={true} style={{ marginBottom: '10px' }} />
            <Stats
                statVariant="h5" 
                broadcast={recentBroadcast || false} 
                xs={6} 
                spacing={1} />
        </div>   
        )
    }

    renderReports = () => {
        const { randNum } = this.state;
        const { classes, recentBroadcast } = this.props;
        return (
        <Paper elevation={2} className={classes.paper}>
            <Typography variant="h5" className={classes.heading}>Most Recent Broadcast</Typography>
            { randNum ? recentBroadcast && !_isEmpty( recentBroadcast ) ? (
            <div>
                {this.renderBroadcastReport()}
                <Button variant="outlined" color="primary" className={classes.button} onClick={this.handleLink.bind(this,'/reports/broadcast' + ( recentBroadcast && recentBroadcast.id && !_isEmpty( recentBroadcast.id ) ? '?broadcast_id=' + recentBroadcast.id : '' ))}>View Full Report</Button>
            </div>
            ) : (
            <div style={{ padding: "20px 0px" }}>
                No recent broadcast found.
            </div>
            ) : <DotsLoader /> }
        </Paper>
        );
    }

    renderMenus = () => {
        const { classes, authData } = this.props;
        return (
        <Paper elevation={2} className={classes.paper}>
            <Typography variant="h5" className={classes.heading}>Quick Links</Typography>
            { authData && hasAccessRights( authData, ['ctr'] ) ? <Button variant="outlined" color="primary" className={classes.button} onClick={this.handleLink.bind(this,'/contacts')}>View Contacts</Button> : null }
            { authData && hasAccessRights( authData, ['lsr'] ) ? <Button variant="outlined" color="primary" className={classes.button} onClick={this.handleLink.bind(this,'/lists')}>View Lists</Button> : null }
            { authData && hasAccessRights( authData, ['emb'] ) ? <Button variant="outlined" color="primary" className={classes.button} onClick={this.handleLink.bind(this,'/broadcast')}>Email Broadcast</Button> : null }
            { authData && hasAccessRights( authData, ['rpr'] ) ? <Button variant="outlined" color="primary" className={classes.button} onClick={this.handleLink.bind(this,'/reports/broadcast')}>View Reports</Button> : null }
            { authData && hasAccessRights( authData, ['mtr'] ) ? <Button variant="outlined" color="primary" className={classes.button} onClick={this.handleLink.bind(this,'/maintenance/email_templates')}>View Email Templates</Button> : null }
        </Paper>
        );
    }

    renderContents() {
        const { authData } = this.props;
        return (
        <div>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {this.renderMenus()}
                </Grid>
                <Grid item xs={6}>
                    { authData && hasAccessRights( authData, ['rpr'] ) ?  this.renderReports() : null }
                </Grid>
            </Grid>

        </div>
        );
    }

    render() {
        return <AppWrapper 
                title="Dashboard"
                maxWidth="910px"
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        recentBroadcast: state.reports && state.reports.recent_email_broadcast || null,
        randNum: state.reports && state.reports.rand || null
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles),
    withRouter
)(DashboardPage);