import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

import AppWrapper from '../../components/AppWrapper';
import FormInput from '../../components/FormInput';
import FormMultiSelect from '../../components/FormMultiSelect';
import SnackBarSave from '../../components/SnackBarSave';
import DotsLoader from '../../components/DotsLoader';

import ListContacts from './contacts';
import ListImport from './import';
import BulkDelete from './bulk_delete';

import { InfoButton, InverseButton } from '../../styles/button';
import { InfoTag } from '../../styles/tag';

import { cloneCollections, getSelectOptions, getSelectValues, reverseSelectValues } from '../../helpers/data';
import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';

import { getList, getListContacts, editList, importContacts, removeContactsFromList } from '../../actions/lists';
import { appChangesMade, resetRedux } from '../../actions/misc';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
    <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`list-tabpanel-${index}`}
        aria-labelledby={`list-tab-${index}`}
        {...other}>
        <Box p={0}>{children}</Box>
    </Typography>
    );
}
function a11yProps(index) {
    return {
        id: `list-tab-${index}`,
        'aria-controls': `list-tabpanel-${index}`,
    };
}

class ListPage extends React.Component {

    state = {
        currentTab: 0,
        list: false,
        randNum: false
    };

    componentDidMount() {
        const { list_id } = this.props.match.params;

        // retrieve list details
        this.props.dispatch(getList(list_id));

        // retrieve list contacts
        this.props.dispatch(getListContacts(list_id));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { mailingList, randNum } = this.props;

        // update contact details
        if ( mailingList && randNum && randNum != this.state.randNum ) {
            this.setState({ list: mailingList, randNum });
        } // end - mailingList

    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('list'));
    }

    handleRefresh = () => {
        const { list_id } = this.props.match.params;
        this.setState({ randNum: false, list: false, currentTab: 0 });
        // reset redux
        this.props.dispatch(resetRedux('list'));

        // wait for a while before retrigger it
        setTimeout(() => {
            // retrieve list details
            this.props.dispatch(getList(list_id));

            // retrieve list contacts
            this.props.dispatch(getListContacts(list_id));
        },250);
    }

    handleContactRemove = () => {
        const { list_id } = this.props.match.params;
        const { listContacts } = this.props;
        this.props.dispatch(removeContactsFromList(list_id,listContacts));
    }

    handleImport = (formData,replace) => {
        const { list_id } = this.props.match.params;
        this.props.dispatch(importContacts(list_id,formData,replace));
    }

    handleSaveChanges = (event) => {
        const { list } = this.state;
        event.preventDefault();
        var error = false;

        if ( !( list && list.name && !_isEmpty( list.name ) ) ) {
            error = 'Please insert a valid name';
        } // end - list.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editList(list));
        } // end - error
    }

    handleFormUpdate = (newValue,key) => {
        const { list } = this.state;
        var newData = ( list ? cloneCollections( list ) : {} );
        newData[key] = newValue;
        
        this.setState({ list: newData });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    renderGeneral = () => {
        const { list } = this.state;
        return (
        <Paper elevation={2} style={{ background: "#fff", padding: "20px" }}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormInput label="List Address" name="domain_name" value={( list.domain_name && list.id ? list.id+'@'+list.domain_name : '' )} disabled={true} />
                </Grid>
                <Grid item xs={6}>
                    <FormInput label="Organisation" name="org" value={( list.org_label || '' )} disabled={true} />
                </Grid>
                <Grid item xs={12}>
                    <FormInput label="Name" name="name" value={( list.name || '' )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={12}>
                    <FormInput label="Description" name="description" value={( list.description || '' )} multiline={true} rowsMax="4" rows="2" onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>
        </Paper>
        )
    }

    renderListContacts = () => {
        const { list_id } = this.props.match.params;
        const { listContacts } = this.props;
        return ( listContacts ? (
                <div style={{ padding: "30px" }}>
                    <ListContacts 
                        list_id={list_id}
                        contacts={listContacts} />
        </div> ) : <DotsLoader style={{ padding: "90px 30px" }} message={<div style={{ textAlign: 'center' }}><Typography variant="h5" style={{ fontStyle: "italic" }}>*Please note that it might take up to 1 - 5 minutes to load<br />if the list's size is more than 10k.</Typography></div>} /> );
    }

    renderTabs = () => {
        const { list, currentTab } = this.state;
        const { listContacts } = this.props;
        return (
        <div>
            <AppBar position="static" color="default" style={{ marginTop: "25px" }}>
                <Tabs value={currentTab} onChange={(event,newValue) => this.setState({ currentTab: newValue })} aria-label="List Tab">
                    <Tab label="Contacts" {...a11yProps(0)} />
                    <Tab label="Import" {...a11yProps(1)} />
                    <Tab label="Remove All Contacts" {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={currentTab} index={0}>
                <div style={{ border: "2px dashed #999", marginTop: "15px" }}>{this.renderListContacts()}</div>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <Paper elevation={2} style={{ marginTop: "15px", background: "#fff", padding: "30px" }}>
                    <ListImport onImport={this.handleImport} />
                </Paper>
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
                <Paper elevation={2} style={{ marginTop: "15px", background: "#fff", padding: "30px" }}>
                    { listContacts ? (
                        <BulkDelete 
                        list={list}
                        onRemove={this.handleContactRemove} />
                    ) : <DotsLoader style={{ padding: "90px 30px" }} message={<div style={{ textAlign: 'center' }}><Typography variant="h5" style={{ fontStyle: "italic" }}>*Please note that it might take up to 1 - 5 minutes to load<br />if the list's size is more than 10k.</Typography></div>} /> }
                </Paper>
            </TabPanel>
        </div>
        );
    }

    renderStatusMessage = () => {
        return (
        <div>
            <div style={{ paddingBottom: "20px", textAlign: "right", marginTop: "45px" }}>
                <InverseButton style={{ padding: "10px 25px" }} onClick={this.handleRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
            </div>
            <Grid container justify="center" alignItems="center" style={{ padding: "60px", border: "2px dashed #999" }}>
                <CircularProgress />
                <Typography variant="h5" style={{ marginLeft: "20px" }}>
                    <div style={{ marginBottom: "5px" }}><InfoTag>Section Not Available</InfoTag></div>
                    {/* We're importing the new contacts into this list right now */}
                    Updating process is still on-going. Please come back later.
                </Typography>
            </Grid>
        </div>
        );
    }

    renderContents() {
        const { list } = this.state;
        return (
        <div style={{ paddingBottom: "45px" }}>

            {this.renderGeneral()}

            <div style={{ textAlign: "center", marginTop: "25px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            { list && list.status && list.status === 'importing' ? this.renderStatusMessage() : this.renderTabs()}

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { list_id } = this.props.match.params;
        const { list, randNum } = this.state;
        return <AppWrapper 
                title="Edit List"
                subtitle="Lists"
                back="/lists"
                breadcrumbs={[
                    { url: '/lists', label: 'Lists' },
                    { label: 'Edit' }
                ]}
                onLoad={( !( list && list_id == list.id && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        mailingList: state.lists && state.lists.list || null,
        listContacts: state.lists && state.lists.list_contacts || null,
        randNum: state.lists && state.lists.rand || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ListPage);