import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

import AppWrapper from '../../components/AppWrapper';
import FormInput from '../../components/FormInput';

import Contacts from './contacts';

import { InfoButton } from '../../styles/button';

import { cloneCollections } from '../../helpers/data';
import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';

import { exportContacts } from '../../actions/export_contacts';
import { resetRedux } from '../../actions/misc';

class ExportPage extends React.Component {

    state = {
        lists: false,
        contacts: false,
        keywords: '',
        dataType: false,
        randNum: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { exportedData, randNum } = this.props;
        if ( exportedData && randNum && randNum != this.state.randNum ) {
            this.setState({ 
                contacts: ( exportedData.contacts && isArrayExists( exportedData.contacts ) ? cloneCollections( exportedData.contacts ) : [] ), 
                lists: ( exportedData.lists && isArrayExists( exportedData.lists ) ? cloneCollections( exportedData.lists ) : [] ), 
                randNum 
            });
        } // end - exportedContacts
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('tools_export'));
    }

    handleReset = () => {
        this.setState({ contacts: false, lists: false });
        this.props.dispatch(resetRedux('tools_export'));
    }

    handleExport = (event) => {
        const { dataType, keywords } = this.state;
        event.preventDefault();

        var error = false;

        // if is keywords - check if is it empty?
        if ( !( dataType && !_isEmpty( dataType ) ) ) {
            error = 'Please select a valid data type';
        } else {
            if ( dataType === 'keywords' && !( keywords && !_isEmpty( keywords ) ) ) {
                error = 'Please insert at least one keywords';
            }  // end - dataType
        } // end - dataType

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(exportContacts({ type: dataType, keywords }));
        } // end - error

    }

    renderKeywordInput = () => {
        const { keywords } = this.state;
        return (
        <div>
            <FormInput label="Keyword" name="keywords" value={( keywords || '' )} onChange={(newValue) => this.setState({ keywords: newValue })} />
        </div>
        );
    }

    renderForm = () => {
        const { dataType } = this.state;
        return (
        <Paper elevation={2} style={{ background: "#fff", padding: "40px 30px" }}>

            <div>
                <FormControl component="fieldset">
                    <FormLabel component="legend"><div style={{ color: '#212121', fontSize: "2rem", marginBottom: "10px", fontWeight: "700" }}>Select Data Type</div></FormLabel>
                    <RadioGroup aria-label="datatype" name="export_data_type" value={( dataType || false )} onChange={event => this.setState({ dataType: event.target.value })}>
                        <FormControlLabel value="unsubscribed_list" control={<Radio />} label="Unsubscribed List" />
                        <FormControlLabel value="bounced_list" control={<Radio />} label="Bounced List" />
                        <FormControlLabel value="keywords" control={<Radio />} label="Export by Keyword" />
                    </RadioGroup>
                </FormControl>
            </div>

            { dataType && dataType === 'keywords' ? this.renderKeywordInput() : null }

            <InfoButton minWidth="128px" key="export" style={{padding: "10px 25px", marginTop: "20px" }} onClick={this.handleExport}><i className="fa fa-cloud-download"></i>Download Data</InfoButton>

        </Paper>
        )
    }

    renderContents() {
        const { contacts, lists, dataType } = this.state;
        const { authData } = this.props;
        return (
        <div>
            { contacts ? <Contacts 
                            dataType={dataType}
                            authData={authData}
                            lists={lists} 
                            contacts={contacts} 
                            onReset={this.handleReset} /> : this.renderForm() }
        </div>
        );
    }

    render() {
        return <AppWrapper 
                title="Export"
                subtitle="Tools"
                maxWidth="1110px"
                onLoad={false}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        exportedData: state.tools && state.tools.export_contacts || null,
        randNum: state.tools && state.tools.rand || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ExportPage);