import * as firebase from 'firebase/app';
import shortid from 'shortid';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import { getIDToken } from './auth';
import { doPromise } from '../helpers/action';
import { isArrayExists } from '../helpers/validation';
import { cloneCollections } from '../helpers/data';

import { fb_deleteContactFromList } from './contacts';

/* helpers start */

const addContactstoImportQueue = (id,formData,replace) => {
    return new Promise((resolve,reject) => {

        var queue_id = shortid.generate(),
            queueCount = 0,
            contactCount = 0,
            updateData = {};

        updateData["contacts_import_queue/"+queue_id+"/list_id"] = id;
        updateData["contacts_import_queue/"+queue_id+"/replace"] = ( replace ? 'yes' : 'no' );

        if ( formData && isArrayExists( formData ) ) {
            queueCount++; // start queue at 1
            updateData["contacts_import_queue/"+queue_id+"/queue/q"+queueCount+"/queue_num"] = queueCount;

            _forEach( formData, contact => {
                contactCount++; // start contact count

                // separate by 200 contacts for each queue
                if ( contactCount > 200 ) {
                    contactCount = 1;
                    queueCount++;
                    updateData["contacts_import_queue/"+queue_id+"/queue/q"+queueCount+"/queue_num"] = queueCount;
                } // end - contactCount

                var contact_id = ( contact.id && !_isEmpty( contact.id ) ? contact.id : shortid.generate() );
                updateData["contacts_import_queue/"+queue_id+"/queue/q"+queueCount+"/contacts/"+contact_id+"/name"] = ( contact.name || '' );
                updateData["contacts_import_queue/"+queue_id+"/queue/q"+queueCount+"/contacts/"+contact_id+"/first_name"] = ( contact.first_name || '' );
                updateData["contacts_import_queue/"+queue_id+"/queue/q"+queueCount+"/contacts/"+contact_id+"/last_name"] = ( contact.last_name || '' );
                updateData["contacts_import_queue/"+queue_id+"/queue/q"+queueCount+"/contacts/"+contact_id+"/email"] = ( contact.email || '' );
                updateData["contacts_import_queue/"+queue_id+"/queue/q"+queueCount+"/contacts/"+contact_id+"/subscribed"] = ( contact.subscribed || 'yes' );
            });
        } // end - formData

        updateData["contacts_import_queue/"+queue_id+"/total_queue"] = queueCount;

        firebase.database().ref().update( updateData, error => {
            if ( error ) {
                reject(error);
            } else {
                resolve(queue_id);
            }
        });

    })
}

const triggerBatchContactsRemove = ({ list_id, contacts }) => {
    return new Promise((resolve,reject) => {

        let promises = [];
        if ( contacts && isArrayExists( contacts ) ) {
            console.log( contacts );
            _forEach( contacts, contact => {
                if ( contact && contact.id && !_isEmpty( contact.id ) && list_id && !_isEmpty(list_id) ) {
                    promises.push( fb_deleteContactFromList(contact.id,list_id) );
                }
            });
        } // end - contacts
    
        if ( isArrayExists( promises ) ) {
            Promise.all( promises )
            .then(result => {
                resolve(true);
            })
            .catch(error => {
                resolve(false);
            });
        } else {
            resolve(false);
        }

    })
}

const doAsyncAPICall = async ({ list_id, contacts }) => {

    const list = [];
    const limits = 200;
    let count = 1,
        batch = [];

    if ( contacts && isArrayExists( contacts ) ) {
        _forEach( contacts, contact => {
            batch.push(contact);
            if ( count === limits ) {
                list.push(batch); // add to list
                // then reset
                count = 1; 
                batch = [];
            } else {
                count++;
            } // end - count
        });

        // if batch is not empty - add to list
        if ( batch && isArrayExists( batch ) )
            list.push( batch );

    } // end - contacts

    if ( list && isArrayExists( list ) ) {
        let cIndex = 0;
        while ( cIndex < list.length ) {
            await triggerBatchContactsRemove({ list_id, contacts: cloneCollections( list[cIndex]) })
                .then(() => {
                    cIndex++;
                })
                .catch(error => {
                    let errorMessage = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to remove contacts. Please try again.' ) );
                    throw new Error( errorMessage );
                });
        }
    } // end - contacts

    return 'done';
}

const addContactstoRemovalQueue = (id,contacts) => {
    return new Promise((resolve,reject) => {

        var queue_id = shortid.generate(),
            queueCount = 0,
            contactCount = 0,
            updateData = {};

        updateData["contacts_removal_queue/"+queue_id+"/list_id"] = id;

        if ( contacts && isArrayExists( contacts ) ) {
            queueCount++; // start queue at 1
            updateData["contacts_removal_queue/"+queue_id+"/queue/q"+queueCount+"/queue_num"] = queueCount;

            _forEach( contacts, contact => {
                contactCount++; // start contact count

                // separate by 50 contacts for each queue
                if ( contactCount > 50 ) {
                    contactCount = 1;
                    queueCount++;
                    updateData["contacts_removal_queue/"+queue_id+"/queue/q"+queueCount+"/queue_num"] = queueCount;
                } // end - contactCount

                var contact_id = ( contact.id && !_isEmpty( contact.id ) ? contact.id : shortid.generate() );
                updateData["contacts_removal_queue/"+queue_id+"/queue/q"+queueCount+"/contacts/"+contact_id+"/id"] = contact_id;
                updateData["contacts_removal_queue/"+queue_id+"/queue/q"+queueCount+"/contacts/"+contact_id+"/email"] = ( contact.email || '' );
            });
        } // end - contacts

        updateData["contacts_removal_queue/"+queue_id+"/total_queue"] = queueCount;

        firebase.database().ref().update( updateData, error => {
            if ( error ) {
                reject(error);
            } else {
                resolve(queue_id);
            }
        });

    })
}

const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}

/* helper end */

export const fb_getLists = () => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'list',
                params: [{ key: 'action', value: 'get' }]
            })
            .then(res => {
                var lists = ( res && res.data && res.data.lists && isArrayExists( res.data.lists ) ? res.data.lists : [] );
                resolve(lists);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getList = (id) => {
    return new Promise((resolve,reject) => {

        firebase.firestore().collection("lists").doc(id).get()
        .then( doc => {
            var list = ( doc.exists ? doc.data() : {} );
            resolve(list);
        })
        .catch( error => {
            reject(error);
        });

    })
}

export const fb_getListContacts = (id) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'list',
                params: [{ key: 'action', value: 'get_list_contacts' }],
                formData: { id }
            })
            .then(res => {
                var contacts = ( res && res.data && res.data.contacts && isArrayExists( res.data.contacts ) ? res.data.contacts : [] );
                resolve(contacts);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_addList = (formData) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'list',
                params: [{ key: 'action', value: 'add' }],
                formData
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateList = (formData) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'list',
                params: [{ key: 'action', value: 'update' }],
                formData
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteList = (id) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'list',
                params: [{ key: 'action', value: 'delete' }],
                formData: { id }
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_importContacts = (id,formData,replace) => {
    return new Promise((resolve,reject) => {

        addContactstoImportQueue(id,formData,replace)
        .then( queue_id => {

            getIDToken()
            .then(idToken => {
    
                doPromise({
                    idToken,
                    method: 'post',
                    url: 'import',
                    params: [{ key: 'action', value: 'import_contacts' }],
                    formData: { id: queue_id }
                })
                .then(res => {
                    resolve('done');
                })
                .catch(error => {
                    reject(error);
                });
    
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_removeContactsFromList = (list_id,list_contacts) => {
    return new Promise((resolve,reject) => {

        addContactstoRemovalQueue(list_id,list_contacts)
        .then( queue_id => {

            getIDToken()
            .then(idToken => {
    
                doPromise({
                    idToken,
                    method: 'post',
                    url: 'import',
                    params: [{ key: 'action', value: 'contacts_removal' }],
                    formData: { id: queue_id }
                })
                .then(res => {
                    resolve('done');
                })
                .catch(error => {
                    reject(error);
                });
    
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

        // doAsyncAPICall({ list_id, contacts: list_contacts })
        // .then(() => {
        //     resolve(true);
        // })
        // .catch(error => {
        //     reject(error);
        // });

    })
}