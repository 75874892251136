import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Dropzone from 'react-dropzone';
import shortid from 'shortid';
import Papa from 'papaparse';
import blue from '@material-ui/core/colors/blue';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _trim from 'lodash/trim';
import _replace from 'lodash/replace';
import _split from 'lodash/split';
import _toLower from 'lodash/toLower';

import AppWrapper from '../../components/AppWrapper';
import FormInput from '../../components/FormInput';

import Contacts from './contacts';

import { InfoButton, AInverseLink } from '../../styles/button';

import { cloneCollections } from '../../helpers/data';
import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';

import { importContacts } from '../../actions/import_contacts';
import { resetRedux } from '../../actions/misc';

class ImportPage extends React.Component {

    state = {
        contacts: false,
        dataType: false,
        randNum: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { importResults } = this.props;
        if ( importResults && importResults !== prevProps.importResults ) {
            this.setState({ contacts: false, dataType: false });
        } // end - importResults
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('tools_import'));
    }

    handleReset = () => {
        this.setState({ contacts: false, randNum: false });
        this.props.dispatch(resetRedux('tools_import'));
    }

    handleImport = (event) => {
        const { dataType, contacts } = this.state;
        event.preventDefault();

        var error = false;

        if ( !( contacts && isArrayExists( contacts ) ) ) {
            error = 'Please insert at least one contact.';
        } // end - contacts

        if ( !( dataType && !_isEmpty( dataType ) ) ) {
            error = 'Please select a valid data type';
        }

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(importContacts(contacts,dataType));
        } // end - error

    }

    handleFileUpload = (files) => {
        var file = false;

        if ( files && files[0] ) {
            file = files[0];
        } // end - files

        if ( file ) {
            this.setState({ processing: true });
            Papa.parse(file, {
                header: true,
                complete: (results) => {
                    var contacts = [];

                    if ( results && results.data && isArrayExists( results.data ) ) {
                        _forEach( results.data, function(user) {
                            var user_email = ( user.email && !_isEmpty( user.email ) ? _toLower( _trim( user.email ) ) : false );
                            if ( user_email && validateEmail( user_email ) ) {
                                var found = ( isArrayExists( contacts ) ? _find( contacts, { email: user_email } ) : false );
                                if ( !found ) {
                                    var name = ( user.name && !_isEmpty( user.name ) ? _replace( _trim( user.name ), '- ', '' ) : '' ),
                                        splitName = ( name && !_isEmpty( name ) ? _split( name, ', ' ) : false ),
                                        first_name = ( user.firstname && !_isEmpty( user.firstname ) ? _trim( user.firstname ) : '' ),
                                        last_name = ( user.lastname && !_isEmpty( user.lastname ) ? _trim( user.lastname ) : '' );

                                    // if no first name & last name
                                    if ( _isEmpty( first_name ) && _isEmpty( last_name ) ) {
                                        // get first name & last name
                                        if ( splitName && splitName[1] && !_isEmpty( splitName[1] ) ) {
                                            first_name = splitName[1];
                                            last_name = ( splitName[0] && !_isEmpty( splitName[0] ) ? splitName[0] : '' );
                                        } else if ( splitName && splitName[0] && !_isEmpty( splitName[0] ) ) {
                                            first_name = splitName[0];
                                        }
                                    } // end - first_name

                                    contacts.push({
                                        id: shortid.generate(),
                                        name,
                                        first_name,
                                        last_name,
                                        email: user_email || '',
                                        subscribed: ( user.subscribed && 'no' === _toLower( user.subscribed ) ? 'no' : 'yes' ),
                                        bounced: ( user.bounced && 'no' === _toLower( user.bounced ) ? 'no' : 'yes' )
                                    });
                                } // end - found
                            } // end - user_email
                      });
                    } // end - results.data

                    this.setState({ contacts });
                }
            });
        } else {
            triggerErrorAlert('Invalid file dropped. Please try again.');
        }
    }

    renderDropzone = () => {
        return (
        <Dropzone onDrop={this.handleFileUpload} accept="text/csv,.csv">
        {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ style: {
                display: "block",
                border: "2px dashed "+blue['500'],
                padding: "30px",
                minHeight: "150px",
                background: blue['50']
            } })}>
                <input {...getInputProps()} />
                <Typography variant="h6">Drag 'n' drop a csv file here, or click to select a csv</Typography>
            </div>
        )}
        </Dropzone>
        );
    }

    renderDropForm = () => {
        return (
        <div style={{ marginBottom: "15px" }}>
            <Grid container alignItems="center" justify="space-between" style={{ padding: "15px 0px" }}>
                <Typography variant="h5" style={{ color: '#212121', fontSize: "2rem", marginBottom: "10px", fontWeight: "700", display: "inline-block" }}>Select CSV file</Typography>
                <AInverseLink href="https://firebasestorage.googleapis.com/v0/b/dreamcatcher-lms.appspot.com/o/website%2Fimport_contacts_sample.csv?alt=media" target="_blank" style={{ padding: "5px 15px" }}><i className="fa fa-file-excel-o"></i>Download Sample CSV File</AInverseLink>
            </Grid>
            {this.renderDropzone()}
        </div>
        );
    }

    renderContacts = () => {
        const { contacts } = this.state;
        return (
        <div style={{ marginBottom: "15px",  }}>
            <div style={{ padding: "20px", border: "2px dashed #999" }}>
                <Contacts 
                    contacts={contacts} 
                    onReset={this.handleReset} />
            </div>
        </div>
        )
    }

    renderContents = () => {
        const { contacts, dataType } = this.state;
        return (
        <Paper elevation={2} style={{ background: "#fff", padding: "40px 30px" }}>

            <div style={{ marginBottom: "15px" }}>
                <FormControl component="fieldset">
                    <FormLabel component="legend"><div style={{ color: '#212121', fontSize: "2rem", marginBottom: "10px", fontWeight: "700" }}>Select Data Type</div></FormLabel>
                    <RadioGroup aria-label="datatype" name="export_data_type" value={( dataType || false )} onChange={event => this.setState({ dataType: event.target.value })}>
                        <FormControlLabel value="unsubscribed_contacts" control={<Radio />} label="Unsubscribed Contacts" />
                    </RadioGroup>
                </FormControl>
            </div>

            { contacts ? this.renderContacts() : ( dataType && dataType === 'unsubscribed_contacts' ? this.renderDropForm() : null ) }

            <InfoButton minWidth="128px" key="import" style={{padding: "10px 25px", marginTop: "20px" }} onClick={this.handleImport}><i className="fa fa-cloud-upload"></i>Start Import</InfoButton>

        </Paper>
        )
    }

    render() {
        return <AppWrapper 
                title="Import"
                subtitle="Tools"
                maxWidth="1110px"
                onLoad={false}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        importResults: state.tools && state.tools.import_results || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ImportPage);