import React from 'react';
import styled from "styled-components";
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import theme from '../../theme';

const Container = styled.div`
    display: block;
    width: 500px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    border-radius: 4px;
    background: #fff;
`;

class PopperBox extends React.Component {

    handleClickAway = () => {
        if ( this.props.onClose ) 
            this.props.onClose();
    }

    render() {
        const { open, anchorEl, placement, contents, wrapperStyle, containerStyle } = this.props;
        return (
        <Popper 
        open={open} 
        placement={ placement || "bottom-end" }
        anchorEl={( anchorEl || {} )} 
        style={{ zIndex: '99999' }}
        transition>
            {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={this.handleClickAway} >
                <Fade {...TransitionProps} timeout={350}>
                    <div style={( wrapperStyle || null )}>
                        <Container style={( containerStyle || null )}>{ contents || '' }</Container>
                    </div>
                </Fade>
            </ClickAwayListener>
            )}
        </Popper>
        );
    }

}

export default PopperBox