import _isEmpty from "lodash/isEmpty";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import * as firebase from "firebase/app";

import { getIDToken } from "./auth";
import { doPromise } from "../helpers/action";
import { getMomentTimestamp } from "../helpers/date";
import { isArrayExists } from "../helpers/validation";

import { DEV_MODE } from "../constants";

/* helpers start */
// retrieve contact details from snapshot
const getContactDetails = (snapshot) => {
    var val = snapshot.val(),
        contact = {
            id: snapshot.key,
            name: (val.first_name || "") + (val.last_name && !_isEmpty(val.last_name) ? " " + val.last_name : ""),
            first_name: val.first_name || "",
            last_name: val.last_name || "",
            email: val.email || "",
            subscribed: val.subscribed || "no",
            bounced: val.bounced || "no",
            bounced_records: [],
            lists: [],
            created_on: val.created_on && val.created_on._seconds ? getMomentTimestamp(val.created_on._seconds) : 0,
        };

    if (snapshot.child("lists").exists() && snapshot.child("lists").hasChildren()) {
        snapshot.child("lists").forEach((listSnapshot) => {
            if (listSnapshot.val()) contact.lists.push({ id: listSnapshot.key });
        });
    } // end - snapshot.child("lists")

    if (snapshot.child("bounced_records").exists() && snapshot.child("bounced_records").hasChildren()) {
        snapshot.child("bounced_records").forEach((recordSnapshot) => {
            contact.bounced_records.push({ id: recordSnapshot.key });
        });
    } // end - snapshot.child("bounced_records")

    return contact;
};

const getBouncedRecord = (contact) => {
    return new Promise((resolve, reject) => {
        var promises = [],
            records = [];

        _forEach(contact.bounced_records, (record) => {
            promises.push(
                firebase
                    .database()
                    .ref("email_broadcasts_report/" + record)
                    .once("value")
            );
        });

        Promise.all(promises)
            .then((results) => {
                if (results && isArrayExists(results)) {
                    _forEach(results, (result) => {
                        if (result.exists()) {
                            var val = result.val(),
                                record = {
                                    id: result.key,
                                    status: val.status || "",
                                    delivery_time: val.delivery_time || "",
                                    label: val.label || "",
                                    subject_line: val.subject_line || "",
                                    created_on: val.created_on || 0,
                                    created_by: val.created_by || "",
                                };
                            records.push(record);
                        } // end - result
                    });
                } // end - results

                contact.bounced_records = records;
                resolve(contact);
            })
            .catch((error) => {
                resolve(contact);
            });
    });
};

/* helper end */

export const fb_getContactsLive = (callback) => {
    return new Promise((resolve, reject) => {
        let fbRef = firebase.database().ref("contacts");

        if (DEV_MODE) {
            fbRef = fbRef.limitToLast(50).orderByChild("created_on/_seconds");
        }

        fbRef.on("value", (snapshot) => {
            var contacts = [];

            if (snapshot.exists() && snapshot.hasChildren()) {
                snapshot.forEach((childSnapshot) => {
                    contacts.push(getContactDetails(childSnapshot));
                });
            } // end - snapshot

            callback(contacts);
        });
    });
};

export const fb_getContacts = (props) => {
    return new Promise((resolve, reject) => {
        const { perPage, page, searchterms, filterBy } = props;

        firebase
            .database()
            .ref("contacts")
            .once("value")
            .then((snapshot) => {
                var contacts = [];
                if (snapshot.exists() && snapshot.hasChildren()) {
                    snapshot.forEach((childSnapshot) => {
                        contacts.push(getContactDetails(childSnapshot));
                    });
                } // end - snapshot
                resolve(contacts);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_getContact = (id) => {
    return new Promise((resolve, reject) => {
        firebase
            .firestore()
            .collection("contacts")
            .doc(id)
            .get()
            .then((doc) => {
                var contact = {};
                if (doc.exists) {
                    contact = doc.data();
                }
                // if bounced record found, trigger a promise to retrieve the record
                return contact && contact.bounced_records && isArrayExists(contact.bounced_records) ? getBouncedRecord(contact) : contact;
            })
            .then((contact) => {
                resolve(contact);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_addContact = (formData) => {
    return new Promise((resolve, reject) => {
        getIDToken()
            .then((idToken) => {
                doPromise({
                    idToken,
                    method: "post",
                    url: "contact",
                    params: [{ key: "action", value: "add" }],
                    formData,
                })
                    .then((res) => {
                        resolve(res.data && res.data.new_contact_id ? res.data.new_contact_id : {});
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_updateContact = (formData) => {
    return new Promise((resolve, reject) => {
        getIDToken()
            .then((idToken) => {
                doPromise({
                    idToken,
                    method: "post",
                    url: "contact",
                    params: [{ key: "action", value: "update" }],
                    formData,
                })
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_deleteContact = (id) => {
    return new Promise((resolve, reject) => {
        getIDToken()
            .then((idToken) => {
                doPromise({
                    idToken,
                    method: "post",
                    url: "contact",
                    params: [{ key: "action", value: "delete" }],
                    formData: { id },
                })
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const fb_deleteContactFromList = (id, list_id) => {
    return new Promise((resolve, reject) => {
        getIDToken()
            .then((idToken) => {
                doPromise({
                    idToken,
                    method: "post",
                    url: "contact",
                    params: [{ key: "action", value: "remove_from_list" }],
                    formData: { id, list_id },
                })
                    .then((res) => {
                        resolve(res.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
            .catch((error) => {
                reject(error);
            });
    });
};
