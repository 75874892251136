import * as firebase from 'firebase/app';

import {
	IS_LOGGED_IN,
	AUTH_LOGIN,
	AUTH_LOGOUT,
	AUTH_EDIT_PROFILE,
	AUTH_GET_PROFILE,
	GLOBAL_ERROR_TRIGGER
} from './types';

import { toggleLoader } from './global';
import { triggerErrorAlert, triggerSuccessAlert } from '../helpers/alert';
import { appChangesReset } from './misc';

import { googleSignIn, logoutUser, onAuth, fb_getProfile, fb_updateProfile, getCurrentUser } from '../firebase/auth';

export const isUserLoggedIn = () => {
	return dispatch => {

        firebase.auth().onAuthStateChanged(function(userToken) {
            if (userToken) {
				onAuth(userToken)
				.then(user => {
					dispatch({
						type: IS_LOGGED_IN,
						payload: user
					});
				})
				.catch(error => {
					dispatch({ type: AUTH_LOGOUT });
					triggerErrorAlert(error.message);
				});
            } else {
                dispatch({ type: AUTH_LOGOUT });
            }
        }); 

	}
}

export const signIn = () => {
	return dispatch => {
		dispatch(toggleLoader(true));

		googleSignIn()
		.then( user => {
			dispatch({
				type: AUTH_LOGIN,
				payload: user
			});
			dispatch(toggleLoader(false));
		})
		.catch(error => {
			dispatch(toggleLoader(false));
			triggerErrorAlert( error.message );
		});

	}
}

export const logout = () => {
	return dispatch => {
		dispatch(toggleLoader(true));

		logoutUser()
		.then(() => {
			dispatch({ type: AUTH_LOGOUT });
			dispatch(toggleLoader(false));
		});

	}
}

export const getProfile = () => {
	return dispatch => {
		
		fb_getProfile()
		.then( user => {
			dispatch({
				type: AUTH_GET_PROFILE,
				payload: user
			});
		})
		.catch(error => {
			triggerErrorAlert( error.message );
		});

	}
}

export const updateProfile = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateProfile(formData)
		.then( results => {
			fb_getProfile()
			.then(user => {
				// push to list
				dispatch({ type: AUTH_GET_PROFILE, payload: user });

				setTimeout(() => {

					const userToken = getCurrentUser();
					onAuth(userToken)
					.then(user => {
						// update auth data
						dispatch({ type: IS_LOGGED_IN, payload: user });

						// updated
						dispatch({ type: AUTH_EDIT_PROFILE });
						dispatch(toggleLoader(false));
						dispatch(appChangesReset());
						triggerSuccessAlert("Profile Updated");
					})

				},1500);

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update contact' ) );
			triggerErrorAlert(errMsg);
		});

	}
}