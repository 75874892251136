import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import shortid from 'shortid';
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from "@material-ui/core/styles";
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _trim from 'lodash/trim';
import _replace from 'lodash/replace';
import _split from 'lodash/split';
import _toLower from 'lodash/toLower';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import DotsLoader from '../../components/DotsLoader';
import ModalView from '../../components/ModalView';

import { InfoButton, AInverseLink, InverseButton } from '../../styles/button';

import { cloneCollections, doPagination } from '../../helpers/data';
import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';

const useStyles = theme => ({
    headcell: {
        fontSize: '14px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '14px',
        verticalAlign: 'middle'
    }
});

class ListImport extends React.Component {

    state = {
        importData: false,
        replace: false,
        perPage: 20,
        page: 1,
        openConfirmation: false,
        processing: false,
        randNum: false
    };

    handleContactImport = () => {
        const { importData, replace } = this.state;
        const { onImport } = this.props;
        if ( importData && isArrayExists( importData ) ) {
            this.setState({ openConfirmation: false, importData: false });
            if ( onImport )
                onImport(importData,replace);
        } else {
            triggerErrorAlert("No Contact(s) Found");
        }
    }

    handleFileUpload = (files) => {
        var file = false;

        if ( files && files[0] ) {
            file = files[0];
        } // end - files

        if ( file ) {
            this.setState({ processing: true });
            Papa.parse(file, {
                header: true,
                complete: (results) => {
                    var importData = [];

                    if ( results && results.data && isArrayExists( results.data ) ) {
                        _forEach( results.data, function(user) {
                            var user_email = ( user.email && !_isEmpty( user.email ) ? _toLower( _trim( user.email ) ) : false );
                            if ( user_email && validateEmail( user_email ) ) {
                                var found = ( isArrayExists( importData ) ? _find( importData, { email: user_email } ) : false );
                                if ( !found ) {
                                    var name = ( user.name && !_isEmpty( user.name ) ? _replace( _trim( user.name ), '- ', '' ) : '' ),
                                        splitName = ( name && !_isEmpty( name ) ? _split( name, ', ' ) : false ),
                                        first_name = ( user.firstname && !_isEmpty( user.firstname ) ? _trim( user.firstname ) : '' ),
                                        last_name = ( user.lastname && !_isEmpty( user.lastname ) ? _trim( user.lastname ) : '' );

                                    // if no first name & last name
                                    if ( _isEmpty( first_name ) && _isEmpty( last_name ) ) {
                                        // get first name & last name
                                        if ( splitName && splitName[1] && !_isEmpty( splitName[1] ) ) {
                                            first_name = splitName[1];
                                            last_name = ( splitName[0] && !_isEmpty( splitName[0] ) ? splitName[0] : '' );
                                        } else if ( splitName && splitName[0] && !_isEmpty( splitName[0] ) ) {
                                            first_name = splitName[0];
                                        }
                                    } // end - first_name

                                    importData.push({
                                        id: shortid.generate(),
                                        name,
                                        first_name,
                                        last_name,
                                        email: user_email || '',
                                        subscribed: ( user.subscribed && 'no' === _toLower( user.subscribed ) ? 'no' : 'yes' )
                                    });
                                } // end - found
                            } // end - user_email
                      });
                    } // end - results.data

                    this.setState({ importData, processing: false });
                }
            });
        } else {
            triggerErrorAlert('Invalid file dropped. Please try again.');
        }

    }

    reorganizeData() {
        const { importData, perPage, page } = this.state;
        var items = ( importData ? cloneCollections( importData ) : [] ),
            total = _size( items );

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    importActions = () => {
        const { replace } = this.state;
        return (
        <Grid container spacing={1} style={{ marginTop: "20px" }} alignItems="center">
            <Grid item xs={6}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color="primary"
                            label="abc"
                            checked={replace}
                            style={{ width: "60px", height: "60px" }}
                            onClick={(event) => this.setState({ replace: event.target.checked })} />
                    }
                    label={(
                        <span style={{ fontSize: "1.5rem" }}>
                            Replace all contacts in this list with the imported contacts.<br />
                            <span style={{ color: red['500'], fontWeight: "700" }}>WARNING: This action would permanently delete all the exisitng contacts in this list.</span>
                        </span>
                    )}
                />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
                <InfoButton onClick={() => this.setState({ openConfirmation: true })} style={{ padding: "15px 45px" }}>Do Import Now</InfoButton>
            </Grid>
        </Grid>
        );
    }

    renderTableActions = () => {
        const { perPage } = this.state;
        return <TableBar
                show={['entries']}
                perPage={perPage}
                rightButtons={[
                    <InverseButton key="reset" onClick={() => this.setState({ importData: false })} style={{ marginRight: "5px", padding: "0px 20px" }}><i className="fa fa-refresh"></i>Reset</InverseButton>
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />;
    }

    renderContactRow = (contact) => {
        const { classes } = this.props;
        return (
        <TableRow hover key={contact.id}>
            <TableCell className={classes.bodycell}>{contact.first_name || ''}</TableCell>
            <TableCell className={classes.bodycell}>{contact.last_name || ''}</TableCell>
            <TableCell className={classes.bodycell}>{contact.email || ''}</TableCell>
            <TableCell className={classes.bodycell}>{( contact.subscribed && contact.subscribed === 'no' ? 'no' : 'yes' )}</TableCell>
        </TableRow>
        );
    }

    renderContactsTable = (data) => {
        const { classes } = this.props;
        return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.headcell} style={{ width: "20%" }}>First Name</TableCell>
                    <TableCell className={classes.headcell} style={{ width: "20%" }}>Last Name</TableCell>
                    <TableCell className={classes.headcell}>Email</TableCell>
                    <TableCell className={classes.headcell}>Subscribed</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                { data && isArrayExists( data ) ? _map( data, this.renderContactRow) : <TableRow><TableCell className={classes.bodycell} colSpan={2}>No contact(s) uploaded yet.</TableCell></TableRow> }
            </TableBody>
        </Table>
        );
    }

    renderDropzone = () => {
        return (
        <Dropzone onDrop={this.handleFileUpload} accept="text/csv,.csv">
        {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ style: {
                display: "block",
                border: "2px dashed "+blue['500'],
                padding: "30px",
                minHeight: "200px",
                background: blue['50']
            } })}>
                <input {...getInputProps()} />
                <Typography variant="h6">Drag 'n' drop a csv file here, or click to select a csv</Typography>
            </div>
        )}
        </Dropzone>
        );
    }

    renderSampleCSVBtn = () => {
        return (
        <div style={{ padding: "15px 0px" }}>
            <AInverseLink href="https://firebasestorage.googleapis.com/v0/b/dreamcatcher-lms.appspot.com/o/website%2Fimport_contacts_sample.csv?alt=media" target="_blank" style={{ padding: "5px 15px" }}><i className="fa fa-file-excel-o"></i>Download Sample CSV File</AInverseLink>
        </div>
        );
    }

    render() {
        const { processing, openConfirmation, replace } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            { items && isArrayExists( items ) ? (
                <div>
                    <div style={{ padding: "20px", border: "2px dashed #999" }}>
                        {this.renderTableActions()}
                        {this.renderContactsTable(items)}
                        {this.renderPagination(total)}
                    </div>
                    {this.importActions()}
                </div>
            ) : ( processing ? <DotsLoader style={{ padding: "90px 30px" }} /> : (
                <div>
                    {this.renderSampleCSVBtn()}
                    {this.renderDropzone()}
                </div>
            ) )}

            <ModalView
                open={( openConfirmation || false )}
                title={"Final Confirmation"}
                actionLabel="Confirm"
                onClose={() => this.setState({ openConfirmation: false })}
                doAction={this.handleContactImport}
                contents={(
                <div>
                    <p>Please confirm if you want to import these contact(s) into this list?</p>
                    { replace ? <Typography variant="h6" style={{ color: red['500'], fontWeight: '700', padding: "15px", background: red['50'] }}>WARNING: You've selected the "Replace All" option - Meaning that all the existing contacts will be deleted, and replace with the new contacts from the csv file.</Typography> : '' }
                </div>
                )} />

        </div>
        );
    }

}


export default compose(
    withRouter,
    withStyles(useStyles)
)(ListImport);