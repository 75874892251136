import React from 'react';
import styled from "styled-components";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

class BroadcastStepper extends React.Component {

    render() {
        const { steps, activeStep } = this.props;
        return (
        <div style={{ padding: "30px 15px 15px 15px" }}>
            <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map(step => (
                <Step key={step.id}>
                    <StepLabel><div style={{ fontSize: "1.75rem" }}>{step.label}</div></StepLabel>
                </Step>
            ))}
            </Stepper>
        </div>
        );
    }

}

export default BroadcastStepper;