import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import Users from './users';

import { cloneCollections } from '../../helpers/data';
import { getUserOrgs } from '../../helpers/auth';

import { getUsers } from '../../actions/users';
import { getOrgs } from '../../actions/m_orgs';
import { getRoles } from '../../actions/m_roles';

class UsersPage extends React.Component {

    state = {
        rand: false
    };

    componentDidMount() {
        const { orgsList, rolesList, authData } = this.props;

        // get lists
        this.props.dispatch(getUsers(getUserOrgs(authData)));

        // get orgs
        if ( !orgsList ) {
            this.props.dispatch(getOrgs());
        }

        // get roles
        if ( !rolesList ) {
            this.props.dispatch(getRoles());
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { usersList, randNum } = this.props;

        // once all the data is loaded
        if ( usersList && randNum && randNum != this.state.rand )
            this.setState({ rand: randNum });
    }

    handleRefresh = () => {
        const { authData } = this.props;

        // get lists
        this.props.dispatch(getUsers(getUserOrgs(authData)));
        this.setState({ rand: false });
    }

    renderContents() {
        const { usersList, orgsList, rolesList, authData } = this.props;
        return (
        <Users 
            users={usersList} 
            orgs={orgsList} 
            roles={rolesList} 
            authData={authData}
            onRefresh={this.handleRefresh} />
        );
    }

    render() {
        const { usersList , orgsList, rolesList } = this.props;
        const { rand } = this.state;
        return <AppWrapper 
                title="Users Management"
                onLoad={( !( usersList && orgsList && rolesList && rand ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        usersList: state.users && state.users.users || null,
        randNum: state.users && state.users.rand || null,
        orgsList: state.maintenance && state.maintenance.orgs || false,
        rolesList: state.maintenance && state.maintenance.roles || false,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(UsersPage);