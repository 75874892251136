import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	TOOLS_EXPORT_CONTACTS,
	GLOBAL_ERROR_TRIGGER
} from './types';

import { toggleLoader } from './global';
import { triggerErrorAlert, triggerSuccessAlert } from '../helpers/alert';
import { appChangesReset } from './misc';

import { fb_performExport } from '../firebase/export_contacts';

export const exportContacts = (formData) => {
	return dispatch => {
        dispatch(toggleLoader(true));

		fb_performExport(formData)
		.then(returnData => {
            // push to list
            dispatch({ type: TOOLS_EXPORT_CONTACTS, payload: ( returnData || { contacts: [], lists: [] } ) });
            dispatch(toggleLoader(false));
            dispatch(appChangesReset());
            triggerSuccessAlert("Data Downloaded");
        })
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to export data' ) );
			triggerErrorAlert(errMsg);
		});

	}
}