import _random from 'lodash/random';

import { 
	GET_LISTS,
    GET_LIST,
    GET_LIST_CONTACTS,
    IMPORT_CONTACTS,
	ADD_LIST,
	EDIT_LIST,
    DELETE_LIST,
    LISTS_RESET,
    RESET_LIST
} from '../actions/types';

const initialState = {
    lists: null,
    list: null,
    list_contacts: null,
    updated: false,
    rand: false
};

export const lists = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_LISTS:
            return {
                ...state,
                lists: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_LIST:
            return {
                ...state,
                list: ( payload.single || {} ),
                rand: _random(1,9999)
            };
        case GET_LIST_CONTACTS:
            return {
                ...state,
                list_contacts: ( payload.list_contacts || {} ),
                rand: _random(1,9999)
            };
        case ADD_LIST:
            return {
                ...state,
                new_list_id: ( payload.id || false ),
                rand: _random(1,9999)
            };
        case EDIT_LIST:
        case DELETE_LIST:
        case IMPORT_CONTACTS:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        case LISTS_RESET:
            return {
                ...state,
                lists: null,
                rand: false
            };
        case RESET_LIST:
            return {
                ...state,
                list: null,
                list_contacts: null,
                rand: false
            };
        default:
            return state;
    }
}