import React from 'react';
import styled from "styled-components";
import FormControl from '@material-ui/core/FormControl';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

import { isArrayExists } from '../../helpers/validation';

import theme from '../../theme';

class FormSelect extends React.Component {

    handleChange = (event) => {
        const { name } = this.props;
        if ( this.props.onChange ) {
            this.props.onChange(event.target.value,name);
        }
    }

    renderValue = selected => {
        const { options } = this.props;
        var string = '';
        if ( selected && isArrayExists( selected ) ) {
            selected.forEach(value => {
                var item = _find( options, { value } );
                string += ( !_isEmpty( string ) ? ', ' : '' ) + ( item && item.label || '' );
            });
        }
        return string;
    }

    render() {
        const { label, value, options, name, disabled } = this.props;
        return (
        <FormControl variant="outlined" style={{ background: "#fff" }} fullWidth={true}>
            <InputLabel style={{ fontSize: "16px", fontWeight: "700", textTransform: "uppercase", background: '#fff', paddingLeft: '5px', paddingRight: '5px' }}>{label || ''}</InputLabel>
            <Select native 
                value={value} 
                onChange={this.handleChange}
                disabled={( disabled || null )}
                input={
                    disabled ? <FilledInput name={name} fullWidth={true} /> : <OutlinedInput name={name} fullWidth={true} />
                } >
                { options && isArrayExists( options ) ? options.map(option => {
                    return <option key={option.value} value={option.value}>{option.label}</option>
                }) : null }
            </Select>
        </FormControl>
        )
    }

}

export default FormSelect