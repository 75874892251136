import _random from 'lodash/random';

import { 
    M_GET_ORGS,
    M_ORGS_UPDATED,
    M_GET_ROLES,
    M_ROLES_UPDATED,
    M_GET_REPLYTO_EMAILS,
    M_REPLYTO_EMAILS_UPDATED,
} from '../actions/types';

const initialState = {
    orgs: null,
    roles: null,
    rand: false
};

export const maintenance = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case M_GET_ORGS: return { ...state, orgs: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_ROLES: return { ...state, roles: ( payload.list || [] ), rand: _random(1,9999) };
        case M_GET_REPLYTO_EMAILS: return { ...state, replyto_emails: ( payload.list || [] ), rand: _random(1,9999) };

        case M_ORGS_UPDATED:
        case M_ROLES_UPDATED:
        case M_REPLYTO_EMAILS_UPDATED:
            return { ...state, rand: _random(1,9999) };
            
        default:
            return state;
    }
}