import _random from 'lodash/random';

import {
    GET_USERS,
    ADD_USER,
    EDIT_USER,
    DELETE_USER,
    SYNC_USERS,
	GLOBAL_ERROR_TRIGGER
} from './types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from './global';
import { triggerErrorAlert, triggerSuccessAlert } from '../helpers/alert';
import { appChangesReset } from './misc';

import { fb_getUsers, fb_addUser, fb_editUser, fb_deleteUser, fb_syncUsers } from '../firebase/users';

export const getUsers = (org) => {
	return dispatch => {

		fb_getUsers(org)
		.then(list => {
			dispatch({
				type: GET_USERS,
				payload: { list }
			});	
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve users list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_USERS,
				payload: { list: [] }
			});
		})

	}
}

export const addUser = (formData,org) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addUser(formData)
  		.then(() => {
			fb_getUsers(org)
			.then(list => {
				dispatch({ type: GET_USERS, payload: { list } });	
				dispatch({ type: ADD_USER });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("User Added");
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new user' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editUser = (formData,org) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_editUser(formData)
		.then(() => {
			fb_getUsers(org)
			.then(list => {
				dispatch({ type: GET_USERS, payload: { list } });	
				dispatch({ type: EDIT_USER });
				dispatch(toggleModalProcessing(false));
				triggerSuccessAlert("User Updated");
			})
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update user' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteUser = (uid,org) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteUser(uid)
  		.then(() => {
			fb_getUsers(org)
			.then(list => {
				dispatch({ type: GET_USERS, payload: { list } });	
				dispatch({ type: DELETE_USER });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("User Deleted!");
			})
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete user' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const syncUsers = () => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_syncUsers()
		.then(() => {
			fb_getUsers()
			.then(list => {
				dispatch({ type: GET_USERS, payload: { list } });	
				dispatch({ type: SYNC_USERS });
				dispatch(toggleLoader(false));
				triggerSuccessAlert("Users Synced!");
			})
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to sync data with LMS' ) );
			triggerErrorAlert(errMsg);
			dispatch(toggleLoader(false));
		});

	}
}