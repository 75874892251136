import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormInput from '../../components/FormInput';

import { InfoButton, ErrorButton, ButtonGroup, AInfoLink } from '../../styles/button';
import { WrapWord } from '../../styles/misc';

import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { getMomentTime } from '../../helpers/date';
import { isSuperAdmin, getUserFirstOrg } from '../../helpers/auth';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions, getSelectValues, reverseSelectValues } from '../../helpers/data';

import { addEmailTemplate, deleteEmailTemplate } from '../../actions/m_email_templates';

import { ACCESS_RIGHTS } from '../../constants';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});

class Templates extends React.Component {
    
    state = {
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handleFormUpdate = (newValue,key) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        if ( key == 'orgs' ) {
            newData[key] = reverseSelectValues(newValue,'id');
        } else {
            newData[key] = newValue;
        }
        this.setState({ modalData: newData });
    }

    handleAction = () => {
        const { modalData } = this.state;
        const { history } = this.props;
        var error = false;

        if ( !( modalData && modalData.orgs && !_isEmpty( modalData.orgs ) ) ) {
            error = 'Please select at least one organisation';
        } // end - modalData.org

        if ( !( modalData && modalData.label && !_isEmpty( modalData.label ) ) ) {
            error = 'Please insert a valid label';
        } // end - modalData.label

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(addEmailTemplate(modalData,history));
        } // end - error
    }

    handleEdit = (id,event) => {
        event.preventDefault();
        this.props.history.push("/maintenance/email_templates/"+id);
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteEmailTemplate(deleteModal.id));
    }

    reorganizeData() {
        const { sortBy, filterBy, perPage, page } = this.state;
        const { templates, authData } = this.props;
        var items = ( templates ? cloneCollections( templates ) : [] ),
            total = _size( items );

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['created_on'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['label'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['label'] );
                    break;
            }
        } // end - sortBy

        // do filter
        if ( isSuperAdmin( authData ) ) {
            // only super admin can do filter for all the orgs
            if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
                items = _filter( items, (o) => ( o.orgs && isArrayExists( o.orgs ) && _find( o.orgs, { id: filterBy }) ? true : false ) );
                total = _size( items );
            }
        } else {
            // if is admin, then prefilter the org based on their org
            items = _filter( items, (o) => ( o.orgs && isArrayExists( o.orgs ) && authData.orgs && !_isEmpty( authData.orgs ) && _find( o.orgs, (p) => authData.orgs.indexOf(p.id) >= 0 ) ? true : false ) );
            total = _size( items );
        } // end - isSuperAdmin


        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderViewForm = () => {
        const { authData, orgs } = this.props;
        const { modalData, modalType } = this.state;
        return (
        <div>
            <FormInput label="Label" name="label" value={( modalData.label || '' )} onChange={this.handleFormUpdate} />
            <div style={{ paddingTop: '15px' }}><FormMultiSelect 
                label="Organisation (Required)" 
                name="orgs" 
                value={( modalData.orgs ? getSelectValues(modalData.orgs,'id') : [] )} 
                options={getSelectOptions({ 
                    list: ( isSuperAdmin( authData ) ? orgs : _filter( orgs, (o) => ( authData.orgs && !_isEmpty( authData.orgs ) && authData.orgs.indexOf(o.id) >= 0 ) ) ), 
                    keys: { value: 'id', label: 'label' }, sortBy: 'label' })}
                onChange={this.handleFormUpdate} /></div>
        </div>
        );
    }

    renderTableActions = () => {
        const { authData, orgs } = this.props;
        const { sortBy, filterBy, perPage, searchterms } = this.state;
        return <TableBar
                show={['sort','entries','filter']}
                filterBy={filterBy}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Entries first' },
                    { value: 'date-asc', label: 'Oldest Entries first' },
                    { value: 'name-asc', label: 'Label ( A - Z)' },
                    { value: 'name-desc', label: 'Label ( Z - A )' }
                ]}
                filterByOptions={( authData && authData.type && authData.type == 'superadmin' ? getSelectOptions({ list: orgs, options: [{ value: 'all', label: 'All' }], keys: { value: 'id', label: 'label' }, sortBy: 'label' }) : false )}
                rightButtons={[
                    <InfoButton minWidth="128px" key="addnew" style={{ marginRight: "5px" }} onClick={() => this.setState({ openViewModal: true, modalData: { label: '', orgs: [ getUserFirstOrg(authData,'obj') ] }, modalType: 'new' })}><i className="fa fa-plus-circle"></i>Add New</InfoButton>
                ]}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes, orgs } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell}>{item.label || ''}</TableCell>
                    <TableCell className={classes.bodycell}>{ item.orgs && isArrayExists( item.orgs ) ? item.orgs.map(org => {
                            var selected = _find( orgs, { id: org.id } );
                            return ( selected && selected.label ? <div key={org.id} style={{ fontSize: '0.875em', marginBottom: "4px" }}>{selected.label}</div> : null );
                        }) : '---' }</TableCell>
                    <TableCell className={classes.bodycell}>{ item.created_on ? getMomentTime( item.created_on, 'YYYY-MM-DD' ) : '-'}</TableCell>
                    <TableCell className={classes.bodycell}>
                        <ButtonGroup>
                            <InfoButton size="small" onClick={this.handleEdit.bind(this,item.id)}><i className="fa fa-edit"></i>Edit</InfoButton>
                            <ErrorButton size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton>
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell} colSpan={4}>No Email Templates(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell} style={{ width: "20%" }}>Label</TableCell>
                <TableCell className={classes.headcell}>Organisation(s)</TableCell>
                <TableCell className={classes.headcell}>Created On</TableCell>
                <TableCell className={classes.headcell} style={{ width: "20%" }}>Actions</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { openDeleteModal, deleteModal, openViewModal, modalType } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title={"Add New Email Template"}
                actionLabel={"Add New"}
                onClose={() => this.setState({ openViewModal: false, modalData: false })}
                doAction={this.handleAction}
                contents={this.renderViewForm()} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this email template ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(Templates);