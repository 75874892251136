import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import styled from "styled-components";
import GrapesJS from 'grapesjs';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _last from 'lodash/last';
import _forEach from 'lodash/forEach';


import Controller from './controller';
import TemplateEditor from '../MaintenanceEmailTemplate/editor';

import ModalView from '../../components/ModalView';

import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections } from '../../helpers/data';
import { isArrayExists } from '../../helpers/validation';
import { getMomentNow, getMomentTime, getMomentTimestamp, getMomentNowstamp } from '../../helpers/date';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background,
        textAlign: 'right',
        width: "30%"
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});

const TemplateWrapper = styled.div`
    position: relative;
    pointer-event: none;
    cursor: default;
    border: 2px solid #212121;

    &:before {
        display: block;
        content: " ";
        width: calc( 100% - 12px );
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
    }

    #grapesjs-react-editor {
        .gjs-pn-panels { display: none; }
        .gjs-cv-canvas {
            top: 0;
            height: 100%;
            width: 100%;
        }
    }
`;

class StepSummary extends React.Component {

    state = {
        submitData: false,
        scheduler: 'now',
        selectedDate: getMomentNow('YYYY-MM-DD'),
        selectedTime: getMomentNow('HH:mm'),
        showConfirmation: false
    };

    componentDidMount() {
        const { formData } = this.props;
        if ( formData ) {
            if ( formData.delivery_time && !_isEmpty( formData.delivery_time ) ) {
                if ( getMomentTimestamp( formData.delivery_time ) > getMomentNowstamp() ) {
                    this.setState({ 
                        scheduler: 'date',
                        selectedDate: getMomentTime( formData.delivery_time, 'YYYY-MM-DD' ), 
                        selectedTime: getMomentTime( formData.delivery_time, 'HH:mm' ) 
                    });
                }
            } else {
                this.setState({ scheduler: 'draft_mode' });
            }
        } // end - emailBroadcast
    }

    handleConfirmationAction = () => {
        const { submitData } = this.state;
        if ( submitData && !_isEmpty( submitData ) && this.props.onAction ) {
            // do action
           this.props.onAction(submitData);
        } else {
            triggerErrorAlert('Unknown error occured.');
        } // end - submitData
    }

    handleSchedulerChange = (event) => {
        const selected = event.target.value;
        this.setState({ scheduler: selected });
    }

    handleDateChange = (event) => {
        const selected = event.target.value;
        this.setState({ scheduler: selected });
    }

    handleStepChange = (direction) => {
        const { formData, lists } = this.props;
        const { scheduler, selectedDate, selectedTime } = this.state;
        var submitData = ( formData ? cloneCollections( formData ) : {} );
        
        if ( direction === 'back' ) {
            this.props.goBack();
        } else if ( direction === 'next' ) {
            // do error check before proceed
            var error = false;

            if ( scheduler === 'date' ) {
                if ( selectedDate && !_isEmpty( selectedDate ) && selectedTime && !_isEmpty( selectedTime ) ) {
                    submitData['delivery_time'] = ( selectedDate && !_isEmpty( selectedDate ) ? selectedDate : getMomentNow( 'YYYY-MM-DD' ) ) +'T'+ ( selectedTime && !_isEmpty( selectedTime ) ? selectedTime : getMomentNow( 'HH:mm' ) ) + '+08:00';
                } else {
                    
                    if ( !( selectedTime && !_isEmpty( selectedTime ) ) )
                        error = 'Please select a valid time';

                    if ( !( selectedDate && !_isEmpty( selectedDate ) ) )
                        error = 'Please select a valid date';
                }
            } else if ( scheduler === 'draft_mode' ) {
                submitData['delivery_time'] = '';
            } else {
                submitData['delivery_time'] = getMomentNow( 'YYYY-MM-DD' ) +'T'+ getMomentNow( 'HH:mm' ) + '+08:00';
            } // end - scheduler

            if ( !( scheduler && !_isEmpty( scheduler ) ) ) {
                error = 'Please select a Schedule Type';
            } // end - formData

            if ( error ) {
                triggerErrorAlert(error);
            } else {

                // add in org data for each list
                if ( formData && formData.lists && isArrayExists( formData.lists ) ) {
                    _forEach( formData.lists, (list,index) => {
                        var selected = ( lists && isArrayExists( lists ) ? _find( lists, { id: list.id } ) : false );
                        if ( selected ) {
                            submitData.lists[index].org = ( selected.org || '' );
                        } // end - index
                    });
                } // end - formData

                // trigger popup
                this.setState({ showConfirmation: true, submitData });
            } // end - error

        } // end - direction
    }

    renderConfirmation = () => {
        const { submitData, scheduler, selectedDate, selectedTime } = this.state;
        const { lists, classes } = this.props;
        return (
        <div>
            <p>Please confirm if the following details is correct:</p>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.headcell} component="th">Subject Line</TableCell>
                        <TableCell className={classes.bodycell}>{( submitData && submitData.subject_line || '' )}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.headcell} component="th">From</TableCell>
                        <TableCell className={classes.bodycell}>{( submitData && submitData.from_label || '' ) + ' - ' + ( submitData && submitData.from || '' )}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.headcell} component="th">Selected Lists</TableCell>
                        <TableCell className={classes.bodycell}>
                            { submitData.lists && isArrayExists( submitData.lists ) ? <ul>{_map( submitData.lists, list => {
                                var selected = _find( lists, { id: list.id } );
                                return (
                                <li key={list.id}>
                                    { selected && selected.name + ' ('+( selected.members_count ? selected.members_count : '0' )+')' }
                                </li>
                                );
                            })}</ul> : <p>No list selected.</p> }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.headcell} component="th">Broadcast On:</TableCell>
                        <TableCell className={classes.bodycell}>
                            { scheduler == 'draft_mode' ? 'Unavailable' : ( scheduler == 'now' ? 'Now' : selectedDate + ' / ' + getMomentTime( submitData.delivery_time, 'hh:mm a' ) ) }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
        );
    }

    renderSummary = () => {
        const { formData, lists, classes } = this.props;
        return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell className={classes.headcell} component="th">Message Label<div style={{ fontSize: "0.75em", fontStyle: "italic" }}>(for your reference only)</div></TableCell>
                    <TableCell className={classes.bodycell}>{( formData && formData.label || '' )}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className={classes.headcell} component="th">Subject Line</TableCell>
                    <TableCell className={classes.bodycell}>{( formData && formData.subject_line || '' )}</TableCell>
                </TableRow>
                <TableRow>
                        <TableCell className={classes.headcell} component="th">From</TableCell>
                        <TableCell className={classes.bodycell}>{( formData && formData.from_label || '' ) + ' - ' + ( formData && formData.from || '' )}</TableCell>
                    </TableRow>
                <TableRow>
                    <TableCell className={classes.headcell} component="th">Selected Lists</TableCell>
                    <TableCell className={classes.bodycell}>
                        { formData.lists && isArrayExists( formData.lists ) ? <ul>{_map( formData.lists, list => {
                            var selected = _find( lists, { id: list.id } );
                            return (
                            <li key={list.id}>
                                { selected && selected.name + ' ('+( selected.members_count ? selected.members_count : '0' )+')' }
                            </li>
                            );
                        })}</ul> : <p>No list selected.</p> }
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
        );
    }

    renderScheduleSelector = () => {
        const { scheduler } = this.state;
        return (
        <div style={{ marginTop: "45px" }}>
            <Typography variant="h5" style={{ marginBottom: "15px", paddingBottom: "15px", borderBottom: "1px solid #ddd" }}>Scheduler Settings</Typography>
            <RadioGroup value={scheduler} onChange={this.handleSchedulerChange}>
                <FormControlLabel value="draft_mode" control={<Radio color="primary" />} label={<span style={{ fontSize: "16px" }}>Save as Draft</span>} />
                <FormControlLabel value="now" control={<Radio color="primary" />} label={<span style={{ fontSize: "16px" }}>Broadcast Now</span>} />
                <FormControlLabel value="date" control={<Radio color="primary" />} label={<span style={{ fontSize: "16px" }}>Select a Specific Date</span>} />
            </RadioGroup>
        </div>
        )
    }

    renderDatePicker = () => {
        const { selectedDate, selectedTime } = this.state;
        return (
        <div style={{ marginTop: "15px" }}>
            <div style={{ paddingTop: "5px" }}><TextField
                id="date"
                label="Pick a Date"
                type="date"
                value={selectedDate}
                fullWidth={true}
                onChange={(event) => this.setState({ selectedDate: event.target.value })}
                InputLabelProps={{
                    shrink: true,
                    style: { fontSize: "16px", fontWeight: "700", textTransform: "uppercase" }
                }} /></div>
            <div style={{ paddingTop: "20px" }}><TextField
                id="time"
                label="Pick a Time"
                type="time"
                value={selectedTime}
                fullWidth={true} 
                onChange={(event) => this.setState({ selectedTime: event.target.value })}
                InputLabelProps={{
                    shrink: true,
                    style: { fontSize: "16px", fontWeight: "700", textTransform: "uppercase" }
                }}
                inputProps={{
                    step: 1800,
                }} /></div>
        </div>
        )
    }

    renderEmailContents = () => {
        const { formData, randNum } = this.props;
        return (
        <TemplateWrapper>
            <TemplateEditor 
                template={{
                    contents_html: ( formData.template_html || '' ),
                    contents_css: ( formData.template_css || '' ),
                    contents_js: ( formData.template_js || '' )
                }}
                isPreview={true}
                randNum={randNum} />
        </TemplateWrapper>
        );
    }

    render() {
        const { formData, step } = this.props;
        const { scheduler, showConfirmation } = this.state;
        return (
        <div style={{ maxWidth: "100%", marginLeft: "auto", marginRight: "auto" }}>
            
            <Paper elevation={2} style={{ padding: "30px", background: "#fff" }}>

                <Typography variant="h4" style={{ marginBottom: "30px" }}><div style={{ paddingBottom: "15px", borderBottom: "1px solid #ddd" }}>Summary</div></Typography>
                
                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <div style={{ paddingRight: "15px" }}>
                            {this.renderSummary()}
                            {this.renderScheduleSelector()}
                            { scheduler && scheduler == 'date' ? this.renderDatePicker() : null } 
                        </div>
                    </Grid>
                    <Grid item xs={7}>
                        {this.renderEmailContents()}
                    </Grid>
                </Grid>

            </Paper>

            <Controller step={step} lastStep={true} onClick={this.handleStepChange} />

            <ModalView 
                open={showConfirmation}
                title={"Final Confirmation"}
                actionLabel="Confirm"
                onClose={() => this.setState({ showConfirmation: false, submitData: false })}
                doAction={this.handleConfirmationAction}
                contents={this.renderConfirmation()} />

        </div>
        );
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
)(StepSummary);