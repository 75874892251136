import React from 'react';
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import Papa from 'papaparse';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _split from 'lodash/split';


import FormSelect from '../../components/FormSelect';

import { SuccessButton } from '../../styles/button';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { getMomentTime } from '../../helpers/date';
import { cloneCollections } from '../../helpers/data';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '13px',
        verticalAlign: 'top'
    }
});


class EventsExport extends React.Component {
    
    state = {
        exportType: 'all',

    }

    handleExport = (event) => {
        event.preventDefault();
        const { lists } = this.props;
        const { exportType } = this.state;
        const events = ( lists && isArrayExists( lists ) ? ( exportType !== 'all' ? _filter( lists, { event: exportType } ) : cloneCollections( lists ) ) : false );
        var results = { fields: ['date','event','email'], data: [] };

        if ( exportType === 'all' || exportType === 'clicked' )
            results.fields.push('link');

        if ( exportType === 'all' || exportType === 'failed' )
            results.fields.push('description');

        if ( exportType === 'all' || exportType === 'failed' )
            results.fields.push('message');

        if ( events && isArrayExists( events ) ) {
            _forEach( _sortBy( events, ['timestamp'] ), event => {
                let item = [];

                let date = ( event.timestamp ? getMomentTime( ( event.timestamp * 1000 ), 'YYYY-MM-DD h:mma' ) : '-' );
                item.push( date );

                if ( event.event && event.event === 'failed' )
                    item.push( 'bounced' );
                else
                    item.push( event.event || '' );

                item.push( event.recipient || '' );

                if ( exportType === 'all' || exportType === 'clicked' )
                    item.push( event.url || '' );

                if ( exportType === 'all' || exportType === 'failed' )   
                    item.push( event.delivery_description || '' );

                if ( exportType === 'all' || exportType === 'failed' )   
                    item.push( event.delivery_message || '' );

                results.data.push(item);
            });
        } // end - events

        var csv = Papa.unparse( results, { delimiter: ',' } );
        var blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        var uri = window.URL.createObjectURL(blob);
        var link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "export_broadcast_events_"+exportType+".csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }


    render() {
        const { exportType } = this.state;
        return (
        <Paper elevation={2} style={{ padding: "20px", background: "#fff", marginTop: "25px" }}>
            <Typography variant="h5" style={{ fontWeight: "700", marginBottom: "10px" }}>Export</Typography>
            <div style={{ marginTop: '30px' }}><FormSelect 
                label="Event Type" 
                value={( exportType || '' )} 
                options={[
                    { value: 'all', label: 'All' },
                    { value: 'opened', label: 'Opened' },
                    { value: 'clicked', label: 'Clicked' },
                    { value: 'unsubscribed', label: 'Unsubscribed' },
                    { value: 'failed', label: 'Bounced' },
                    { value: 'complained', label: 'Complained' },
                ]}
                onChange={newValue => this.setState({ exportType: newValue })} /></div>
            <div style={{ marginTop: '20px' }}>
                <SuccessButton onClick={this.handleExport} style={{ padding: "10px 25px" }}><i className="fa fa-file-excel-o"></i>Export to CSV</SuccessButton>
            </div>
        </Paper>
        );
    }

}

export default compose(
    withStyles(useStyles),
    withRouter
)(EventsExport);