import _random from 'lodash/random';

import { 
    GLOBAL_MODAL_PROCESSING,
	GLOBAL_MODAL_DELETING,
    GLOBAL_ERROR_TRIGGER,
    GLOBAL_LOADER_START,
    GLOBAL_LOADER_END,
    GLOBAL_SNACKBAR_TRIGGER
} from '../actions/types';

const initialState = {
    error: null,
    modal_deleting: false,
    modal_deleting_rand: null,
    modal_processing: false,
    modal_processing_rand: null,
    loader: false,
    loader_label: null,
    snackbar_type: null,
    snackbar_message: null,
    snackbar_rand: null
};

export const global = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GLOBAL_ERROR_TRIGGER:
            return {
                ...state,
                error: payload,
            };
        case GLOBAL_MODAL_PROCESSING:
            return {
                ...state,
                modal_processing: payload.status,
                modal_processing_rand: ( payload.rand ? payload.rand : null )
            };
        case GLOBAL_MODAL_DELETING:
            return {
                ...state,
                modal_deleting: payload,
                modal_deleting_rand: ( payload ? _random(1,99999) : null ) 
            };
        case GLOBAL_LOADER_START:
            return {
                ...state,
                loader: true,
                loader_label: ( payload || null ),
            };
        case GLOBAL_LOADER_END:
            return {
                ...state,
                loader: false,
                loader_label: null
            };
        case GLOBAL_SNACKBAR_TRIGGER:
            return {
                ...state,
                snackbar_type: ( payload.type || null ),
                snackbar_message: ( payload.message || null ),
                snackbar_rand: _random(1,9999)
            };
        default:
            return state;
    }
}