import * as firebase from 'firebase/app';

import { getIDToken } from './auth';
import { doPromise } from '../helpers/action';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_performExport = (formData) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'export',
                params: [{ key: 'action', value: 'export_contacts' }],
                formData
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}