import React from 'react';
import styled from "styled-components";
import _isEmpty from 'lodash/isEmpty';

import theme from '../../theme';

class DotsLoader extends React.Component {

    renderMessage = () => {
        const { message } = this.props;
        return <div>{message}</div>
    }

    render() {
        const { style, message, messagePosition } = this.props;
        return (
        <div style={( style || null )}>
            { message && !_isEmpty( message ) && !( messagePosition && messagePosition == 'bottom' ) ? this.renderMessage( message )  : null }
            <div className="dots-loader">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
            { message && !_isEmpty( message ) && ( messagePosition && messagePosition == 'bottom' ) ? this.renderMessage( message )  : null }
        </div>
        )
    }

}

export default DotsLoader