import React from 'react';
import styled from "styled-components";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

import theme from '../../theme';

class FormInput extends React.Component {

    handleChange = (event) => {
        const { name } = this.props;
        if ( this.props.onChange ) {
            this.props.onChange(event.target.value,name);
        }
    }

    render() {
        const { type, label, value, disabled, multiline, rows, rowsMax } = this.props;
        return (
        <FormControl fullWidth={true}>
            <TextField 
                label={( label || '' )} 
                type={( type || 'text' )} 
                disabled={( disabled || false )}
                multiline={( multiline || null )}
                rows={( rows || null )}
                rowsMax={( rowsMax || null )}
                fullWidth={true} 
                InputLabelProps={{
                    style: { fontSize: "16px", fontWeight: "700", textTransform: "uppercase" }
                }}
                margin="normal" 
                variant={( disabled ? "filled" : "outlined" )} 
                value={( value || '' )} 
                onChange={this.handleChange} />
        </FormControl>
        )
    }

}

export default FormInput