import * as firebase from 'firebase/app';

import { getIDToken } from './auth';
import { doPromise } from '../helpers/action';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getReplytoEmails = (callback) => {

    firebase.database().ref("maintenance/replyto_emails").on("value", snapshot => {

        var list = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach( childSnapshot => {
                var val = childSnapshot.val(),
                    item = {
                        id: childSnapshot.key,
                        email: val.email || '',
                        label: val.label || '',
                        created_on: val.created_on || '',
                        orgs: []
                    };

                if ( childSnapshot.child("orgs").exists() && childSnapshot.child("orgs").hasChildren() ) {
                    childSnapshot.child("orgs").forEach(orgSnapshot => {
                        item.orgs.push({ id: orgSnapshot.key });
                    });
                } // end - childSnapshot

                list.push( item );
            });
        } // end - snapshot

        callback( list );
    });

}

export const fb_addNewReplytoEmail = (formData) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'mReplyTo',
                params: [{ key: 'action', value: 'add' }],
                formData
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateReplytoEmail = (formData) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'mReplyTo',
                params: [{ key: 'action', value: 'update' }],
                formData
            })
            .then(res => {
                resolve('done');
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteReplytoEmail = (id) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'mReplyTo',
                params: [{ key: 'action', value: 'delete' }],
                formData: { id }
            })
            .then(res => {
                resolve('done');
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}