import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
    M_GET_REPLYTO_EMAILS,
    M_REPLYTO_EMAILS_UPDATED,
	GLOBAL_ERROR_TRIGGER
} from './types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from './global';
import { triggerErrorAlert, triggerSuccessAlert } from '../helpers/alert';
import { appChangesReset } from './misc';

import { fb_getReplytoEmails, fb_addNewReplytoEmail, fb_updateReplytoEmail, fb_deleteReplytoEmail } from '../firebase/m_replyto_emails';

export const getReplytoEmails = () => {
	return dispatch => {

		fb_getReplytoEmails(list => {
			dispatch({
				type: M_GET_REPLYTO_EMAILS,
				payload: { list }
			});
		});

	}
}

export const addNewReplytoEmail = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addNewReplytoEmail(formData)
		.then( results => {
			dispatch({ type: M_REPLYTO_EMAILS_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Email Added");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new email' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const updateReplytoEmail = (formData) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_updateReplytoEmail(formData)
		.then( results => {
			dispatch({ type: M_REPLYTO_EMAILS_UPDATED });
			dispatch(toggleModalProcessing(false));
			triggerSuccessAlert("Email Updated");
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update email' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteReplytoEmail = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteReplytoEmail(id)
		.then( results => {
			dispatch({ type: M_REPLYTO_EMAILS_UPDATED });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Email Deleted");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete email' ) );
			triggerErrorAlert(errMsg);
		});

	}
}