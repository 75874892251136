import React from 'react';
import styled from "styled-components";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import theme from '../../theme';

class FormCheckbox extends React.Component {

    handleChange = event => {
        const { name, value } = this.props;
        if ( this.props.onChange ) {
            if ( event.target.checked ) {
                this.props.onChange(value,name,'checked');
            } else {
                this.props.onChange(value,name,'unchecked');
            }
        }
    }

    render() {
        const { value, label, checked, disabled } = this.props;
        return <FormControlLabel
                control={
                    <Checkbox
                        checked={( value && value == checked ? true : false )}
                        disabled={disabled || null}
                        onChange={this.handleChange}
                        value={value}
                        color="primary" />
                }
                label={( label || '' )} />
    }

}

export default FormCheckbox