import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';

import { getPercentage } from '../../helpers/number';
import { isArrayExists } from '../../helpers/validation';


class ReportStats extends React.Component {

    getStatNum = (type) => {
        const { broadcast } = this.props;
        var num = 0,
            domains = [];

        if ( broadcast && broadcast.stats && isArrayExists( broadcast.stats ) ) {
            _forEach( broadcast.stats, list => {
                var domain_found = ( list && list.domain_name ? _find( domains, { id: list.domain_name } ) : false ); 
                // make sure there is no duplication from the same domain
                if ( !domain_found && list && list.stats && isArrayExists( list.stats ) ) {

                    // add domain_name to domains - make sure no duplication of stats
                    if ( list.domain_name && !_isEmpty( list.domain_name ) )
                        domains.push({ id: list.domain_name });

                    // calculate stats
                    _forEach( list.stats, stat => {
                        switch( type ) {
                            case 'clicked':
                            case 'opened':
                                if ( stat[type] && stat[type].unique ) {
                                    num += stat[type].unique;
                                }
                                break;
                            case 'failed':
                                if ( stat[type] && stat[type].permanent ) {
                                    num += ( stat[type].permanent['total'] ? stat[type].permanent['total'] : 0 );
                                }
                                break;
                            default:
                                if ( stat[type] && stat[type].total ) {
                                    num += stat[type].total;
                                }
                                break;
                        }  
                    });
                } // end - list.stats
            });
        } // end - broadcast.stats

        // calculate based on events
        if ( broadcast.events && isArrayExists( broadcast.events ) ) {
            switch( type ) {
                case 'clicked':
                    var selected = _filter( broadcast.events, { event: 'clicked' } ),
                        emails = [];
                        
                    num = 0; // reset back to zero

                    _forEach( selected, event => {
                        if ( !_find( emails, { email: event.recipient } ) ) {
                            emails.push({ email: event.recipient }); // to be exclude from future loops
                            num++;
                        } // end - emails
                    });
                    break;
                case 'opened':
                    var selected = _filter( broadcast.events, { event: 'opened' } );
                    num = _size( selected );
                    break;
                case 'failed':
                    var selected = _filter( broadcast.events, { event: 'failed' } );
                    num = _size( selected );
                    break;
            }  
        } // end - broadcast.events

        return num;
    }

    getStatNumLabel = (type,totalSent) => {
        switch( type ) {
            case 'delivered':
                var delivered = this.getStatNum('delivered');
                return getPercentage( delivered, totalSent ) + '% (' + delivered + ')';
            case 'opened':
                var opened = this.getStatNum('opened');
                return getPercentage( opened, totalSent ) + '% (' + opened + ')';
            case 'clicked':
                var clicked = this.getStatNum('clicked');
                return getPercentage( clicked, totalSent ) + '% (' + clicked + ')';
            case 'unsubscribed':
                var unsubscribed = this.getStatNum('unsubscribed');
                return getPercentage( unsubscribed, totalSent ) + '% (' + unsubscribed + ')';
            case 'failed':
                var failed = this.getStatNum('failed');
                return getPercentage( failed, totalSent ) + '% (' + failed + ')';
            case 'complained':
                var complained = this.getStatNum('complained');
                return getPercentage( complained, totalSent ) + '% (' + complained + ')';
            default:
                return totalSent;
        }
        
    }

    getTotalSent = () => {
        const { broadcast } = this.props;
        var totalSent = 0;
        // get total lists sizes
        if ( broadcast && broadcast.lists && isArrayExists( broadcast.lists ) ) {
            _forEach( broadcast.lists, list => {
                totalSent += ( list.members_count || 0 );
            });
        } // end - broadcast.lists
        return totalSent;
    }

    renderStatBox = (label,type,total) => {
        const { labelVariant, statVariant } = this.props;
        return (
        <Paper elevation={2} style={{ padding: "20px", textAlign: "center", background: "#fff" }}>
            <Typography variant={ labelVariant || "h5" } style={{ fontWeight: "700", marginBottom: "10px" }}>{label}</Typography>
            <Typography variant={ statVariant || "h3" }>{this.getStatNumLabel(type,total)}</Typography>
        </Paper>
        );
    }

    render() {
        const { xs, spacing } = this.props;
        var totalSent = this.getTotalSent();
        return (
        <Grid container spacing={( spacing || 3 )}>
            <Grid item xs={( xs || 4 )}>{this.renderStatBox('Delivered','delivered',totalSent)}</Grid>
            <Grid item xs={( xs || 4 )}>{this.renderStatBox('Opened','opened',totalSent)}</Grid>
            <Grid item xs={( xs || 4 )}>{this.renderStatBox('Clicked','clicked',totalSent)}</Grid>
            <Grid item xs={( xs || 4 )}>{this.renderStatBox('Unsubscribed','unsubscribed',totalSent)}</Grid>
            <Grid item xs={( xs || 4 )}>{this.renderStatBox('Bounced','failed',totalSent)}</Grid>
            <Grid item xs={( xs || 4 )}>{this.renderStatBox('Complaints','complained',totalSent)}</Grid>
        </Grid>
        );
    }

}

export default ReportStats;