import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _remove from 'lodash/remove';

import AppWrapper from '../../components/AppWrapper';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormCheckbox from '../../components/FormCheckbox';
import SnackBarSave from '../../components/SnackBarSave';

import { FormRow, FormColumn } from '../../styles/form';
import { InfoButton } from '../../styles/button';
import { ErrorTag } from '../../styles/tag';

import { cloneCollections, getSelectOptions, getSelectValues, reverseSelectValues } from '../../helpers/data';
import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { isSuperAdmin } from '../../helpers/auth';

import { getProfile, updateProfile } from '../../actions/auth';
import { getOrgs } from '../../actions/m_orgs';
import { getRoles } from '../../actions/m_roles';
import { appChangesMade } from '../../actions/misc';

class AuthProfilePage extends React.Component {

    state = {
        user: false,
        randNum: false
    };

    componentDidMount() {
        const { orgsList, rolesList } = this.props;

        // get profile
        this.props.dispatch(getProfile());

        // get orgs
        if ( !orgsList ) {
            this.props.dispatch(getOrgs());
        }

        // get roles
        if ( !rolesList ) {
            this.props.dispatch(getRoles());
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentUser, randNum } = this.props;
        if ( currentUser && randNum && randNum !== this.state.randNum ) {
            this.setState({ user: cloneCollections( currentUser ), randNum });
        } // end - currentUser
    }

    handleSaveChanges = (event) => {
        const { user } = this.state;
        event.preventDefault();
        var error = false,
            formData = cloneCollections( user );

        if ( !( user && user.name && !_isEmpty( user.name ) ) ) {
            error = 'Please insert a valid name';
        } // end - user.name

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(updateProfile(formData));
        } // end - error
    }

    handleFormUpdate = (newValue,key,status) => {
        const { user } = this.state;
        var newData = ( user ? cloneCollections( user ) : {} );
        newData[key] = newValue;
        this.setState({ user: newData });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    renderForm = () => {
        const { orgsList, rolesList } = this.props;
        const { user } = this.state;
        return (
        <Paper elevation={2} style={{ background: "#fff", padding: "20px" }}>

            <FormInput label="Name (Required)" name="name" value={( user.name || '' )} onChange={this.handleFormUpdate} />
            <FormInput label="Email" name="email" type="email" disabled={true} value={( user.email || '' )} onChange={this.handleFormUpdate} />

            <div style={{ paddingTop: "15px" }}>
                <FormSelect label="Role" name="role" value={( user.role || '' )} options={getSelectOptions({
                    list: rolesList,
                    options: [{ value: '', label: 'Select an Option' },{ value: 'superadmin', label: 'Super Admin' },{ value: 'admin', label: 'Admin' }],
                    keys: { value: 'id', label: 'label' }
                })} disabled={true} onChange={this.handleFormUpdate} />
            </div>

            <Grid container spacing={1} style={{ padding: "10px", marginTop: "15px" }}>
                <Grid item xs={12}>
                    <Typography>Organisation</Typography>
                </Grid>
                {_map( orgsList, list => {
                    return (
                    <Grid key={list.id} item xs={6}>
                        <FormCheckbox label={list.label} name="lists" 
                            value={list.id} 
                            checked={( isSuperAdmin(user) ? list.id : ( user.orgs && isArrayExists( user.orgs ) && _find( user.orgs, { id: list.id } ) ? list.id : false ) )} 
                            disabled={true}
                            onChange={this.handleFormUpdate} />
                    </Grid>
                    );
                })}
            </Grid>
        </Paper>
        )
    }

    renderContents() {
        return (
        <div>

            {this.renderForm()}

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { currentUser, orgsList, rolesList } = this.props;
        const { randNum } = this.state;
        return <AppWrapper 
                title="Edit Profile"
                subtitle="Account Settings"
                maxWidth="1110px"
                onLoad={( !( currentUser && orgsList && rolesList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        currentUser: state.auth && state.auth.current_user || null,
        randNum: state.auth && state.auth.rand || null,
        rolesList: state.maintenance && state.maintenance.roles || false,
        orgsList: state.maintenance && state.maintenance.orgs || false,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(AuthProfilePage);