import * as firebase from 'firebase/app';

import { doPromise } from '../helpers/action';

// get user data via onAuth
export const onAuth = (userToken) => {
    return new Promise((resolve, reject) => {
        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'auth',
                params: [{ key: 'action', value: 'auth' }]
            })
            .then(res => {
                var user = res.data;
                user['photoURL'] = ( userToken.photoURL || '' );
                resolve(user);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

// register user upon sign-up
export const onAccountCreated = () => {
    return new Promise((resolve, reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'auth',
                params: [{ key: 'action', value: 'create_account' }]
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

// get current user
export const getCurrentUser = () => {
    return firebase.auth().currentUser;
}

// get current user ID token
export const getIDToken = function(authHeader) {
    return new Promise((resolve,reject) => {

        firebase.auth().currentUser.getIdToken(true)
        .then(function(idToken) {
            resolve(( authHeader ? 'Bearer ' + idToken : idToken ));
        })
        .catch(function(error) {
            reject(error);
        });
          
    });
}

// perform login
export const logoutUser = () => {
    return new Promise((resolve,reject) => {

        firebase.auth().signOut().then(function() {
            resolve('logged_out');
        }).catch(function(error) {
            reject(error);
        });
          
    });
}

// perform google sign-in
export const googleSignIn = () => {
    return new Promise((resolve,reject) => {

        const provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');

        firebase.auth().signInWithPopup(provider).then(function(result) { 
            onAccountCreated()
            .then( user => {
                user['photoURL'] = ( result && result.user && result.user.photoURL || '' );
                resolve(user);
            })
            .catch(function(error) {
                reject(error);
            });
        }).catch(function(error) {
            reject(error);
        });
          
    });
}

// get user profile
export const fb_getProfile = () => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'auth',
                params: [{ key: 'action', value: 'get_profile' }]
            })
            .then(res => {
                var user = res.data;
                resolve(user);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });
          
    });
}

// update user profile
export const fb_updateProfile = (formData) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'auth',
                params: [{ key: 'action', value: 'update_profile' }],
                formData
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });
          
    });
}