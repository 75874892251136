import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Papa from 'papaparse';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import _find from 'lodash/find';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _forEach from 'lodash/forEach';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';

import { InfoButton, InverseButton,  ErrorButton, ButtonGroup, AInfoLink } from '../../styles/button';
import { SuccessTag, ErrorTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch } from '../../helpers/data';


const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});


class ExportContacts extends React.Component {
    
    state = {
        sortBy: 'email-asc',
        perPage: 20,
        page: 1
    }

    handleReset = (event) => {
        event.preventDefault();
        if ( this.props.onReset )
            this.props.onReset();
    }

    reorganizeData() {
        const { searchterms, sortBy, perPage, page } = this.state;
        const { contacts } = this.props;
        var items = ( contacts ? cloneCollections( contacts ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email','subscribed'] );
			total = _size( items );
        } // end - searchterms

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['created_on'] );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, [(o) => o.email.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, [(o) => o.email.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderTableActions = () => {
        const { sortBy, perPage } = this.state;
        return <TableBar
                show={['sort','entries']}
                sortBy={sortBy}
                perPage={perPage}
                sortByOptions={[
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    { value: 'email-asc', label: 'Email ( A - Z)' },
                    { value: 'email-desc', label: 'Email ( Z - A )' }
                ]}
                leftButtons={[
                    <InverseButton minWidth="128px" key="reset" style={{ padding: "10px 25px" }} onClick={this.handleReset}><i className="fa fa-refresh"></i>Reset</InverseButton>
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell}>
                        <WrapWord>{item.email || ''}</WrapWord>
                    </TableCell>
                    <TableCell className={classes.bodycell}>{item.first_name || '-'}</TableCell>
                    <TableCell className={classes.bodycell}>{item.last_name || '-'}</TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No Contact(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell}>Email</TableCell>
                <TableCell className={classes.headcell}>First Name</TableCell>
                <TableCell className={classes.headcell}>Last Name</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { items, total } = this.reorganizeData();
        return (
        <div>

            {this.renderTableActions(total)}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(ExportContacts);