import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import shortid from 'shortid';
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from "@material-ui/core/styles";
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _trim from 'lodash/trim';
import _replace from 'lodash/replace';
import _split from 'lodash/split';
import _toLower from 'lodash/toLower';

import DotsLoader from '../../components/DotsLoader';
import ModalView from '../../components/ModalView';
import FormInput from '../../components/FormInput';

import { InfoButton, AInverseLink, InverseButton, ErrorButton} from '../../styles/button';

import { cloneCollections, doPagination } from '../../helpers/data';
import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';

const useStyles = theme => ({
    headcell: {
        fontSize: '14px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '14px',
        verticalAlign: 'middle'
    }
});

class ListBulkDelete extends React.Component {

    state = {
        list_address: '',
        openConfirmation: false,
        processing: false,
        randNum: false
    };

    handleContactsRemove = () => {
        const { onRemove } = this.props;
        this.setState({ openConfirmation: false, importData: false, list_address: '' });
        if ( onRemove )
            onRemove();
    }

    render() {
        const { list } = this.props;
        const { processing, openConfirmation, list_address } = this.state;
        return (
        <div>

            <div>
                <Typography variant="h4" style={{ color: red['500'], fontWeight: '700', padding: "15px 0" }}>Are you sure you want to remove all the contacts from this list?</Typography>
                <Typography variant="h6" style={{ marginBottom: '0px' }}>To remove all the contacts from this list, please enter the list address below:</Typography>
                <FormInput label="" name="list_address" value={( list_address || '' )} onChange={(newValue) => this.setState({ list_address: newValue })} />
                { list_address && !_isEmpty( list_address ) && list && list.id && list.domain_name && list_address === (list.id+'@'+list.domain_name) ? <ErrorButton 
                    style={{ padding: '10px 25px' }}
                    onClick={() => this.setState({ openConfirmation: true })}>Yes, Delete</ErrorButton> : null }
            </div>

            <ModalView
                open={( openConfirmation || false )}
                title={"Final Confirmation"}
                actionLabel="Confirm"
                onClose={() => this.setState({ openConfirmation: false })}
                doAction={this.handleContactsRemove}
                contents={(
                <div>
                    <p>Please confirm if you want to remove all the contact(s) from this list?</p>
                    <Typography variant="h6" style={{ color: red['500'], fontWeight: '700', padding: "15px", background: red['50'] }}>WARNING: This action cannot be undone.</Typography>
                </div>
                )} />

        </div>
        );
    }

}


export default compose(
    withRouter,
    withStyles(useStyles)
)(ListBulkDelete);