import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { appChangesReset } from '../../actions/misc';

import { DEV_MODE } from '../../constants';

class RouterModifier extends React.Component {


    componentDidUpdate(prevProps, prevState, snapshot) {
        // make page start from top when route changed
        if ( this.props.location.pathname !== prevProps.location.pathname ) {
            window.scrollTo(0,0);
        }

        window.onbeforeunload = (e) => {
            // if changes not saved - trigger warning when back button pressed
            if ( this.props.changesNotSaved && !DEV_MODE ) {
                var message = "You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?";
                e.returnValue = false;
                return message;
            } // end - changesNotSaved
        }

    }

    componentWillUnmount () {
        window.onbeforeunload = () => {}
    }

    render() {
        return this.props.children;
    }

}

const mapStateToProps = state => {
    return {
        changesNotSaved: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(RouterModifier);