import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	M_GET_EMAIL_TEMPLATES,
	M_GET_EMAIL_TEMPLATE,
    M_ADD_EMAIL_TEMPLATE,
    M_EDIT_EMAIL_TEMPLATE,
    M_DELETE_EMAIL_TEMPLATE,
	GLOBAL_ERROR_TRIGGER
} from './types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from './global';
import { triggerErrorAlert, triggerSuccessAlert } from '../helpers/alert';
import { appChangesReset } from './misc';

import { fb_getEmailTemplates, fb_getEmailTemplate, fb_addEmailTemplate, fb_updateEmailTemplate, fb_deleteEmailTemplate } from '../firebase/email_templates';

export const getEmailTemplates = () => {
	return dispatch => {

        fb_getEmailTemplates(list => {
			dispatch({ 
                type: M_GET_EMAIL_TEMPLATES, 
                payload: { list } 
            });
		});

	}
}

export const getEmailTemplate = (id) => {
	return dispatch => {

		fb_getEmailTemplate(id)
        .then(single => {
			dispatch({ 
                type: M_GET_EMAIL_TEMPLATE, 
                payload: { single } 
            });
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to email template' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: M_GET_EMAIL_TEMPLATE,
				payload: { single: {} }
			});
		});

	}
}

export const addEmailTemplate = (formData,history) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addEmailTemplate(formData)
  		.then( results  => {
			if ( results && results.new_template_id && !_isEmpty( results.new_template_id ) ) {
				fb_getEmailTemplate( results.new_template_id )
				.then( single => {

					// updated
					dispatch({ type: M_GET_EMAIL_TEMPLATE, payload: { single } });
					dispatch({ type: M_ADD_EMAIL_TEMPLATE });
					dispatch(toggleModalProcessing(false));
					triggerSuccessAlert("Email Template Added");

					// redirect to contact page
					history.push("/maintenance/email_templates/" + results.new_template_id);

				});
			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new template ID');
			}
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new template' ) );
			triggerErrorAlert(errMsg);
		});
  }
}

export const editEmailTemplate = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateEmailTemplate(formData)
		.then( results => {
			fb_getEmailTemplate(formData.id)
			.then(single => {
				// push to list
				dispatch({ type: M_GET_EMAIL_TEMPLATE, payload: { single } });

				// updated
				dispatch({ type: M_EDIT_EMAIL_TEMPLATE });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Email Template Updated");

			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update email template' ) );
			triggerErrorAlert(errMsg);
		});
  }
}

export const deleteEmailTemplate = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteEmailTemplate(id)
		.then( results => {
			// trigger updated
			dispatch({ type: M_DELETE_EMAIL_TEMPLATE });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Email Template Deleted");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete email template' ) );
			triggerErrorAlert(errMsg);
		});

  }
}