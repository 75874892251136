import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GrapesJS from 'grapesjs';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _last from 'lodash/last';

import AppWrapper from '../../components/AppWrapper';
import FormInput from '../../components/FormInput';
import FormCheckbox from '../../components/FormCheckbox';
import SnackBarSave from '../../components/SnackBarSave';
import MediaLibrary from '../../components/MediaLibrary';

import TemplateEditor from './editor';

import { InfoButton } from '../../styles/button';

import { cloneCollections } from '../../helpers/data';
import { isArrayExists } from '../../helpers/validation';
import { isSuperAdmin } from '../../helpers/auth';
import { triggerErrorAlert } from '../../helpers/alert';

import { getEmailTemplate, editEmailTemplate } from '../../actions/m_email_templates';
import { getOrgs } from '../../actions/m_orgs';
import { appChangesMade } from '../../actions/misc';

class EmailTemplate extends React.Component {

    state = {
        current: false,
        randNum: true
    };

    componentDidMount() {
        const { template_id } = this.props.match.params;
        const { orgsList } = this.props;

        // get email template
        this.props.dispatch(getEmailTemplate(template_id));

        // get orgs
        if ( !orgsList ) {
            this.props.dispatch(getOrgs());
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { emailTemplate, randNum } = this.props;

        // update template details
        if ( emailTemplate && randNum && randNum != this.state.randNum ) {
            this.setState({ current: emailTemplate, randNum });
        } // end - mailingList
    }

    handleSaveChanges = (event) => {
        const { current } = this.state;
        const editor = ( GrapesJS.editors && isArrayExists( GrapesJS.editors ) ? _last( GrapesJS.editors ) : false );
        event.preventDefault();
        var error = false,
            formData = cloneCollections( current );

        // compile editor contents
        if ( editor ) {
            formData['contents_html'] = editor.getHtml();
            formData['contents_css'] = editor.getCss();
            formData['contents_js'] = editor.getJs();
        } // end - editor

        if ( !( current && current.orgs && isArrayExists( current.orgs ) ) ) {
            error = 'Please select at least one organisation';
        } // end - current.orgs

        if ( !( current && current.label && !_isEmpty( current.label ) ) ) {
            error = 'Please insert a valid label';
        } // end - current.label

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editEmailTemplate(formData));
        } // end - error
    }

    handleFormUpdate = (newValue,key,status) => {
        const { current } = this.state;
        var newData = ( current ? cloneCollections( current ) : {} );

        if ( key == 'orgs' ) {
            var newOrgs = ( current.orgs && isArrayExists( current.orgs ) ? cloneCollections(current.orgs) : [] );
            if ( status == 'checked' ) {
                if ( !_find( newOrgs, { id: newValue } ) )
                    newOrgs.push({ id: newValue });
            } else {
                var pulled = _remove( newOrgs, { id: newValue } );
            } // end - status
            newData[key] = newOrgs;
        } else {
            newData[key] = newValue;
        }
        
        this.setState({ current: newData });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    renderEditor = () => {
        const { current, randNum } = this.state;
        return (
        <div style={{ marginTop: "45px" }}>
            <div style={{ marginBottom: "20px" }}>
                <MediaLibrary />
            </div>
            <TemplateEditor 
                template={( current || null )}
                randNum={( randNum || false )} />
        </div>
        );
    }

    renderGeneral = () => {
        const { current } = this.state;
        const { authData, orgsList } = this.props;
        return (
        <Paper elevation={2} style={{ background: "#fff", padding: "20px" }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormInput label="label" name="label" value={( current.label || '' )} onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ padding: "10px" }}>
                <Grid item xs={12}>
                    <Typography>Organisations (Required)</Typography>
                </Grid>
                {_map( orgsList, org => {
                    return (
                    <Grid key={org.id} item xs={6}>
                        <FormCheckbox label={org.label} name="orgs" 
                            value={org.id} 
                            checked={( current.orgs && isArrayExists( current.orgs ) && _find( current.orgs, { id: org.id } ) ? org.id : false )} 
                            disabled={( isSuperAdmin( authData ) ? false : ( authData.orgs && !_isEmpty( authData.orgs ) && authData.orgs.indexOf(org.id) >= 0 ? false : true ) )} // disabled it if current user is from different org
                            onChange={this.handleFormUpdate} />
                    </Grid>
                    );
                })}
            </Grid>
        </Paper>
        )
    }

    renderContents() {
        return (
        <div>
            
            {this.renderGeneral()}
            {this.renderEditor()}

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { template_id } = this.props.match.params;
        const { orgsList } = this.props;
        const { current, randNum } = this.state;
        return <AppWrapper 
                title={( current && current.label && !_isEmpty( current.label ) ? current.label : '' )}
                subtitle="Email Templates"
                back="/maintenance/email_templates"
                breadcrumbs={[
                    { url: '/maintenance/email_templates', label: 'Email Templates' },
                    { label: 'Edit' }
                ]}
                onLoad={( !( current && orgsList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        emailTemplate: state.emailtemplates && state.emailtemplates.single || null,
        randNum: state.emailtemplates && state.emailtemplates.rand || null,
        orgsList: state.maintenance && state.maintenance.orgs || false,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(EmailTemplate);