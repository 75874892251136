import _random from 'lodash/random';

import { 
    GET_EMAIL_BROADCASTS,
    GET_EMAIL_BROADCAST,
    CREATE_EMAIL_BROADCAST,
    UPDATE_EMAIL_BROADCAST,
    DELETE_EMAIL_BROADCAST,
    RESET_EMAIL_BROADCAST,
    SAMPLE_ACTION 
} from '../actions/types';

const initialState = {
    sample: null,
    rand: false
};

export const email_broadcasts = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_EMAIL_BROADCASTS:
            return {
                ...state,
                broadcasts: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_EMAIL_BROADCAST:
            return {
                ...state,
                broadcast: ( payload.single || {} ),
                rand: _random(1,9999)
            };
        case CREATE_EMAIL_BROADCAST:
        case UPDATE_EMAIL_BROADCAST:
        case DELETE_EMAIL_BROADCAST:
            return {
                ...state,
                rand: _random(1,9999)
            };
        case RESET_EMAIL_BROADCAST:
            return {
                ...state,
                broadcast: false,
                rand: false
            };
        default:
            return state;
    }
}