import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _remove from 'lodash/remove';

import AppWrapper from '../../components/AppWrapper';
import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormCheckbox from '../../components/FormCheckbox';
import SnackBarSave from '../../components/SnackBarSave';

import { FormRow, FormColumn } from '../../styles/form';
import { InfoButton, AInfoLink, ButtonGroup } from '../../styles/button';
import { ErrorTag } from '../../styles/tag';

import { cloneCollections, getSelectOptions, getSelectValues, reverseSelectValues } from '../../helpers/data';
import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { isSuperAdmin } from '../../helpers/auth';
import { getMomentTime } from '../../helpers/date';

import { getContact, editContact } from '../../actions/contacts';
import { getLists } from '../../actions/lists';
import { appChangesMade, appChangesReset } from '../../actions/misc';

import { YES_NO_OPTIONS  } from '../../constants';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});

class ContactPage extends React.Component {

    state = {
        contact: false,
        lists: false,
        randNum: false
    };

    componentDidMount() {
        const { contact_id } = this.props.match.params;
        
        // retrieve contact details
        this.props.dispatch(getContact(contact_id));

        // get lists
        this.props.dispatch(getLists());

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedContact, mailingLists, randNum } = this.props;
        console.log( selectedContact, randNum );
        // update contact details
        if ( selectedContact && randNum && randNum != this.state.randNum ) {
            this.setState({ contact: selectedContact, randNum });
        } // end - selectedContact

        // update lists
        if ( mailingLists && !this.state.lists ) {
            this.setState({ lists: mailingLists });
        }

    }

    handleClick(url,event) {
        const { history, changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                history.push(url);
            }
        } else {
            history.push(url);
        }

    }

    handleSaveChanges = (event) => {
        const { contact } = this.state;
        event.preventDefault();
        var error = false,
            formData = cloneCollections( contact );

        if ( !( contact && contact.email && validateEmail( contact.email ) ) ) {
            error = 'Please insert a valid email address';
        } // end - contact.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            this.props.dispatch(editContact(formData));
        } // end - error
    }

    handleFormUpdate = (newValue,key,status) => {
        const { contact } = this.state;
        var newData = ( contact ? cloneCollections( contact ) : {} );

        if ( key == 'lists' ) {
            var newLists = ( contact.lists && isArrayExists( contact.lists ) ? cloneCollections(contact.lists) : [] );
            if ( status == 'checked' ) {
                if ( !_find( newLists, { id: newValue } ) )
                    newLists.push({ id: newValue });
            } else {
                var pulled = _remove( newLists, { id: newValue } );
            } // end - status
            newData[key] = newLists;
        } else {
            newData[key] = newValue;
        }
        
        this.setState({ contact: newData });

        // trigger changes made
        this.props.dispatch(appChangesMade());
    }

    getLists = () => {
        const { lists } = this.state;
        var items = [];
        if ( lists && isArrayExists( lists ) ) {
            lists.forEach(list => {
                items.push({ 
                    id: list.id, 
                    label: list.name + ( list.org_label && !_isEmpty( list.org_label ) ? ' ('+list.org_label+')' : '' ), 
                    org: list.org || '' 
                });
            });
        } // end - lists
        return items;
    }

    renderBouncedRecords = () => {
        const { contact } = this.state;
        const { classes } = this.props;
        return (
        <Paper elevation={2} style={{ background: "#fff", padding: "40px 20px", marginTop: "45px" }}>
            <Typography variant="h5">Bounced Record</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.headcell}>Label</TableCell>
                        <TableCell className={classes.headcell}>Delivery Time</TableCell>
                        <TableCell className={classes.headcell} style={{ width: "20%" }}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                { contact.bounced_records && isArrayExists( contact.bounced_records ) ? contact.bounced_records.map(item => {
                    return (
                    <TableRow key={item.id}>
                        <TableCell className={classes.bodycell}>{( item.label || '' )}</TableCell>
                        <TableCell className={classes.bodycell}>{ item.delivery_time && !_isEmpty( item.delivery_time ) ? getMomentTime( item.delivery_time, 'YYYY-MM-DD hh:mma' ) : '-'}</TableCell>
                        <TableCell className={classes.bodycell}>
                            <ButtonGroup>
                                <InfoButton size="small" onClick={this.handleClick.bind(this,"/reports/broadcast/?broadcast_id="+item.id)}><i className="fa fa-search"></i>View</InfoButton>
                            </ButtonGroup>
                        </TableCell>
                    </TableRow>
                    )
                }) : (
                    <TableRow>
                        <TableCell className={classes.bodycell}>No Contact(s) found.</TableCell>
                    </TableRow>
                ) }
            </TableBody>
            </Table>

        </Paper>
        );
    }

    renderGeneral = () => {
        const { contact } = this.state;
        const { authData } = this.props;
        return (
        <Paper elevation={2} style={{ background: "#fff", padding: "20px" }}>

            { contact && contact.bounced && contact.bounced === 'yes' ? <div style={{ textAlign: 'right' }}><ErrorTag>Bounced</ErrorTag></div> : null }

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormInput label="Email (Required)" name="email" type="email" disabled={true} value={( contact.email || '' )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={6}>
                    <FormInput label="First Name" name="first_name" value={( contact.first_name || '' )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={6}>
                    <FormInput label="Last Name" name="last_name" value={( contact.last_name || '' )} onChange={this.handleFormUpdate} />
                </Grid>
                <Grid item xs={12}>
                    <FormSelect label="Subscribed?" name="subscribed" value={( contact.subscribed || '' )} options={YES_NO_OPTIONS} onChange={this.handleFormUpdate} />
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ padding: "10px" }}>
                <Grid item xs={12}>
                    <Typography>Lists (Required)</Typography>
                </Grid>
                {_map( this.getLists(), list => {
                    return (
                    <Grid key={list.id} item xs={6}>
                        <FormCheckbox label={(
                        <div>
                            {list.label} 
                        {( contact && contact.old_lists && isArrayExists( contact.old_lists ) && _find( contact.old_lists, { id: list.id } ) ? <ErrorTag style={{ marginLeft: "5px" }}>{( contact && contact.bounced && contact.bounced == 'yes' ? 'Bounced' : 'Unsubscribed' )}</ErrorTag> : null )}
                        </div>
                        )} name="lists"
                            value={list.id} 
                            checked={( contact.lists && isArrayExists( contact.lists ) && _find( contact.lists, { id: list.id } ) ? list.id : false )} 
                            disabled={( isSuperAdmin( authData ) ? false : ( authData.orgs && !_isEmpty( authData.orgs ) && authData.orgs.indexOf(list.org) >= 0 ? false : true ) )} // disabled it if current user is from different org
                            onChange={this.handleFormUpdate} />
                    </Grid>
                    );
                })}
            </Grid>
        </Paper>
        )
    }

    renderContents() {
        const { contact } = this.state;
        return (
        <div>

            {this.renderGeneral()}
            { contact && contact.bounced && contact.bounced === 'yes' && contact.bounced_records && isArrayExists( contact.bounced_records ) ? this.renderBouncedRecords() : null }

            <div style={{ textAlign: "center", marginTop: "45px" }}>
                <InfoButton style={{ padding: "15px 5px" }} minWidth="250px" onClick={this.handleSaveChanges}><i className="fa fa-save"></i>Save Changes</InfoButton>
            </div>

            <SnackBarSave onSave={this.handleSaveChanges} />

        </div>
        );
    }

    render() {
        const { contact_id } = this.props.match.params;
        const { contact, lists, randNum } = this.state;
        return <AppWrapper 
                title="Edit Contact"
                subtitle="Contacts"
                back="/contacts"
                breadcrumbs={[
                    { url: '/contacts', label: 'Contacts' },
                    { label: 'Edit' }
                ]}
                maxWidth="1110px"
                onLoad={( !( contact && ( ( contact.id && contact_id == contact.id ) || _isEmpty( contact ) ) && lists && randNum ) ? true : false )}
                contents={( contact && !_isEmpty( contact ) ? this.renderContents() : <div><Paper elevation={2} style={{ background: "#fff", padding: "40px" }}><Typography variant="h3" align="center">No Contact found!</Typography></Paper></div> )} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        selectedContact: state.contacts && state.contacts.contact || null,
        randNum: state.contacts && state.contacts.rand || null,
        mailingLists: state.lists && state.lists.lists || null,
        changesNotSaved: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles),
    withRouter
)(ContactPage);