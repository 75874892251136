import _random from 'lodash/random';

import { 
    M_GET_EMAIL_TEMPLATES,
    M_GET_EMAIL_TEMPLATE,
    M_ADD_EMAIL_TEMPLATE,
    M_EDIT_EMAIL_TEMPLATE,
    M_DELETE_EMAIL_TEMPLATE
} from '../actions/types';

const initialState = {
    sample: null,
    rand: false
};

export const emailtemplates = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case M_GET_EMAIL_TEMPLATES:
            return {
                ...state,
                list: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case M_GET_EMAIL_TEMPLATE:
            return {
                ...state,
                single: ( payload.single || {} ),
                rand: _random(1,9999)
            };
        case M_ADD_EMAIL_TEMPLATE:
        case M_EDIT_EMAIL_TEMPLATE:
        case M_DELETE_EMAIL_TEMPLATE:
            return {
                ...state,
                rand: _random(1,9999)
            };
        default:
            return state;
    }
}