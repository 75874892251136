import React from 'react';
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import shortid from 'shortid';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _size from 'lodash/size';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';

import Stats from './stats';
import TemplateEditor from '../MaintenanceEmailTemplate/editor';
import EventsTable from './events';
import EventsExport from './export';

import { cloneCollections } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { getPercentage } from '../../helpers/number';
import { isArrayExists } from '../../helpers/validation';
import { isSuperAdmin } from '../../helpers/auth';

import { InverseButton, SuccessButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';

const useStyles = theme => ({
    headcell: {
        fontSize: '14px',
        fontWeight: "700",
        color: theme.palette.background,
        textAlign: 'right',
        width: "35%"
    },
    bodycell: {
        fontSize: '14px',
        verticalAlign: 'top'
    },
    linkheadcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background,
    },
    linkbodycell: {
        fontSize: '14px',
        verticalAlign: 'top'
    }
});

const TemplateWrapper = styled.div`
    position: relative;
    pointer-event: none;
    cursor: default;
    border: 2px solid #212121;

    &:before {
        display: block;
        content: " ";
        width: calc( 100% - 12px );
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
    }

    #grapesjs-react-editor {
        .gjs-pn-panels { display: none; }
        .gjs-cv-canvas {
            top: 0;
            height: 100%;
            width: 100%;
        }
    }
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
    <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`list-tabpanel-${index}`}
        aria-labelledby={`list-tab-${index}`}
        {...other}>
        <Box p={0}>{children}</Box>
    </Typography>
    );
}
function a11yProps(index) {
    return {
        id: `list-tab-${index}`,
        'aria-controls': `list-tabpanel-${index}`,
    };
}

class Report extends React.Component {

    state = {
        currentTab: 0,
        randNum: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleRefresh = () => {
        const { onRefresh } = this.props;
        if ( onRefresh )
            onRefresh();
    }

    getTotalLinksClicked = () => {
        const { broadcast } = this.props;
        let links = [];
        const events = ( broadcast && broadcast.events && isArrayExists( broadcast.events ) ? _filter( broadcast.events, { event: 'clicked' } ) : false );

        if ( events && isArrayExists( events ) ) {
            _forEach( events, event => {
                if ( event.url && !_isEmpty( event.url ) ) {
                    let index = _findIndex( links, { url: event.url } );
                    if ( index >= 0 ) {
                        links[index].count++;
                    } else {
                        links.push({
                            id: shortid.generate(),
                            url: event.url,
                            count: 1
                        });
                    } // end - index
                } // end - event.url
            });
        } // end - events

        return links;
    }

    renderEmailContent = () => {
        const { broadcast, randNum } = this.props;
        return (
        <TemplateWrapper>
            <TemplateEditor 
                template={{
                    contents_html: ( broadcast.template_html || '' ),
                    contents_css: ( broadcast.template_css || '' ),
                    contents_js: ( broadcast.template_js || '' )
                }}
                isPreview={true}
                randNum={randNum} />
        </TemplateWrapper>
        );
    }

    renderEmailTable = () => {
        const { broadcast, classes } = this.props;
        return (
        <Paper elevation={2} style={{ padding: "20px", textAlign: "center", background: "#fff" }}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell className={classes.headcell} component="th">Sent on</TableCell>
                        <TableCell className={classes.bodycell}>{( broadcast && broadcast.delivery_time ? getMomentTime( broadcast.delivery_time, 'YYYY-MM-DD hh:mma' ) : '' )}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.headcell} component="th">Subject Line</TableCell>
                        <TableCell className={classes.bodycell}>{( broadcast && broadcast.subject_line || '' )}</TableCell>
                    </TableRow>
                    <TableRow>
                            <TableCell className={classes.headcell} component="th">From</TableCell>
                            <TableCell className={classes.bodycell}>
                                <div>{( broadcast && broadcast.from_label || '' )}</div>
                                <WrapWord>{( broadcast && broadcast.from || '' )}</WrapWord>
                            </TableCell>
                        </TableRow>
                    <TableRow>
                        <TableCell className={classes.headcell} component="th">Selected Lists</TableCell>
                        <TableCell className={classes.bodycell}>
                            { broadcast.lists && isArrayExists( broadcast.lists ) ? <ul>{_map( broadcast.lists, list => {
                                return (
                                <li key={list.id}>
                                    { list && list.name + ' ('+( list.members_count ? list.members_count : '0' )+')' }
                                </li>
                                );
                            })}</ul> : <p>No list selected.</p> }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
        );
    }

    renderLinkClicked = () => {
        const { classes } = this.props;
        const links = this.getTotalLinksClicked();
        return (
        <div style={{ marginBottom: "45px" }}>
        <Paper elevation={2} style={{ background: "#fff" }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.linkheadcell}>Link(s)</TableCell>
                        <TableCell className={classes.linkheadcell} style={{ width: "15%" }}>Total Click(s)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { links && isArrayExists( links ) ? _map( links, link => {
                        return (
                        <TableRow key={link.id}>
                            <TableCell className={classes.linkbodycell}><WrapWord>{ link.url || '' }</WrapWord></TableCell>
                            <TableCell className={classes.linkbodycell}>{link.count || 0}</TableCell>
                        </TableRow>
                        )
                    }) : (
                    <TableRow>
                        <TableCell className={classes.linkbodycell} colSpan>No data yet.</TableCell>
                    </TableRow>
                    ) }
                </TableBody>
            </Table>
        </Paper>
        </div>
        );
    }

    renderEventTabs = () => {
        const { currentTab } = this.state;
        const { broadcast } = this.props;
        return (
        <div style={{ marginBottom: "45px" }}>
            <AppBar position="static" color="default" style={{ marginTop: "25px", background: "#fff" }}>
                <Tabs value={currentTab} onChange={(event,newValue) => this.setState({ currentTab: newValue })} aria-label="Report Tab">
                    <Tab label="Opened" {...a11yProps(0)} />
                    <Tab label="Clicked" {...a11yProps(1)} />
                    <Tab label="Unsubscribed" {...a11yProps(2)} />
                    <Tab label="Bounced" {...a11yProps(3)} />
                    <Tab label="Complained" {...a11yProps(4)} />
                    <Tab label="Export" {...a11yProps(5)} />
                </Tabs>
            </AppBar>
            <TabPanel value={currentTab} index={0}>
                <EventsTable lists={( broadcast && broadcast.events || [] )} eventType='opened' />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                <EventsTable lists={( broadcast && broadcast.events || [] )} eventType='clicked' />
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
                <EventsTable lists={( broadcast && broadcast.events || [] )} eventType='unsubscribed' />
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
                <EventsTable lists={( broadcast && broadcast.events || [] )} eventType='failed' />
            </TabPanel>
            <TabPanel value={currentTab} index={4}>
                <EventsTable lists={( broadcast && broadcast.events || [] )} eventType='complained' />
            </TabPanel>
            <TabPanel value={currentTab} index={5}>
                <EventsExport broadcast={( broadcast || false )} lists={( broadcast && broadcast.events || [] )} />
            </TabPanel>
        </div>
        );
    }

    renderStats = () => {
        const { broadcast } = this.props;
        return (
        <div style={{ margin: "45px auto" }}>
            {this.renderRefreshButton()}
            <Stats broadcast={broadcast || false} />
        </div>
        );
    }

    renderRefreshButton = () => {
        return (
        <div style={{ paddingBottom: "25px", textAlign: "right" }}>
            <InverseButton style={{ padding: "10px 25px" }} onClick={this.handleRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
        </div>
        );
    }

    render() {
        const { broadcast } = this.props;
        return (
        <div>

            {this.renderStats()}
            {this.renderLinkClicked()}
            {this.renderEventTabs()}

            <Grid container spacing={2}>
                <Grid item xs={5}>{this.renderEmailTable()}</Grid>
                <Grid item xs={7}>{this.renderEmailContent()}</Grid>
            </Grid>

        </div>
        );
    }

}

export default compose(
    withRouter,
    withStyles(useStyles)
)(Report);