import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

import {
    GET_MEDIA_LIBRARY,
    UPLOAD_NEW_MEDIA,
    EDIT_MEDIA,
    DELETE_MEDIA,
	GLOBAL_ERROR_TRIGGER
} from './types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from './global';
import { triggerErrorAlert, triggerSuccessAlert } from '../helpers/alert';
import { isArrayExists } from '../helpers/validation';
import { appChangesReset } from './misc';

import { fb_getAllMedia, fb_uploadNewMedia, fb_deleteMedia } from '../firebase/media_library';

export const getAllMedia = () => {
	return dispatch => {

		fb_getAllMedia(list => {
			dispatch({
				type: GET_MEDIA_LIBRARY,
				payload: { list }
			});
		});
  		
	}
}

export const uploadNewMedia = (files,authData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		var promises = [];

		_forEach( files, file => {
			promises.push( fb_uploadNewMedia(file,authData) );
		});

		Promise.all( promises )
		.then( results => {
			dispatch({ type: UPLOAD_NEW_MEDIA });
			dispatch(toggleLoader(false));
			triggerSuccessAlert("Upload Process Completed");
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to upload file' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editMedia = () => {
	return dispatch => {

  		dispatch({ type: EDIT_MEDIA });

	}
}

export const deleteMedia = (medias) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		var promises = [];

		_forEach( medias, media => {
			promises.push( fb_deleteMedia(media) );
		});

		Promise.all( promises )
		.then( results => {
			// trigger updated
			dispatch({ type: DELETE_MEDIA });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("File(s) Deleted");
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete file' ) );
			triggerErrorAlert(errMsg);
		});

	}
}