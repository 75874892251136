/*
This file contains all constants in the app.
*/

const api_env = "live";
export const DEV_MODE = api_env && api_env === "live" ? false : true;

// firebase related
const FIREBASE_SERVE = true;
export const FIREBASE_API = "https://dreamcatcher-mailing.firebaseio.com/";
export const FIREBASE_CLOUD_API =
    FIREBASE_SERVE && DEV_MODE
        ? "http://localhost:5000/dreamcatcher-mailing/asia-northeast1/"
        : "https://asia-northeast1-dreamcatcher-mailing.cloudfunctions.net/";

// list of access rights
export const ACCESS_RIGHTS = [
    { id: "ctr", label: "Contacts (Read)", width: 6 },
    { id: "ctw", label: "Contacts (Write)", width: 6 },
    { id: "lsr", label: "Lists (Read)", width: 6 },
    { id: "lsw", label: "Lists (Write)", width: 6 },
    { id: "emb", label: "Email Broadcast", width: 12 },
    { id: "rpr", label: "Reports (Read)", width: 12 },
    { id: "mtr", label: "Maintenance (Read)", width: 6 },
    { id: "mtw", label: "Maintenance (Write)", width: 6 },
];

// options
export const ENTRIES_OPTIONS = [
    { value: 5, label: "5" },
    { value: 20, label: "20" },
    { value: 40, label: "40" },
    { value: 60, label: "60" },
    { value: 80, label: "80" },
    { value: 100, label: "100" },
    { value: 200, label: "200" },
    { value: 500, label: "500" },
];

export const MONTH_OPTIONS = [
    { value: "01", label: "Jan" },
    { value: "02", label: "Feb" },
    { value: "03", label: "Mar" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "Aug" },
    { value: "09", label: "Sept" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
];

export const GENDER_OPTIONS = [
    { value: "", label: "Select Gender" },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
];
export const YES_NO_OPTIONS = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
];

export const DEFAULT_EMAIL_TEMPLATE = {
    html: '<table class="c1617"><tbody style="box-sizing: border-box;"><tr style="box-sizing: border-box;"><td style="box-sizing: border-box; vertical-align: top; margin: 0; padding: 0;"></td></tr></tbody></table>',
    css: "* { box-sizing: border-box; } body {margin: 0;}body{background-color:#f1f1f1;}*{box-sizing:border-box;}body{margin-top:0px;margin-right:0px;margin-bottom:0px;margin-left:0px;}.c1617{height:100%;margin:10px auto 10px auto;padding:5px 5px 5px 5px;width:100%;max-width:650px;background-color:#ffffff;font-family:Arial, Helvetica, sans-serif;border:1px solid #dddddd;}",
    js: "",
};
