import React from 'react';
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { connect } from "react-redux";
import styled from "styled-components";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _startCase from 'lodash/startCase';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';

import PopperBox from '../Popper';

import { H3 } from '../../styles/heading';
import { FlexColumn } from '../../styles/division';
import { ErrorLink, GreyLink, InverseLink } from '../../styles/button';

import { isArrayExists } from '../../helpers/validation';
import { hasAccessRights, isAdmin, isSuperAdmin } from '../../helpers/auth';

import { logout } from '../../actions/auth';
import { appChangesReset } from '../../actions/misc';

import theme from '../../theme';


const HeaderWrapper = styled.header`
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background: #fff;
    padding: 0px 0px 0px 30px;
    border-bottom: 1px solid #ddd;
`;

const Nav = styled.nav`
    display: block;

    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;

        li {
            display: block;
            padding: 0px;
            margin: 0px;
            height: 64px;
        }

    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 0px 5px;
        min-width: 64px;
        text-align: center;
        color: ${theme.palette.background};
        font-weight: 700;
        box-shadow:inset 0 0 0 0 #fff;
        text-transform: uppercase;

        
        &:hover,
        &:active,
        &.active {
            color: ${theme.palette.primary.main};
            box-shadow:inset 0 -5px 0 0 ${theme.palette.primary.main};
        }
    }
`;

const ToggleButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.35ms ease-in-out;
    cursor: pointer;
    padding: 0px 45px;
    height: 100%;
    vertical-align: middle;
    border-radius: 45px 0px 0px 45px;

    i { 
        font-size: 30px;
        margin-left: 30px;
        
        &:first-child {
            margin-left: 0px;
        }
    }

    &.opened {
        background: ${theme.palette.primary.main};
        color: #fff;
    }
`;

const UserDetails = styled.div`
    padding: 20px;
    border-bottom: 1px solid #ddd;
`;

const UserSubMenuWrapper = styled.div`
    padding: 20px;
`;

class Header extends React.Component {

    state = {
        openSubMenu: false,
        subMenuAnchor: {},
        subMenuID: false,
        userAnchorEl: {},
        openUserMenu: false
    };

    handleLogOut = (event) => {
        const { changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                this.props.dispatch(logout());
            }
        } else {
            this.props.dispatch(logout());
        }
    }

    handleLinkClick(url,event) {
        const { history, changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                history.push(url);
            }
        } else {
            history.push(url);
        }
    }

    handleSubMenu = (link_id,event) => {
        const { openSubMenu } = this.state;
        event.preventDefault();
        this.setState({ openSubMenu: !openSubMenu, subMenuAnchor: event.currentTarget, subMenuID: link_id });
    }

    handleUserMenu = (event) => {
        const { openUserMenu } = this.state;
        this.setState({ openUserMenu: !openUserMenu, userAnchorEl: event.currentTarget });
    }

    getLinks = () => {
        const { authData } = this.props;
        var links = [{ id: 'dashboard', label: 'Dashboard', url: '/dashboard' }];

        if ( hasAccessRights( authData, ['ctr'] ) )
            links.push({ id: 'contacts', label: 'Contacts', url: '/contacts' });

        if ( hasAccessRights( authData, ['lsr'] ) )
            links.push({ id: 'lists', label: 'Lists', url: '/lists' });

        if ( hasAccessRights( authData, ['emb'] ) )
            links.push({ id: 'broadcast', label: 'Email Broadcast', url: '/broadcast' });

        if ( hasAccessRights( authData, ['mtr'] ) ) {
            links.push({ id: 'email_templates', label: 'Email Templates', url: '/maintenance/email_templates' });
        } // end - hasAccessRights

        return links;
    }

    renderSubMenu() {
        const { openSubMenu, subMenuAnchor, subMenuID } = this.state;
        var parent = _find( this.getLinks(), { id: subMenuID });
        return <PopperBox 
                open={openSubMenu}
                placement="bottom"
                anchorEl={subMenuAnchor}
                onClose={() => this.setState({ openSubMenu: false, subMenuAnchor: {}, subMenuID: false })}
                wrapperStyle={{ paddingTop: "10px" }}
                containerStyle={{ width: "250px" }}
                contents={(
                    <div style={{ padding: "10px" }}>
                    { parent && parent.submenu && isArrayExists( parent.submenu ) ? parent.submenu.map(link => {
                       return <InverseLink key={link.id} style={{ display: 'block', padding: "15px" }} onClick={this.handleLinkClick.bind(this,link.url)}>{ link.icon ? <i className={link.icon}></i> : '' }{link.label}</InverseLink>
                    }) : '' }
                    </div>
                )} />
    }

    renderUserSubMenu() {
        const { authData } = this.props;
        const linkStyle = { marginBottom: "10px" };
        var hasMTR = hasAccessRights( authData, ['mtr'] );
        return (
        <UserSubMenuWrapper>
            <Grid container spacing={3}>
                { hasMTR ? <Grid item xs={12} sm={6}>
                    <div style={{ padding: "0 10px" }}>
                        <Typography variant="h5" style={{ marginBottom: "15px", padding: "0px 5px" }}>Maintenance</Typography>
                        { isAdmin(authData) ? <GreyLink onClick={this.handleLinkClick.bind(this,'/users')} style={linkStyle}><i className="fa fa-users"></i>Users</GreyLink> : null }
                        { isSuperAdmin(authData) ? <GreyLink onClick={this.handleLinkClick.bind(this,'/roles')} style={linkStyle}><i className="fa fa-address-card-o"></i>Roles & Privileges</GreyLink> : null }
                        { isSuperAdmin(authData) ? <GreyLink onClick={this.handleLinkClick.bind(this,'/maintenance/organisations')} style={linkStyle}><i className="fa fa-building-o"></i>Organisations</GreyLink> : null }
                        <GreyLink onClick={this.handleLinkClick.bind(this,'/maintenance/emails')} style={linkStyle}><i className="fa fa-link"></i>Emails</GreyLink>
                    </div>
                </Grid> : null }
                <Grid item xs={12} sm={6}>
                    <div style={ hasMTR ? { padding: "0 10px", borderLeft: "1px solid #ddd" } : { padding: "0 10px" }}>
                        <Typography variant="h5" style={{ marginBottom: "15px", padding: "0px 5px" }}>Account Settings</Typography>
                        <GreyLink onClick={this.handleLinkClick.bind(this,'/auth/profile')} style={linkStyle}><i className="fa fa-user"></i>Edit Profile</GreyLink>

                        { authData && isAdmin(authData) ? <Typography variant="h5" style={{ marginTop: '30px', marginBottom: "15px", padding: "0px 5px" }}>Tools</Typography> : null }
                        { authData && isSuperAdmin(authData) ? <div><GreyLink onClick={this.handleLinkClick.bind(this,'/tools/import')} style={linkStyle}><i className="fa fa-cloud-upload"></i>Import</GreyLink></div> : null }
                        { authData && isAdmin(authData) ? <div><GreyLink onClick={this.handleLinkClick.bind(this,'/tools/export')} style={linkStyle}><i className="fa fa-cloud-download"></i>Export</GreyLink></div> : null }
                        
                    </div>
                </Grid>
            </Grid>
        </UserSubMenuWrapper>
        )
    }

    renderUserDetails() {
        const { authData } = this.props;
        return (
        <UserDetails>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <FlexColumn width="70%">
                    <Box display="flex">
                        { authData.photoURL && !_isEmpty( authData.photoURL ) ? <img src={authData.photoURL} style={{ width: "45px", height: "45px", borderRadius: "100%" }} /> : <i className="fa fa-user-circle" style={{ fontSize: '45px' }}></i> }
                        <div style={{ paddingLeft: "20px" }}>
                            <Typography variant="h5" style={{ fontWeight: "700", marginBottom: "4px" }}>{authData.name || ''}</Typography>
                            <Typography variant="h6" color="textSecondary" style={{ marginBottom: "4px" }}>{authData.email || ''}</Typography>
                            <Typography variant="h6" color="primary">{( authData.role ? _startCase( authData.role ) : '' )}</Typography>
                        </div>
                    </Box>
                </FlexColumn>
                <FlexColumn width="30%">
                    <ErrorLink onClick={this.handleLogOut}><i className="fa fa-sign-out"></i>Logout</ErrorLink>
                </FlexColumn>
            </Box>
        </UserDetails>
        )
    }

    renderUserMenu = () => {
        const { openUserMenu, userAnchorEl } = this.state;
        return <PopperBox 
                open={openUserMenu}
                anchorEl={userAnchorEl}
                wrapperStyle={{ padding: "15px" }}
                onClose={() => this.setState({ openUserMenu: false, userAnchorEl: {} })}
                contents={(
                    <div>
                        {this.renderUserDetails()}
                        {this.renderUserSubMenu()}
                    </div>
                )} />
    }

    render() {
        const { openUserMenu, subMenuID } = this.state;
        return (
        <HeaderWrapper>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <FlexColumn width="30%" style={{ paddingRight: "30px" }}>
                    <img src="/logo.png" />
                </FlexColumn>
                <FlexColumn width="70%">
                    <Nav>
                        <ul>
                        {this.getLinks().map( link => {
                            if ( link.submenu && isArrayExists( link.submenu ) )
                                return (
                                <li key={link.id}>
                                    <a href="#" className={( subMenuID && subMenuID === link.id ? 'active' : null )} onClick={this.handleSubMenu.bind(this,link.id)}><span>{link.label}</span><i className="fa fa-angle-down" style={{ marginLeft: "8px" }}></i></a>
                                </li>
                                );
                            else
                                return <li key={link.id}><a href={link.url} onClick={this.handleLinkClick.bind(this,link.url)}><span>{link.label}</span></a></li>;
                        })}
                            <li>
                                <ToggleButton onClick={this.handleUserMenu} className={( openUserMenu ? 'opened' : 'closed' )}>
                                    <i className="fa fa-user-circle"></i>
                                    { openUserMenu ? <i className="fa fa-remove"></i> : <i className="fa fa-ellipsis-v"></i> }
                                </ToggleButton>
                            </li>
                        </ul>
                    </Nav>
                    {this.renderSubMenu()}
                    {this.renderUserMenu()}
                </FlexColumn>
            </Box>
        </HeaderWrapper>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth.user || null,
        changesNotSaved: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(Header);