import React from 'react';
import styled from "styled-components";


export const FormRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 20px 10px;
`;

export const FormColumn = styled.div`
    display: block;
    width: ${props => (props.width ? props.width : '100%')};
    ${props => {
        if ( props.col && props.col == '2' ) {
            return `
            &:nth-child(1) { padding-right: 10px; }
            &:nth-child(2) { padding-left: 10px; }
            `;
        } else if ( props.col && props.col == '3' ) {
            return `
            &:nth-child(1) { padding-right: 10px; }
            &:nth-child(2) { padding-left: 10px; padding-right: 10px; }
            &:nth-child(3) { padding-left: 10px; }
            `;
        }
    }}
`;