import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';

import AppWrapper from '../../components/AppWrapper';
import Contacts from './contacts';

import { cloneCollections } from '../../helpers/data';
import { isArrayExists } from '../../helpers/validation';
import { isSuperAdmin } from '../../helpers/auth';

import { getContacts, getContactsLive } from '../../actions/contacts';
import { getLists } from '../../actions/lists';

class ContactsPage extends React.Component {

    state = {
        queryProps: {},
        rand: false
    };

    componentDidMount() {
        const { queryProps } = this.state;
        
        // get contacts list
        this.props.dispatch(getContactsLive(queryProps));

        // get mailing lists
        this.props.dispatch(getLists());

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { contactsList, mailingLists, randNum } = this.props;

        // once all the data is loaded
        if ( contactsList && mailingLists && randNum && randNum != this.state.rand )
            this.setState({ rand: randNum });
        
    }

    getLists = () => {
        const { mailingLists, authData } = this.props;
        var lists = [];
        if ( isSuperAdmin( authData ) ) {
            lists = ( mailingLists && isArrayExists( mailingLists ) ? cloneCollections( mailingLists ) : [] );
        } else {
            lists = ( mailingLists && isArrayExists( mailingLists ) ? _filter( mailingLists, (o) => o.org && !_isEmpty( o.org ) && authData.orgs && !_isEmpty( authData.orgs ) && authData.orgs.indexOf(o.org) >= 0 ) : [] );
        } // end - isSuperAdmin
        return lists;
    }

    renderContents() {
        const { contactsList, authData } = this.props;
        return (
        <Contacts 
            authData={authData}
            contacts={contactsList} 
            lists={this.getLists()} />
        );
    }

    render() {
        const { contactsList, mailingLists, randNum } = this.props;
        return <AppWrapper 
                title="Contacts Management"
                onLoad={( !( contactsList && mailingLists && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        contactsList: state.contacts && state.contacts.contacts || null,
        randNum: state.contacts && state.contacts.rand || null,
        mailingLists: state.lists && state.lists.lists || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ContactsPage);