import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import Lists from './lists';

import { cloneCollections } from '../../helpers/data';

import { getLists } from '../../actions/lists';
import { getOrgs } from '../../actions/m_orgs';

class ListsPage extends React.Component {

    state = {
        rand: false
    };

    componentDidMount() {
        const { orgsList } = this.props;

        // get lists
        this.props.dispatch(getLists());

        // get orgs
        if ( !orgsList ) {
            this.props.dispatch(getOrgs());
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { mailingLists, orgsList, randNum } = this.props;

        // once all the data is loaded
        if ( mailingLists && randNum && randNum != this.state.rand )
            this.setState({ rand: randNum });
    }

    handleRefresh = () => {
        // get lists
        this.props.dispatch(getLists());
        this.setState({ rand: false });
    }

    renderContents() {
        const { mailingLists, orgsList, authData } = this.props;
        return (
        <Lists 
            lists={mailingLists}
            orgs={orgsList} 
            authData={authData}
            onRefresh={this.handleRefresh} />
        );
    }

    render() {
        const { mailingLists } = this.props;
        const { rand } = this.state;
        return <AppWrapper 
                title="Lists Management"
                onLoad={( !( mailingLists && rand ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        mailingLists: state.lists && state.lists.lists || null,
        randNum: state.lists && state.lists.rand || null,
        orgsList: state.maintenance && state.maintenance.orgs || false,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ListsPage);