import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormSelect from '../../components/FormSelect';
import FormInput from '../../components/FormInput';

import { InfoButton, ErrorButton, ButtonGroup, AInfoLink, InverseButton, AIndigoLink } from '../../styles/button';
import { AmberTag, SuccessTag, InfoTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';
import { getMomentTime } from '../../helpers/date';
import { isSuperAdmin, getUserFirstOrg } from '../../helpers/auth';

import { deleteBroadcast } from '../../actions/email_broadcast';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});


class Broadcasts extends React.Component {
    
    state = {
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openDeleteModal: false,
        deleteModal: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh();
    }

    handleGoTo = (url,event) => {
        event.preventDefault();
        this.props.history.push(url);
    }

    handleDelete = () => {
        const { authData } = this.props;
        const { deleteModal } = this.state;
        // check whather to use super admin delete
        let useSuperAdminDelete = ( isSuperAdmin( authData ) && deleteModal && deleteModal.status && deleteModal.status === 'broadcasted' ? true : false );
        // perform delete
        this.props.dispatch(deleteBroadcast(deleteModal.id,useSuperAdminDelete));
    }

    getTotalRecipentsCount = (item) => {
        var count = 0;
        if ( item && item.lists && isArrayExists( item.lists ) ) {
            _forEach( item.lists, list => {
                count += list.members_count;
            });
        } // end - item.lists
        return count;
    }

    getSelectableOrgs = (firstOption) => {
        const { orgs, authData } = this.props;
        var options = ( firstOption ? [ firstOption ] : [{ value: '', label: 'Select an Option' }] );
        if ( isSuperAdmin( authData ) ) {
            options = getSelectOptions({ list: orgs, options, keys: { value: 'id', label: 'label' }, sortBy: 'label' });
        } else {
            options = getSelectOptions({ list: _filter( orgs, (o) => authData.orgs && !_isEmpty( authData.orgs ) && authData.orgs.indexOf(o.id) >= 0 ), options, keys: { value: 'id', label: 'label' }, sortBy: 'label' });
        } // end - isSuperAdmin
        return options;
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { broadcasts, authData } = this.props;
        var items = ( broadcasts ? cloneCollections( broadcasts ) : [] ),
            total = _size( items );
            
        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name'] );
			total = _size( items );
        } // end - searchterms

        // if is user / admin, then prefilter the org based on their org
        if ( !isSuperAdmin( authData ) ) {
            items = _filter( items, (i) => ( i.orgs && isArrayExists( i.orgs ) && _find( i.orgs, (o) => ( o.id && authData.orgs && !_isEmpty( authData.orgs ) && authData.orgs.indexOf(o.id) >= 0 ? true : false )) ? true : false ) );
            total = _size( items );
        } // end - isSuperAdmin

        // do filter
        if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
            items = _filter( items, (i) => ( i.orgs && isArrayExists( i.orgs ) && _find( i.orgs, { id: filterBy }) ? true : false ) );
            total = _size( items );
        }

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['created_on'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderTableActions = () => {
        const { authData } = this.props;
        const { sortBy, filterBy, perPage, searchterms } = this.state;
        return <TableBar
                filterBy={filterBy}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Entries first' },
                    { value: 'date-asc', label: 'Oldest Entries first' },
                    { value: 'name-asc', label: 'Message Label ( A - Z)' },
                    { value: 'name-desc', label: 'Message Label ( Z - A )' }
                ]}
                filterByOptions={this.getSelectableOrgs({ value: 'all', label: 'All' })}
                rightButtons={[
                    <InfoButton minWidth="228px" key="addnew" style={{ marginRight: "5px" }} onClick={this.handleGoTo.bind(this,'/broadcast/new')}><i className="fa fa-plus-circle"></i>Create New Broadcast</InfoButton>,
                    <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
                ]}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes, orgs, authData } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                var selected_org = ( orgs && isArrayExists( orgs ) ? _find( orgs, { id: item.org } ) : false );
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell}>{( item.status && item.status === 'broadcasted' ? <SuccessTag>{item.status}</SuccessTag> : ( item.status && item.status === 'scheduled' ? <InfoTag>{item.status}</InfoTag> : <AmberTag>{item.status}</AmberTag> ) )}</TableCell>
                    <TableCell className={classes.bodycell}>{item.label || ''}</TableCell>
                    <TableCell className={classes.bodycell}>{this.getTotalRecipentsCount(item)}</TableCell>
                    <TableCell className={classes.bodycell}>{ item.delivery_time && !_isEmpty( item.delivery_time ) ? getMomentTime( item.delivery_time, 'YYYY-MM-DD hh:mma' ) : '-'}</TableCell>
                    <TableCell className={classes.bodycell}>{ item.created_on ? getMomentTime( item.created_on, 'YYYY-MM-DD' ) : '-'}</TableCell>
                    <TableCell className={classes.bodycell}>
                        <ButtonGroup>
                            { item.status && item.status === 'broadcasted' ? null : <AInfoLink href={"/broadcast/b/"+item.id} size="small" onClick={this.handleGoTo.bind(this,'/broadcast/b/'+item.id)}><i className="fa fa-edit"></i>Edit</AInfoLink> }
                            { item.status && item.status === 'broadcasted' ? <AIndigoLink href={"/reports/broadcast/?broadcast_id="+item.id} size="small" onClick={this.handleGoTo.bind(this,'/reports/broadcast?broadcast_id='+item.id)}><i className="fa fa-file-o"></i>Report</AIndigoLink> : null }
                            { item.status && item.status === 'broadcasted' ? <AInfoLink href={"/broadcast/new?reuse_id="+item.id} size="small" onClick={this.handleGoTo.bind(this,'/broadcast/new?reuse_id='+item.id)}><i className="fa fa-refresh"></i>Re-use</AInfoLink> : null }
                            { item.status && item.status === 'broadcasted' && !isSuperAdmin( authData ) ? null : <ErrorButton size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> }
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No broadcast(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell}>Status</TableCell>
                <TableCell className={classes.headcell}>Message Label</TableCell>
                <TableCell className={classes.headcell}>Total Recipients</TableCell>
                <TableCell className={classes.headcell}>Delivery Time</TableCell>
                <TableCell className={classes.headcell}>Last Updated</TableCell>
                <TableCell className={classes.headcell} style={{ width: "20%" }}>Actions</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { openDeleteModal, deleteModal } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.label ? `Are you sure you want to delete this broadcast ( ${deleteModal.label} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(Broadcasts);