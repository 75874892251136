import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
    GET_EMAIL_BROADCASTS,
    GET_EMAIL_BROADCAST,
    CREATE_EMAIL_BROADCAST,
    UPDATE_EMAIL_BROADCAST,
    DELETE_EMAIL_BROADCAST,
	GLOBAL_ERROR_TRIGGER
} from './types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from './global';
import { triggerErrorAlert, triggerSuccessAlert } from '../helpers/alert';
import { appChangesReset } from './misc';

import { fb_getBroadcasts, fb_getBroadcast, fb_createBroadcast, fb_updateBroadcast, fb_deleteBroadcast } from '../firebase/email_broadcasts';

export const getBroadcasts = () => {
	return dispatch => {

        fb_getBroadcasts(list => {
            dispatch({
                type: GET_EMAIL_BROADCASTS,
                payload: { list }
            });
        });

	}
}

export const getBroadcast = (id) => {
	return dispatch => {

        fb_getBroadcast(id)  
		.then(single => {
			dispatch({
				type: GET_EMAIL_BROADCAST,
				payload: { single }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve broadcast data' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_EMAIL_BROADCAST,
				payload: { single: {} }
			});
		});

	}
}

export const createBroadcast = (formData,history) => {
	return dispatch => {
        dispatch(toggleLoader(true));

		fb_createBroadcast(formData)
		.then( results => {
			if ( results && results.new_broadcast_id && !_isEmpty( results.new_broadcast_id ) ) {
				// updated
				dispatch({ type: CREATE_EMAIL_BROADCAST });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Broadcast Created");

				// redirect to broadcast page
				history.push("/broadcast");

			} else {
				dispatch(toggleLoader(false));
				triggerErrorAlert('Missing new broadcast ID');
			}
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to create new broadcast' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const updateBroadcast = (formData,broadcast_id,history) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateBroadcast(formData,broadcast_id)
		.then( results => {
			// updated
			dispatch({ type: UPDATE_EMAIL_BROADCAST });
			dispatch(toggleLoader(false));
			dispatch(appChangesReset());
			triggerSuccessAlert("Broadcast Updated");

			// redirect to broadcast page
			history.push("/broadcast");
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update broadcast' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteBroadcast = (id,useSuperAdminDelete) => {
	return dispatch => {
        dispatch(toggleModalDeleting(true));

		fb_deleteBroadcast(id,useSuperAdminDelete)
		.then( results => {

			// trigger updated
			dispatch({ type: DELETE_EMAIL_BROADCAST });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Broadcast Deleted");

		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete broadcast' ) );
			triggerErrorAlert(errMsg);
		});

	}
}