import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import Emails from './emails';

import { getReplytoEmails } from '../../actions/m_replyto_emails';
import { getOrgs } from '../../actions/m_orgs';

class MaintenanceReplyToEmails extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        const { orgsList } = this.props;

        // get reply-to emails
        this.props.dispatch(getReplytoEmails());

        // get orgs
        if ( !orgsList ) {
            this.props.dispatch(getOrgs());
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { replyToEmailsList, orgsList, randNum } = this.props;

        if ( replyToEmailsList && orgsList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    renderContents() {
        const { replyToEmailsList, orgsList, authData } = this.props;
        return <Emails 
                    emails={replyToEmailsList}
                    orgs={orgsList} 
                    authData={authData} />;
    }

    render() {
        const { replyToEmailsList } = this.props;
        const { randNum } = this.state;
        return <AppWrapper 
                title="Emails"
                subtitle="Maintenance"
                onLoad={( !( replyToEmailsList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        replyToEmailsList: state.maintenance && state.maintenance.replyto_emails || null,
        randNum: state.maintenance && state.maintenance.rand || null,
        orgsList: state.maintenance && state.maintenance.orgs || false,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceReplyToEmails);