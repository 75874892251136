import * as firebase from 'firebase/app';

import { getIDToken } from './auth';
import { doPromise } from '../helpers/action';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getEmailTemplates = (callback) => {
    return new Promise((resolve,reject) => {

        firebase.database().ref("maintenance/email_templates").on("value",snapshot => {

            var list = [];

            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    var val = childSnapshot.val(),
                        item = {
                            id: childSnapshot.key,
                            label: val.label || '',
                            orgs: [],
                            created_on: val.created_on || 0,
                        };

                    if ( childSnapshot.child("orgs").exists() && childSnapshot.child("orgs").hasChildren() ) {
                        childSnapshot.child("orgs").forEach( orgSnapshot => {
                            item.orgs.push({ id: orgSnapshot.key });
                        });
                    } // end - childSnapshot.child("orgs")

                    list.push(item);
                });
            } // end - snapshot

            callback(list);
        });

    })
}

export const fb_getEmailTemplate = (id) => {
    return new Promise((resolve,reject) => {

        var promises = [];
        promises.push( firebase.database().ref("maintenance/email_templates/"+id).once("value") );
        promises.push( firebase.database().ref("maintenance/email_templates_contents/"+id).once("value") );
        
        Promise.all(promises)
        .then( results => {

            var item = {};

            if ( results && results[0].exists() ) {
                var val = results[0].val();
                item = {
                    id: results[0].key,
                    label: val.label || '',
                    orgs: [],
                    contents_html: '',
                    contents_css: '',
                    contents_js: '',
                    created_on: val.created_on || 0,
                };

                // get orgs
                if ( results[0].child("orgs").exists() && results[0].child("orgs").hasChildren() ) {
                    results[0].child("orgs").forEach( orgSnapshot => {
                        item.orgs.push({ id: orgSnapshot.key });
                    });
                } // end - results[0].child("orgs")

                // get template contents
                if ( results && results[1].exists() ) {
                    var contents = results[1].val();
                    item['contents_html'] = contents.html || '';
                    item['contents_css'] = contents.css || '';
                    item['contents_js'] = contents.js || '';
                } // end - results[0]

            } // end - results[0]

            resolve(item);
        })
        .catch( error => {
            reject(error);
        });

    })
}

export const fb_getTemplate = (id) => {
    return new Promise((resolve,reject) => {

        firebase.database().ref("maintenance/email_templates_contents/"+id).once("value")
        .then( snapshot => {
            var template = {};

            if ( snapshot.exists() ) {
                var val = snapshot.val();
                template['html'] = val.html || '';
                template['css'] = val.css || '';
                template['js'] = val.js || '';
            } // end - snapshot

            resolve(template);
        })
        .catch( error => {
            reject(error);
        });

    })
}

export const fb_addEmailTemplate = (formData) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'emailTemplates',
                params: [{ key: 'action', value: 'add' }],
                formData
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_updateEmailTemplate = (formData) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'emailTemplates',
                params: [{ key: 'action', value: 'update' }],
                formData
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteEmailTemplate = (id) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'emailTemplates',
                params: [{ key: 'action', value: 'delete' }],
                formData: { id }
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}