import React from 'react';
import styled from "styled-components";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _remove from 'lodash/remove';

import Controller from './controller';

import FormInput from '../../components/FormInput';
import FormCheckbox from '../../components/FormCheckbox';

import { triggerErrorAlert } from '../../helpers/alert';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';

class StepLists extends React.Component {

    handleFormUpdate = (newValue,key,status) => {
        const { formData } = this.props;
        var newData = ( formData && !_isEmpty(formData) ? cloneCollections(formData) : {} );

        if ( key == 'lists' ) {
            var newLists = ( formData.lists && isArrayExists( formData.lists ) ? cloneCollections(formData.lists) : [] );
            if ( status == 'checked' ) {
                if ( !_find( newLists, { id: newValue } ) )
                    newLists.push({ id: newValue });
            } else {
                var pulled = _remove( newLists, { id: newValue } );
            } // end - status
            newData[key] = newLists;
        } else {
            newData[key] = newValue;
        } // end - key

        // push updates to parent
        if ( this.props.onFormUpdate ) {
            this.props.onFormUpdate(newData);
        }
    }

    handleStepChange = (direction) => {
        const { formData } = this.props;
        
        if ( direction === 'back' ) {
            this.props.goBack();
        } else if ( direction === 'next' ) {
            // do error check before proceed
            var error = false;

            if ( !( formData.lists && !_isEmpty( formData.lists ) ) ) {
                error = 'Please select at least one list';
            } // end - formData

            if ( error ) {
                triggerErrorAlert(error);
            } else {
                this.props.goNext();
            } // end - error

        }
    }

    render() {
        const { authData, lists } = this.props;
        const { formData, step } = this.props;
        return (
        <div style={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto" }}>
            
            <Paper elevation={2} style={{ padding: "30px", background: "#fff" }}>

                <Typography variant="h4" style={{ textAlign: "center", marginBottom: "30px" }}>Select one (or more) List(s):</Typography>
                
                <Grid container spacing={2} style={{ padding: "10px" }}>
                    { lists && isArrayExists( lists ) ? _map( lists, list => {
                        return (
                        <Grid key={list.id} item xs={4}>
                            <FormCheckbox label={list.name+' ('+( list.members_count ? list.members_count : '0' )+')'} name="lists" 
                                value={list.id} 
                                checked={( formData.lists && isArrayExists( formData.lists ) && _find( formData.lists, { id: list.id } ) ? list.id : false )} 
                                onChange={this.handleFormUpdate} />
                        </Grid>
                        );
                    }) : <p style={{ textAlign: 'center' }}>No list found.</p>}
                </Grid>

            </Paper>

            <Controller step={step} onClick={this.handleStepChange} />

        </div>
        );
    }

}

export default StepLists;