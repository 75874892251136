import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_REPORT_EMAIL_BROADCAST,
	RESET_REPORT_EMAIL_BROADCAST,
	GET_REPORT_RECENT_EMAIL_BROADCAST,
	GLOBAL_ERROR_TRIGGER
} from './types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from './global';
import { triggerErrorAlert, triggerSuccessAlert } from '../helpers/alert';
import { isArrayExists } from '../helpers/validation';
import { appChangesReset } from './misc';

import { fb_getBroadcastReport, fb_getBroadcastEvents, fb_getRecentBroadcastReport } from '../firebase/reports';
import { fb_deleteBroadcast } from '../firebase/email_broadcasts';

export const getEmailBroadCastReport = (formData) => {
	return dispatch => {

        fb_getBroadcastReport(formData)
		.then(report => {

			fb_getBroadcastEvents(formData)
			.then( events => {
				report.events = ( events && isArrayExists( events ) ? events : [] );
				dispatch({
					type: GET_REPORT_EMAIL_BROADCAST,
					payload: { report }
				});
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve report data' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_REPORT_EMAIL_BROADCAST,
				payload: { report: null }
			});
		});

	}
}

export const getRecentEmailBroadCastReport = (email) => {
	return dispatch => {

		fb_getRecentBroadcastReport(email)
		.then(report => {
			dispatch({
				type: GET_REPORT_RECENT_EMAIL_BROADCAST,
				payload: { report }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve report data' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_REPORT_RECENT_EMAIL_BROADCAST,
				payload: { report: null }
			});
		});

	}
}

export const deleteBroadcastInReport = (id,useSuperAdminDelete,formData) => {
	return dispatch => {
        dispatch(toggleModalDeleting(true));

		fb_deleteBroadcast(id,useSuperAdminDelete)
		.then( results => {

			fb_getBroadcastReport(formData)
			.then(report => {

				fb_getBroadcastEvents(formData)
				.then( events => {
					report.events = ( events && isArrayExists( events ) ? events : [] );

					// trigger updated
					dispatch(toggleModalDeleting(false));
					triggerSuccessAlert("Broadcast Deleted");

					dispatch({
						type: GET_REPORT_EMAIL_BROADCAST,
						payload: { report }
					});
				});
			})
			.catch(error => {
				var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve report data' ) );
				triggerErrorAlert(errMsg);
				dispatch({
					type: GET_REPORT_EMAIL_BROADCAST,
					payload: { report: null }
				});
			});

		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete broadcast' ) );
			triggerErrorAlert(errMsg);
		});

	}
}