import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { FullScreenFlex, FullScreenComponent } from '../../styles/division';
import { Button } from '../../styles/button';

import { signIn, logout } from '../../actions/auth';

class LoginPage extends React.Component {

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleLogin = event => {
        event.preventDefault();
        this.props.dispatch(signIn());
    }

    handleLogout = event => {
        event.preventDefault();
        this.props.dispatch(logout());
    }

    render() {
        const { authData } = this.props;
        return (
        <FullScreenComponent style={{ background: 'url("/background.png")', backgroundPosition: "center center", backgroundSize: "cover" }}>
            <FullScreenFlex justify="center" items="center" style={{ background: "rgba(#f1f1f1,0.75)" }}>
                <div style={{ textAlign: 'center', padding: "60px 90px", background: "#f1f1f1", borderRadius: "12px", boxShadow: "0 0 15px 0 rgba(0,0,0,0.25)" }}>
                    <div style={{ marginBottom: "30px" }}><img src="/logo.png" /></div>
                    <h1 style={{ fontWeight: "300", textTransform: "uppercase", marginBottom: "30px" }}>Mass Mailing System</h1>
                    { authData ? <Button style={{ textTransform: 'uppercase', padding: "10px 45px" }} onClick={this.handleLogout}><i className="fa fa-power-off"></i>Logout</Button> : <Button style={{ textTransform: 'uppercase', padding: "10px 45px" }} onClick={this.handleLogin}><i className="fa fa-google"></i>Login with Google Account</Button> }
                </div>
            </FullScreenFlex>
        </FullScreenComponent>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(LoginPage);