import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _find from 'lodash/find';
import _upperFirst from 'lodash/upperFirst';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormSelect from '../../components/FormSelect';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormInput from '../../components/FormInput';

import { InfoButton, ErrorButton, ButtonGroup, InverseButton } from '../../styles/button';
import { WrapWord } from '../../styles/misc';
import { SuccessTag, AmberTag, GreyTag } from '../../styles/tag';

import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';
import { isSuperAdmin, getUserOrgs, getUserFirstOrg } from '../../helpers/auth';

import { addUser, editUser, deleteUser } from '../../actions/users';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});


class Users extends React.Component {
    
    state = {
        searchterms: '',
        filterBy: 'all',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalType: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false
    }

    handlePageRefresh = (event) => {
        event.preventDefault();
        if ( this.props.onRefresh )
            this.props.onRefresh();
    }

    handleFormUpdate = (newValue,key) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    handleAddNew = () => {
        const { modalData } = this.state;
        var error = false,
            formData = ( modalData && !_isEmpty( modalData ) ? cloneCollections( modalData ) : {} );

        if ( !( modalData && modalData.orgs_search && !_isEmpty( modalData.orgs_search ) ) ) {
            error = 'Please select at least organisation';
        } // end - modalData.role

        if ( !( modalData && modalData.role && !_isEmpty( modalData.role ) ) ) {
            error = 'Please select a role';
        } // end - modalData.role

        if ( !( modalData && modalData.email && validateEmail( modalData.email ) ) ) {
            error = 'Please insert a valid email address';
        } // end - modalData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {

            // re-organize data before submit
            formData['orgs'] = [];
            if ( modalData && modalData.orgs_search && isArrayExists( modalData.orgs_search ) ) {
                modalData.orgs_search.forEach(org => {
                    formData['orgs'].push({ id: org });
                });
            } // end - newValue

            this.props.dispatch(addUser(formData,this.getSelectedOrg()));
        } // end - error
    }

    handleEdit = () => {
        const { modalData } = this.state;
        var error = false,
            formData = ( modalData && !_isEmpty( modalData ) ? cloneCollections( modalData ) : {} );

        if ( !( modalData && modalData.orgs_search && !_isEmpty( modalData.orgs_search ) ) ) {
            error = 'Please select at least one organisation';
        } // end - modalData.role

        if ( !( modalData && modalData.role && !_isEmpty( modalData.role ) ) ) {
            error = 'Please select a role';
        } // end - modalData.role

        if ( !( modalData && modalData.status && !_isEmpty( modalData.status ) ) ) {
            error = 'Please select a status';
        } // end - modalData.role

        if ( !( modalData && modalData.email && validateEmail( modalData.email ) ) ) {
            error = 'Please insert a valid email address';
        } // end - modalData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {

            // re-organize data before submit
            formData['orgs'] = [];
            if ( modalData && modalData.orgs_search && isArrayExists( modalData.orgs_search ) ) {
                modalData.orgs_search.forEach(org => {
                    formData['orgs'].push({ id: org });
                });
            } // end - newValue

            this.props.dispatch(editUser(formData,this.getSelectedOrg()));
        } // end - error
    }

    handleAction = () => {
        const { modalType } = this.state;
        if ( modalType && modalType == 'new' ) {
            this.handleAddNew();
        } else if ( modalType && modalType == 'edit' ) {
            this.handleEdit();
        } // end - modalType
    }

    handleDelete = () => {
        const { deleteModal } = this.state;
        //perform delete
        this.props.dispatch(deleteUser(deleteModal.uid,this.getSelectedOrg()));
    }

    getSelectedOrg = () => {
        const { authData } = this.props;
        return getUserOrgs(authData);
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { users, authData } = this.props;
        var items = ( users ? cloneCollections( users ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email','role'] );
			total = _size( items );
        } // end - searchterms

        // do filter
        if ( isSuperAdmin( authData ) ) {
            // only super admin can do filter for all the orgs
            if ( filterBy && !_isEmpty( filterBy ) && filterBy != 'all' ) {
                items = _filter( items, (o) => ( o.orgs && isArrayExists( o.orgs ) && _find( o.orgs, { id: filterBy }) ? true : false ) );
                total = _size( items );
            }
        } else {
            // if is admin, then prefilter the org based on their org
            items = _filter( items, (o) => ( o.orgs && isArrayExists( o.orgs ) && o.type && o.type !== 'superadmin' && authData.orgs && !_isEmpty( authData.orgs ) && _find( o.orgs, (p) => authData.orgs.indexOf(p.id) >= 0 ) ? true : false ) );
            total = _size( items );
        } // end - isSuperAdmin


        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['created_on'] );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderViewForm = () => {
        const { orgs, roles, authData } = this.props;
        const { modalData, modalType } = this.state;
        var isSuperAdmin = false;
        if ( authData && authData.type ) {
            switch( authData.type ) {
                case 'superadmin': 
                    var roleOptions = [{ value: '', label: 'Select an Option' },{ value: 'superadmin', label: 'Super Admin' },{ value: 'admin', label: 'Admin' }],
                        orgOptions = getSelectOptions({ list: orgs, keys: { value: 'id', label: 'label' }, sortBy: 'label' });
                        isSuperAdmin = true;
                    break;
                case 'admin': 
                    var roleOptions = [{ value: '', label: 'Select an Option' },{ value: 'admin', label: 'Admin' }],
                        userOrgs = getUserOrgs( authData ),
                        orgOptions = [];
                    if ( userOrgs && isArrayExists( userOrgs ) ) {
                        userOrgs.forEach( userOrg => {
                            var selected_org = _find( orgs, { id: userOrg.id } );
                            if ( selected_org && selected_org.id )
                                orgOptions.push({ value: selected_org.id, label: selected_org.label || '' });
                        });
                        orgOptions = _sortBy( orgOptions, ['label'] )
                    } // end - userOrgs
                    break;
                default: 
                    var roleOptions = [{ value: '', label: 'Select an Option' }],
                        orgOptions = [{ value: '', label: 'Select an Option' }];
                    break;
            }
        }
        return (
        <div>
            { modalType && modalType == 'edit' ? <div style={{ marginTop: '8px' }}><FormSelect 
                label="Status (Required)" 
                name="status" 
                value={( modalData.status || '' )} 
                options={[
                    {value:'active',label: 'Active'},
                    {value:'pending',label: 'Pending'},
                    {value:'disabled',label: 'Disabled'},
                ]}
                onChange={this.handleFormUpdate} /></div> : null }
            <FormInput label="Name" name="name" value={( modalData.name || '' )} onChange={this.handleFormUpdate} />
            <FormInput label="Email (Required)" type="email" name="email" value={( modalData.email || '' )} disabled={( modalType && modalType == 'edit' ? true : false )} onChange={this.handleFormUpdate} />
            <div style={{ paddingTop: '10px' }}><FormSelect 
                label="Role (Required)" 
                name="role" 
                value={( modalData.role || '' )} 
                options={getSelectOptions({ list: roles, options: roleOptions, keys: { value: 'id', label: 'label' }, sortBy: 'label' })}
                onChange={this.handleFormUpdate} /></div>
            <div style={{ paddingTop: '15px' }}><FormMultiSelect 
                label="organisation (Required)" 
                name="orgs_search" 
                value={( modalData.orgs_search || [] )} 
                options={orgOptions}
                onChange={this.handleFormUpdate} /></div>
        </div>
        );
    }

    renderTableActions = () => {
        const { orgs, roles, authData } = this.props;
        const { filterBy, sortBy, perPage, searchterms } = this.state;
        return <TableBar
                filterBy={filterBy}
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Entries first' },
                    { value: 'date-asc', label: 'Oldest Entries first' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' }
                ]}
                filterByOptions={( authData && authData.type && authData.type == 'superadmin' ? getSelectOptions({ list: orgs, options: [{ value: 'all', label: 'All' }], keys: { value: 'id', label: 'label' }, sortBy: 'label' }) : false )}
                rightButtons={[
                    <InfoButton minWidth="128px" key="addnew" style={{ marginRight: "5px" }} onClick={() => this.setState({ openViewModal: true, modalData: { name: '', email: '', orgs: [], orgs_search: ( isSuperAdmin( authData ) ? [] : [ getUserFirstOrg(authData) ] ), role: '' }, modalType: 'new' })}><i className="fa fa-plus-circle"></i>Add New</InfoButton>,
                    <InverseButton minWidth="128px" key="refresh" style={{ marginRight: "5px" }} onClick={this.handlePageRefresh}><i className="fa fa-refresh"></i>Refresh</InverseButton>
                ]}
                onFilterByChange={(newFilterBy) => this.setState({ filterBy: newFilterBy, page: 1 })}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes, orgs, roles, authData } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                var role = ( roles && item.role && !_isEmpty( item.role ) ? _find( roles, { id: item.role } ) : false );
                return (
                <TableRow key={item.uid}>
                    <TableCell className={classes.bodycell}>{ item.status && item.status == 'active' ? <SuccessTag>{item.status }</SuccessTag> : ( item.status && item.status == 'pending' ? <AmberTag>{item.status}</AmberTag> : <GreyTag>disabled</GreyTag> ) }</TableCell>
                    <TableCell className={classes.bodycell}><WrapWord>{item.name || ''}</WrapWord></TableCell>
                    <TableCell className={classes.bodycell}>
                        <WrapWord>{item.email || ''}</WrapWord>
                    </TableCell>
                    <TableCell className={classes.bodycell}>{( item.type && ( item.type === 'admin' || item.type === 'superadmin' ) ? ( item.type === 'superadmin' ? 'Super Admin' : 'Admin' ) : (  role && role.label && !_isEmpty( role.label ) ? role.label : ''  ) )}</TableCell>
                    <TableCell className={classes.bodycell}>{ item.orgs && isArrayExists( item.orgs ) ? item.orgs.map(org => {
                            var selected = _find( orgs, { id: org.id } );
                            return ( selected && selected.label ? <div key={org.id} style={{ fontSize: '0.875em', marginBottom: "4px" }}>{selected.label}</div> : null );
                        }) : '---' }</TableCell>
                    <TableCell className={classes.bodycell}>
                        <ButtonGroup>
                            <InfoButton size="small" onClick={() => this.setState({ openViewModal: true, modalData: item, modalType: 'edit' })}><i className="fa fa-edit"></i>Edit</InfoButton>
                            { item.uid && item.uid == authData.uid ? <ErrorButton size="small" disabled={( item.uid && item.uid == authData.uid ? 'yes' : 'no' )}><i className="fa fa-trash"></i>Delete</ErrorButton> : <ErrorButton size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: item })}><i className="fa fa-trash"></i>Delete</ErrorButton> }
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No List(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell}>Status</TableCell>
                <TableCell className={classes.headcell} style={{ width: "20%" }}>Name</TableCell>
                <TableCell className={classes.headcell} style={{ width: "20%" }}>Email</TableCell>
                <TableCell className={classes.headcell}>Role</TableCell>
                <TableCell className={classes.headcell}>Organisation</TableCell>
                <TableCell className={classes.headcell} style={{ width: "20%" }}>Actions</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { openDeleteModal, deleteModal, openViewModal, modalType } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModalView 
                open={openViewModal}
                title={ modalType && modalType == 'edit' ? "Edit User" : "Add New User" }
                actionLabel={ modalType && modalType == 'edit' ? "Update" : "Add New" }
                onClose={() => this.setState({ openViewModal: false, modalType: false, modalData: false })}
                doAction={this.handleAction}
                contents={this.renderViewForm()} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this user ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions()}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(Users);