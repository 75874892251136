import * as firebase from 'firebase/app';
import _isEmpty from 'lodash/isEmpty';
import _endsWith from 'lodash/endsWith';

import { getIDToken } from './auth';
import { doPromise } from '../helpers/action';

/* helpers start */
const sampleFunc = () => {
    return new Promise((resolve,reject) => {


    })
}


/* helper end */

export const fb_getBroadcastReport = (formData) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'report',
                params: [{ key: 'action', value: 'broadcast' }],
                formData
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_getBroadcastEvents = (formData) => {
    return new Promise((resolve,reject) => {

        var events = [];

        firebase.database().ref("email_broadcasts_events").orderByChild("broadcast_id").equalTo(formData.id).once("value")
        .then( snapshot => {

            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach( childSnapshot => {
                    var val = childSnapshot.val(),
                        event = {
                            id: childSnapshot.key,
                            recipient: val.recipient || '',
                            broadcast_id: val.broadcast_id || '',
                            list_id: val.list_id || '',
                            contact_id: val.contact_id || '',
                            event: val.event || '',
                            timestamp: val.timestamp || 0,
                            url: val.url || '',
                            reason: val.reason || '',
                            delivery_message: val.delivery_message || '',
                            delivery_description: val.delivery_description || ''
                        };
                        
                    // make sure there is no list email in the events
                    if ( event.recipient && !_isEmpty( event.recipient ) && !(
                        _endsWith( event.recipient, '@mailer.dreamcatcher.asia' ) || 
                        _endsWith( event.recipient, '@cwmailer.dreamcatcher.asia' ) || 
                        _endsWith( event.recipient, '@mailer.chumbaka.asia' )
                    ) ) {
                        // add event
                        events.push( event );
                    } // end - event.recipient

                });
            } // end - snapshot

            resolve(events);

        })
        .catch( error => {
            console.log('fb_getBroadcastEventsError',error);
            resolve(false);
        });

    })
}

export const fb_getRecentBroadcastReport = (email) => {
    return new Promise((resolve,reject) => {

        firebase.database().ref("email_broadcasts_report").orderByChild("created_by").equalTo(email).limitToFirst(1)
        .on("value",snapshot => {

            var formData = { id: false };

            if ( snapshot.exists() && snapshot.hasChildren() ) {
                snapshot.forEach(childSnapshot => {
                    formData['id'] = childSnapshot.key;
                });
            } // end - snapshot

            if ( formData.id && !_isEmpty( formData.id ) ) {
                getIDToken()
                .then(idToken => {
    
                    doPromise({
                        idToken,
                        method: 'post',
                        url: 'report',
                        params: [{ key: 'action', value: 'broadcast' }],
                        formData
                    })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
    
                })
                .catch(error => {
                    reject(error);
                });
            } else {
                resolve(false);
            } // end - formData.id

        });

    })
}