import * as firebase from 'firebase/app';
import shortid from 'shortid';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';

import { getIDToken } from './auth';
import { doPromise } from '../helpers/action';
import { isArrayExists } from '../helpers/validation';
import { getMomentTime, getMomentTimestamp } from '../helpers/date';

/* helpers start */
const addBroadCastToSetup = (formData,setup_id) => {
    return new Promise((resolve,reject) => {

        var updateData = {};

        updateData["email_broadcasts_setup/"+setup_id+"/label"] = ( formData.label || '' );
        updateData["email_broadcasts_setup/"+setup_id+"/delivery_time"] = ( formData.delivery_time || '' );
        updateData["email_broadcasts_setup/"+setup_id+"/subject_line"] = ( formData.subject_line || '' );
        updateData["email_broadcasts_setup/"+setup_id+"/from_id"] = ( formData.from_id || '' );
        updateData["email_broadcasts_setup/"+setup_id+"/from"] = ( formData.from || '' );
        updateData["email_broadcasts_setup/"+setup_id+"/from_label"] = ( formData.from_label || '' );
        updateData["email_broadcasts_setup/"+setup_id+"/template_id"] = ( formData.template_id || '' );
        updateData["email_broadcasts_setup/"+setup_id+"/template_html"] = ( formData.template_html || '' );
        updateData["email_broadcasts_setup/"+setup_id+"/template_css"] = ( formData.template_css || '' );
        updateData["email_broadcasts_setup/"+setup_id+"/template_js"] = ( formData.template_js || '' );

        if ( formData.lists && isArrayExists( formData.lists ) ) {
            _forEach( formData.lists, list => {
                if ( list.id && !_isEmpty( list.id ) ){
                    updateData["email_broadcasts_setup/"+setup_id+"/lists/"+list.id] = {
                        org: list.org
                    };
                } // end 0 list.id
            });
        } // end - formData.lists

        firebase.database().ref().update(updateData,error => {
            if ( error ) {
                reject(error);
            } else {
                resolve(setup_id);
            } // end - updateData
        });

    })
}


/* helper end */

export const fb_getBroadcasts = (callback) => {

    firebase.database().ref("email_broadcasts").on("value",snapshot => {
        var list = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach( childSnapshot => {
                var val = childSnapshot.val(),
                    item = {
                        id: childSnapshot.key,
                        status: val.status || 'draft',
                        label: val.label || '',
                        subject_line: val.subject_line || '',
                        delivery_time: val.delivery_time || '',
                        delivery_timestamp: val.delivery_timestamp || 0,
                        created_on: ( val.created_on ? getMomentTimestamp( val.created_on ) : 0 ),
                        created_by: val.created_by || '',
                        lists: [],
                        orgs: []
                    };

                if ( childSnapshot.child("lists").exists() && childSnapshot.child("lists").hasChildren() ) {
                    childSnapshot.child("lists").forEach( listSnapshot => {
                        var listVal = listSnapshot.val();
                        item.lists.push({
                            id: listSnapshot.key,
                            address: ( listVal.address || '' ),
                            members_count: ( listVal.members_count || 0 )
                        });
                    });
                } // end - childSnapshot.child("lists")

                if ( childSnapshot.child("orgs").exists() && childSnapshot.child("orgs").hasChildren() ) {
                    childSnapshot.child("orgs").forEach( orgSnapshot => {
                        item.orgs.push({ id: orgSnapshot.key });
                    });
                } // end - childSnapshot.child("orgs")

                list.push(item);
            });
        } // end - snapshot

        callback( list );
    });
    
}

export const fb_getBroadcast = (id) => {
    return new Promise((resolve,reject) => {

        firebase.database().ref("email_broadcasts/"+id).once("value")
        .then( snapshot => {

            var broadcast = {
                id,
                status: 'draft',
                delivery_time: null,
                label: '',
                subject_line: '',
                from_id: 'none',
                from: '',
                from_label: '',
                template_id: 'none',
                template_html: '',
                template_css: '',
                template_js: '',
                lists: [],
                orgs: []
            };

            if ( snapshot.exists() ) {
                var val = snapshot.val();
                broadcast.status = ( val.status || 'draft' );
                broadcast.delivery_time = ( val.delivery_time || null );
                broadcast.label = ( val.label || '' );
                broadcast.subject_line = ( val.subject_line || '' );
                broadcast.from_id = ( val.from_id || 'none' );
                broadcast.from = ( val.from || '' );
                broadcast.from_label = ( val.from_label || '' );
                broadcast.template_id = ( val.template_id || 'none' );
                broadcast.template_html = ( val.template_html || '' );
                broadcast.template_css = ( val.template_css || '' );
                broadcast.template_js = ( val.template_js || '' );

                if ( snapshot.child("lists").exists() && snapshot.child("lists").hasChildren() ) {
                    snapshot.child("lists").forEach( listSnapshot => {
                        var listVal = listSnapshot.val();
                        broadcast.lists.push({
                            id: listSnapshot.key,
                            address: ( listVal.address || '' ),
                            members_count: ( listVal.members_count || 0 )
                        });
                    });
                } // end - snapshot.child("lists")

                if ( snapshot.child("orgs").exists() && snapshot.child("orgs").hasChildren() ) {
                    snapshot.child("orgs").forEach( orgSnapshot => {
                        broadcast.orgs.push({ id: orgSnapshot.key });
                    });
                } // end - snapshot.child("orgs")

            } // end - snapshpt

            resolve(broadcast);

        })
        .catch( error => {
            resolve({});
        });

    })
}

export const fb_createBroadcast = (formData) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            var setup_id = shortid.generate();

            addBroadCastToSetup(formData,setup_id)
            .then(setup_id => {

                doPromise({
                    idToken,
                    method: 'post',
                    url: 'emailBroadcast',
                    params: [{ key: 'action', value: 'broadcast' }],
                    formData: { id: setup_id }
                })
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    reject(error);
                });
    
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });


    })
}

export const fb_updateBroadcast = (formData,broadcast_id) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            addBroadCastToSetup(formData,broadcast_id)
            .then(() => {

                doPromise({
                    idToken,
                    method: 'post',
                    url: 'emailBroadcast',
                    params: [{ key: 'action', value: 'update' }],
                    formData: { id: broadcast_id }
                })
                .then(res => {
                    resolve({ status: 'done' });
                })
                .catch(error => {
                    reject(error);
                });
    
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}

export const fb_deleteBroadcast = (id,useSuperAdminDelete) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'emailBroadcast',
                params: [{ key: 'action', value: ( useSuperAdminDelete ? 'totally_delete' : 'delete' ) }],
                formData: { id }
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}