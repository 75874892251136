import React from 'react';
import styled from "styled-components";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _forEach from 'lodash/forEach';
import _concat from 'lodash/concat';

import Controller from './controller';

import FormInput from '../../components/FormInput';
import FormSelect from '../../components/FormSelect';

import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, getSelectOptions } from '../../helpers/data';
import { validateEmail, isArrayExists } from '../../helpers/validation';

import { toggleLoader } from '../../actions/global';
import { fb_getTemplate } from '../../firebase/email_templates';

import { DEFAULT_EMAIL_TEMPLATE } from '../../constants';

class StepSettings extends React.Component {

    handleFormUpdate = (newValue,key) => {
        const { formData, emails } = this.props;
        var newData = ( formData && !_isEmpty(formData) ? cloneCollections(formData) : {} );

        if ( key === 'from_id' ) {
            var selected = ( emails && isArrayExists( emails ) ? _find( emails, { id: newValue } ) : false );
            newData['from_id'] = newValue;
            newData['from'] = ( selected && selected.email || '' );
            newData['from_label'] = ( selected && selected.label || '' );
        } else {
            newData[key] = newValue;
        } // end - key
        
        // push updates to parent
        if ( this.props.onFormUpdate ) {
            this.props.onFormUpdate(newData);
        }
    }

    handleStepChange = (direction) => {
        const { formData, dispatch, currentMode } = this.props;
        
        if ( direction === 'back' ) {
            this.props.goBack();
        } else if ( direction === 'next' ) {
            // do error check before proceed
            var error = false;

            if ( !( formData.template_id && !_isEmpty( formData.template_id ) ) ) {
                error = 'Please select a template';
            } // end - formData

            if ( !( formData.from && validateEmail( formData.from ) ) ) {
                error = 'Please select a valid From email';
            } // end - formData

            if ( !( formData.subject_line && !_isEmpty( formData.subject_line ) ) ) {
                error = 'Please insert a subject line';
            } // end - formData

            if ( !( formData.label && !_isEmpty( formData.label ) ) ) {
                error = 'Please insert a message label';
            } // end - formData

            if ( error ) {
                triggerErrorAlert(error);
            } else {
                // toggle loader
                dispatch(toggleLoader(true));

                // retrieve email templates before proceed
                new Promise((resolve) => {
                    if ( formData.template_id && !_isEmpty( formData.template_id ) && formData.template_id != 'none' ) {
                        resolve( fb_getTemplate(formData.template_id) );
                    } else {
                        if ( currentMode && currentMode === 'edit' && formData.template_html && !_isEmpty( formData.template_html ) ) {
                            resolve({
                                html: formData.template_html,
                                css: ( formData.template_css || '' ),
                                js: ( formData.template_js || '' )
                            })
                        } else {
                            // if no template, return default template
                            resolve( DEFAULT_EMAIL_TEMPLATE );
                        }
                    }
                })
                .then( template => {
                    // update email template
                    var newData = cloneCollections( formData );
                    newData['template_html'] = ( template && template.html ? template.html : '' );
                    newData['template_css'] = ( template && template.css ? template.css : '' );
                    newData['template_js'] = ( template && template.js ? template.js : '' );

                    this.props.onFormUpdate(newData);

                    // go to next step
                    dispatch(toggleLoader(false));
                    this.props.goNext();
                })
                .catch( error => {
                    dispatch(toggleLoader(false));
                    var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to load template' ) );
                    triggerErrorAlert(errMsg);
                });
            } // end - error

        }
    }

    getEmailOptions = () => {
        const { emails } = this.props;
        var options = [];

        if ( emails && isArrayExists( emails ) ) {
            _forEach( emails, item => {
                options.push({ value: item.id, label: ( item.label || '' ) + ' - ' + ( item.email || '' ) });
            });
        } // end - emails

        return _concat( [{ value: 'none', label: 'Select an Option' }] , _sortBy( options, ['label'] ) );
    }

    render() {
        const { formData, step, templates, emails } = this.props;
        return (
        <div style={{ maxWidth: "800px", marginLeft: "auto", marginRight: "auto" }}>
            
            <Paper elevation={2} style={{ padding: "30px", background: "#fff" }}>

                <Typography variant="h4" style={{ textAlign: "center", marginBottom: "30px" }}>Broadcast Settings</Typography>
                
                <FormInput label="Message Name (For your reference only)" value={( formData.label || '' )} name="label" onChange={this.handleFormUpdate} />

                <FormInput label="Email Subject Line" value={( formData.subject_line || '' )} name="subject_line" onChange={this.handleFormUpdate} />

                <div style={{ paddingTop: '8px' }}>
                    <FormSelect 
                        label="From" 
                        name="from_id" 
                        value={( formData.from_id || '' )} 
                        options={this.getEmailOptions()}
                        onChange={this.handleFormUpdate} />
                </div>

                <div style={{ marginTop: '15px' }}>
                    <FormSelect 
                        label="Email Template" 
                        name="template_id" 
                        value={( formData.template_id || '' )} 
                        options={getSelectOptions({
                            list: templates,
                            options: [{ value: 'none', label: 'No Template' }],
                            keys: { value: 'id', label: 'label' },
                            sortBy: 'label'
                        })}
                        onChange={this.handleFormUpdate} />
                </div>

            </Paper>

            <Controller step={step} onClick={this.handleStepChange} />

        </div>
        );
    }

}

export default StepSettings;