import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Papa from 'papaparse';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import _find from 'lodash/find';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _forEach from 'lodash/forEach';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';

import { InfoButton, InverseButton,  ErrorButton, ButtonGroup, AInfoLink } from '../../styles/button';
import { SuccessTag, ErrorTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isSuperAdmin } from '../../helpers/auth';
import { isArrayExists } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch } from '../../helpers/data';


const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});


class ExportContacts extends React.Component {
    
    state = {
        sortBy: 'date-desc',
        perPage: 20,
        page: 1
    }

    handleReset = (event) => {
        event.preventDefault();
        if ( this.props.onReset )
            this.props.onReset();
    }

    handleExport = (event) => {
        event.preventDefault();
        const { searchterms, sortBy } = this.state;
        const { contacts, lists, list_id, authData } = this.props;
        var items = ( contacts ? cloneCollections( contacts ) : [] );

        // org filter - for admin only
        if ( !isSuperAdmin( authData ) ) {
            items = _filter( items, (c) => ( ( c.lists && isArrayExists( c.lists ) && _find( c.lists, (l) => ( lists && isArrayExists( lists ) && _find( lists, { id: l.id } ) ? true : false )) ) || c.old_lists && isArrayExists( c.old_lists ) && _find( c.old_lists, (l) => ( lists && isArrayExists( lists ) && _find( lists, { id: l.id } ) ? true : false )) ? true : false ));
        } // end - authData

        // do search filter
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email','subscribed'] );
        } // end - searchterms

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    //items = _sortBy( items, ['created_on'] );
                    break;
                case 'date-asc':
                    //items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, [(o) => o.email.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, [(o) => o.email.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        var results = { fields: ['firstname','lastname','email','subscribed','bounced'], data: [] };

        if ( lists && isArrayExists( lists ) ) {
            _forEach( _sortBy( lists, ['id'] ), list => {
                results.fields.push(list.id);
            });
        } // end - lists

        if ( items && isArrayExists( items ) ) {
            _forEach( items, contact => {
                let item = [];
 
                item.push( contact.first_name || '' );
                item.push( contact.last_name || '' );
                item.push( contact.email || '' );
                item.push( contact.subscribed || '' );
                item.push( contact.bounced || '' );

                if ( lists && isArrayExists( lists ) ) {
                    _forEach( _sortBy( lists, ['id'] ), list => {
                        var selected = ( contact.lists && isArrayExists( contact.lists ) ? _find( contact.lists, { id: list.id } ) : false );
                        if ( selected ) {
                            if ( contact.subscribed && contact.subscribed === 'no' ) {
                                item.push('unsubscribed');
                            } else {
                                item.push('yes');
                            }
                        } else {
                            // check old_lists to see previous list
                            var selected_old = ( contact.old_lists && isArrayExists( contact.old_lists ) ? _find( contact.old_lists, { id: list.id } ) : false );
                            if ( selected_old ) {
                                // if found in old list
                                if ( contact.bounced && contact.bounced === 'yes' ) {
                                    item.push('bounced');
                                } else if ( contact.subscribed && contact.subscribed === 'no' ) {
                                    item.push('unsubscribed');
                                } else {
                                    // else just push no
                                    item.push('no');
                                } // end - contact
                            } else {
                                // push no - if not in the list
                                item.push('no');
                            } // end - selected_old
                        } // end - selected                        
                    });
                } // end - lists

                results.data.push(item);
            });
        } // end - events

        var csv = Papa.unparse( results, { delimiter: ',' } );
        var blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        var uri = window.URL.createObjectURL(blob);
        var link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "export_contacts.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleView = (id,event) => {
        event.preventDefault();
        this.props.history.push("/contacts/"+id);
    }

    reorganizeData() {
        const { searchterms, sortBy, perPage, page } = this.state;
        const { contacts, lists, authData } = this.props;
        var items = ( contacts ? cloneCollections( contacts ) : [] ),
            total = _size( items );

        // org filter - for admin only
        if ( !isSuperAdmin( authData ) ) {
            items = _filter( items, (c) => ( ( c.lists && isArrayExists( c.lists ) && _find( c.lists, (l) => ( lists && isArrayExists( lists ) && _find( lists, { id: l.id } ) ? true : false )) ) || c.old_lists && isArrayExists( c.old_lists ) && _find( c.old_lists, (l) => ( lists && isArrayExists( lists ) && _find( lists, { id: l.id } ) ? true : false )) ? true : false ));
            total = _size( items );
        } // end - authData

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email','subscribed'] );
			total = _size( items );
        } // end - searchterms

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    items = _sortBy( items, ['created_on'] );
                    break;
                case 'date-asc':
                    items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, [(o) => o.email.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, [(o) => o.email.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderTableActions = (total) => {
        const { sortBy, perPage } = this.state;
        return <TableBar
                show={['sort','entries']}
                sortBy={sortBy}
                perPage={perPage}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Entries first' },
                    { value: 'date-asc', label: 'Oldest Entries first' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    { value: 'email-asc', label: 'Email ( A - Z)' },
                    { value: 'email-desc', label: 'Email ( Z - A )' }
                ]}
                leftButtons={[
                    <InverseButton minWidth="128px" key="reset" style={{ padding: "10px 25px" }} onClick={this.handleReset}><i className="fa fa-refresh"></i>Reset</InverseButton>
                ]}
                rightButtons={ total ? [
                    <InfoButton minWidth="128px" key="export" style={{ marginRight: "5px", paddingLeft: "25px", paddingRight: "25px" }} onClick={this.handleExport}><i className="fa fa-file-excel-o"></i>Export {total} contact(s) to CSV</InfoButton>
                ] : null }
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderLists = (current) => {
        const { lists } = this.props;
        return current.map(list => {
            var selected = _find( lists, { id: list.id } );
            return ( selected && selected.name ? <div key={list.id} style={{ fontSize: '0.875em', marginBottom: "4px" }}>{selected.name}</div> : null );
        });
    }

    renderBody = (items) => {
        const { classes, lists } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell}>
                        <WrapWord>{item.email || ''}</WrapWord>
                    </TableCell>
                    <TableCell className={classes.bodycell}>{item.name || ''}</TableCell>
                    <TableCell className={classes.bodycell}>{item.subscribed && item.subscribed == 'yes' ? <SuccessTag>YES</SuccessTag> : <ErrorTag>NO</ErrorTag>}</TableCell>
                    <TableCell className={classes.bodycell}>{item.bounced && item.bounced == 'yes' ? <ErrorTag>YES</ErrorTag> : <SuccessTag>NO</SuccessTag>}</TableCell>
                    <TableCell className={classes.bodycell}>
                        { item.lists && isArrayExists( item.lists ) ? this.renderLists(item.lists) : '---' }
                    </TableCell>
                    <TableCell className={classes.bodycell}>
                        { item.old_lists && isArrayExists( item.old_lists ) ? this.renderLists(item.old_lists) : '---' }
                    </TableCell>
                    <TableCell className={classes.headcell}>
                        <ButtonGroup>
                            <AInfoLink href={"/contacts/"+item.id} size="small" target="_blank"><i className="fa fa-search"></i>View</AInfoLink>
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No Contact(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes, dataType } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell}>Email</TableCell>
                <TableCell className={classes.headcell}>Name</TableCell>
                <TableCell className={classes.headcell}>Subscribed?</TableCell>
                <TableCell className={classes.headcell}>Bounced?</TableCell>
                <TableCell className={classes.headcell}>Lists (Subscribed)</TableCell>
                <TableCell className={classes.headcell}>Lists (Unsubscribed or Bounced)</TableCell>
                <TableCell className={classes.headcell} style={{ width: "10%" }}>Actions</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { items, total } = this.reorganizeData();
        return (
        <div>

            {this.renderTableActions(total)}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

export default compose(
    connect(),
    withStyles(useStyles),
    withRouter
)(ExportContacts);