import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';

const theme = createMuiTheme({
    palette: {
        primary: blue,
        secondary: red,
        background: '#333333'
    },
    typography: {
        htmlFontSize: 14,
        fontSize: 16
    }
});

export default theme;