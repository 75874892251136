import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';

import Report from './report';

import AppWrapper from '../../components/AppWrapper';
import FormSelect from '../../components/FormSelect';
import DotsLoader from '../../components/DotsLoader';
import ModelDelete from '../../components/ModalDelete';

import { cloneCollections } from '../../helpers/data';
import { isArrayExists } from '../../helpers/validation';
import { isSuperAdmin } from '../../helpers/auth';

import { InverseButton, ErrorButton } from '../../styles/button';

import { getBroadcasts } from '../../actions/email_broadcast';
import { getEmailBroadCastReport, deleteBroadcastInReport } from '../../actions/reports';
import { resetRedux } from '../../actions/misc';

class ReportBroadcastPage extends React.Component {

    state = {
        selected: '',
        processing: false,
        reportRand: false,
        randNum: false,
        openDeleteModal: false,
        deleteModal: false
    };

    componentDidMount() {

        // get broadcasts
        this.props.dispatch(getBroadcasts());
        
        // get stats from API if 
        if ( this.props.location.search && !_isEmpty( this.props.location.search ) ) {
            this.setBroadcast();
            this.props.dispatch(getEmailBroadCastReport(this.getQuery()));
        } // end - location.search
            
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { emailBroadCasts, broadcastRand, broadcastReport, randNum } = this.props;

        // update randNum
        if ( emailBroadCasts && broadcastRand && broadcastRand != this.state.randNum ) {
            this.setState({ randNum: broadcastRand });
        } // end - emailBroadCasts

        // update report rand
        if ( broadcastReport && randNum && randNum !== this.state.reportRand ) {
            this.setState({ reportRand: randNum, processing: false });
        } // end - broadcastReport
    }

    componentWillUnmount() {
        this.props.dispatch(resetRedux('report_email_broadcast'));
    }

    handleRefresh = () => {
        const { selected } = this.state;
        this.setState({ processing: true });
        this.props.dispatch(getEmailBroadCastReport(this.getQuery(selected)));
    }

    handleDelete = () => {
        const { authData } = this.props;
        const { deleteModal, selected } = this.state;
        // check whather to use super admin delete
        let useSuperAdminDelete = ( isSuperAdmin( authData ) && deleteModal && deleteModal.status && deleteModal.status === 'broadcasted' ? true : false );
        // perform delete
        this.props.dispatch(deleteBroadcastInReport(deleteModal.id,useSuperAdminDelete,this.getQuery(selected)));
    }

    handleBroadcastChange = (newValue) => {
        if ( newValue && !_isEmpty( newValue ) ) {
            this.setState({ selected: newValue, processing: true });
            this.props.dispatch(getEmailBroadCastReport(this.getQuery(newValue)));
        } else {
            this.setState({ selected: newValue });
        }
    }

    getBroadcastOptions = () => {
        const { emailBroadCasts, authData } = this.props;
        var options = [{ value: '', label: 'Select a Broadcast' }],
            broadcasts = ( emailBroadCasts ? cloneCollections( emailBroadCasts ) : [] );

        if ( broadcasts && isArrayExists( broadcasts ) ) {
            // filter out invalid broadcasts
            broadcasts = _filter( broadcasts, (b) => ( b.status && b.status == 'broadcasted' ? true : false ) );

            // filter broadcasts by org if not super admin
            if ( !isSuperAdmin( authData ) ) {
                broadcasts = _filter( broadcasts, (b) => ( b.orgs && isArrayExists( b.orgs ) && _find( b.orgs, (o) => ( authData && authData.orgs && authData.orgs.indexOf(o.id) >= 0 ) ) ? true : false ) );
            } // end - authData

            // sort by delivery_date
            broadcasts = _sortBy( broadcasts, ['delivery_timestamp'] );
            broadcasts = _reverse( broadcasts );

            _forEach( broadcasts, broadcast => {
                options.push({ value: broadcast.id, label: broadcast.label || '' });
            });
        } // end = broadcasts

        return options;
    }

    setBroadcast = () => {
        const { broadcast_id } = queryString.parse( this.props.location.search );
        if ( broadcast_id && !_isEmpty( broadcast_id ) )
            this.setState({ selected: broadcast_id, processing: true });
    }

    getQuery = (bid) => {
        const { broadcast_id } = queryString.parse( this.props.location.search );
        var query = { 
            id: ( bid ? bid : ( broadcast_id || false ) )
        };


        return query;
    }

    renderReport = () => {
        const { broadcastReport } = this.props;
        const { reportRand } = this.state;
        return (
        <Fade in={true} timeout={350}>
            <>
                <div style={{ margin: "20px 0" }}>
                    <ErrorButton size="small" onClick={() => this.setState({ openDeleteModal: true, deleteModal: broadcastReport })}><i className="fa fa-trash"></i>Delete Broadcast Report</ErrorButton>
                </div>
                <Report 
                    broadcast={broadcastReport} 
                    randNum={reportRand}
                    onRefresh={this.handleRefresh} />
            </>
        </Fade>
        );
    }

    renderInvalidReport = () => {
        return (
        <div style={{ textAlign: 'center', padding: '40px' }}>
            <Typography variant="h3" style={{ marginBottom: "20px" }}>Invalid Report ID / Report has been deleted</Typography>
            <InverseButton style={{ padding: '10px 25px' }} onClick={() => {
                this.props.history.push('/broadcast');
            }}><i className="fa fa-long-arrow-left"></i>Go Back</InverseButton>
        </div>
        )
    }

    renderSelector = () => {
        const { selected } = this.state;
        return (
        <div>
            <FormSelect 
                value={( selected || '' )}
                options={this.getBroadcastOptions()}
                onChange={this.handleBroadcastChange} />
        </div>
        );
    }

    renderContents() {
        const { broadcastReport } = this.props;
        const { reportRand, processing, openDeleteModal, deleteModal } = this.state;
        return (
        <div>

            {this.renderSelector()}

            { processing ? <DotsLoader style={{ padding: "90px 30px" }} /> : ( reportRand && broadcastReport && broadcastReport.exists ? this.renderReport() : this.renderInvalidReport() ) }

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.label ? `Are you sure you want to delete this broadcast ( ${deleteModal.label} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

        </div>
        );
    }

    render() {
        const { emailBroadCasts } = this.props;
        const { randNum } = this.state;
        return <AppWrapper 
                title="Email BroadCast"
                subtitle="Reports"
                maxWidth="1110px"
                back="/broadcast"
                breadcrumbs={[
                    { url: '/broadcast', label: 'Email Broadcasts' },
                    { label: 'Report' }
                ]}
                onLoad={( !( emailBroadCasts && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        emailBroadCasts: state.email_broadcasts && state.email_broadcasts.broadcasts || null,
        broadcastRand: state.email_broadcasts && state.email_broadcasts.rand || null,
        broadcastReport: state.reports && state.reports.email_broadcast || null,
        randNum: state.reports && state.reports.rand || null
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(ReportBroadcastPage);