export const SAMPLE_ACTION = 'sample_action';

/* auth */
export const IS_LOGGED_IN = 'is_logged_in';
export const AUTH_LOGIN = 'auth_login';
export const AUTH_LOGOUT = 'auth_logout';
export const AUTH_GET_PROFILE = 'auth_get_profile';
export const AUTH_EDIT_PROFILE = 'auth_edit_profile';

/* users actions */
export const GET_USERS = 'get_users';
export const ADD_USER = 'add_user';
export const EDIT_USER = 'edit_user';
export const DELETE_USER = 'delete_user';
export const SYNC_USERS = 'sync_users';

/* contacts actions */
export const GET_CONTACTS = 'get_contacts';
export const GET_CONTACT = 'get_contact';
export const ADD_CONTACT = 'add_contact';
export const EDIT_CONTACT = 'edit_contact';
export const DELETE_CONTACT = 'delete_contact';

/* list actions */
export const GET_LISTS = 'get_lists';
export const LISTS_RESET = 'lists_reset';
export const GET_LIST = 'get_list';
export const GET_LIST_CONTACTS = 'get_list_contacts';
export const IMPORT_CONTACTS = 'import_contacts';
export const ADD_LIST = 'add_list';
export const EDIT_LIST = 'edit_list';
export const DELETE_LIST = 'delete_list';
export const RESET_LIST = 'reset_list';

/* email broadcast actions */
export const GET_EMAIL_BROADCASTS = 'get_email_broadcasts';
export const GET_EMAIL_BROADCAST = 'get_email_broadcast';
export const CREATE_EMAIL_BROADCAST = 'create_email_broadcast';
export const UPDATE_EMAIL_BROADCAST = 'update_email_broadcast';
export const DELETE_EMAIL_BROADCAST = 'delete_email_broadcast';
export const RESET_EMAIL_BROADCAST = 'reset_email_broadcast';

/* report actions */
export const GET_REPORT_EMAIL_BROADCAST = 'get_report_email_broadcast';
export const GET_REPORT_RECENT_EMAIL_BROADCAST = 'get_report_recent_email_broadcast';
export const RESET_REPORT_EMAIL_BROADCAST = 'reset_report_email_broadcast';

/* report actions */
export const TOOLS_EXPORT_CONTACTS = 'tools_export_contacts';
export const TOOLS_IMPORT_CONTACTS = 'tools_import_contacts';
export const TOOLS_RESET_EXPORT = 'tools_reset_export';
export const TOOLS_RESET_IMPORT = 'tools_reset_import';


/* Media Library actions */
export const GET_MEDIA_LIBRARY = 'get_media_library';
export const UPLOAD_NEW_MEDIA = 'upload_new_media';
export const EDIT_MEDIA = 'edit_media';
export const DELETE_MEDIA = 'delete_media';


/* maintenance actions */
export const M_GET_ORGS = 'm_get_orgs';
export const M_ORGS_UPDATED = 'm_orgs_updated';
export const M_GET_ROLES = 'm_get_roles';
export const M_ROLES_UPDATED = 'm_roles_updated';
export const M_GET_EMAIL_TEMPLATES = 'm_get_email_templates';
export const M_GET_EMAIL_TEMPLATE = 'm_get_email_template';
export const M_ADD_EMAIL_TEMPLATE = 'm_add_email_template';
export const M_EDIT_EMAIL_TEMPLATE = 'm_edit_email_template';
export const M_DELETE_EMAIL_TEMPLATE = 'm_delete_email_template';
export const M_GET_REPLYTO_EMAILS = 'm_get_replyto_emails';
export const M_REPLYTO_EMAILS_UPDATED = 'm_replyto_emails_updated';


/* global actions */
export const GLOBAL_MODAL_PROCESSING = 'global_modal_processing';
export const GLOBAL_MODAL_DELETING = 'global_modal_deleting';
export const GLOBAL_ERROR_TRIGGER = 'global_error_trigger';
export const GLOBAL_LOADER_START = 'global_loader_start';
export const GLOBAL_LOADER_END = 'global_loader_end';
export const GLOBAL_SNACKBAR_TRIGGER = 'global_snackbar_trigger';

/* MISC actions */
export const MISC_CHANGES_SAVED = 'misc_changes_saved';
export const MISC_CHANGES_RESET = 'misc_changes_reset';
export const MISC_CHANGES_NOT_SAVED = 'misc_changes_not_saved';
