import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Dropzone from 'react-dropzone';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import _endsWith from 'lodash/endsWith';
import _startsWith from 'lodash/startsWith';
import _size from 'lodash/size';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _round from 'lodash/round';

import ModelDelete from '../ModalDelete';
import Pagination from '../Pagination';
import TableBar from '../TableBar';

import { InfoButton, GreyButton, ErrorButton } from '../../styles/button';

import { isArrayExists } from '../../helpers/validation';
import { cloneCollections, doPagination, doArraySearch } from '../../helpers/data';
import { isSuperAdmin } from '../../helpers/auth';
import { getMomentTime } from '../../helpers/date';
import { triggerSuccessAlert } from '../../helpers/alert';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'middle'
    }
});

class MediaTable extends React.Component {

    state = {
        searchterms: '',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        checked: [],
        allCheck: false,
        selected: false,
        openDelete: false
    }

    handleFileUpload = (files) => {
        const { onFileUpload } = this.props;
        if ( files && isArrayExists( files ) && onFileUpload )
            onFileUpload(files);
    }

    handleFileDelete = () => {
        const { onFileDelete } = this.props;
        const { checked } = this.state;
        if ( checked && isArrayExists( checked ) && onFileDelete ) {
            onFileDelete(checked);
            this.setState({ checked: [], allCheck: false });
        }
    }

    handleAllCheck = medias => event => {
        var newChecked = [];
        if ( event.target.checked ) {
            if ( medias && isArrayExists( medias ) ) {
                _forEach( medias, media => {
                    newChecked.push( media.id );
                });
            } // end - medias
        } // end - event.target.checked
        this.setState({ checked: newChecked, allCheck: event.target.checked });
    }

    handleCheckboxClick = media => event => {
        const { checked } = this.state;
        var newChecked = ( checked && isArrayExists( checked ) ? cloneCollections( checked ) : [] );
        if ( event.target.checked ) {
            if ( !_find( newChecked, (c) => ( c === media.id ) ) ) {
                newChecked.push(media.id);
            }
        } else {
            if ( _find( newChecked, (c) => ( c === media.id ) ) ) {
                var pulled = _remove( newChecked, (c) => ( c === media.id ) );
            }
        }
        this.setState({ checked: newChecked });
    }

    reorganizeData = () => {
        const { list, authData } = this.props;
        const { searchterms, sortBy, perPage, page } = this.state;
        let medias = ( list && isArrayExists( list ) ? cloneCollections( list ) : [] ),
            total = _size( medias );

        // super admin filter
        if ( !isSuperAdmin( authData ) ) {
            medias = _filter( medias, (m) => ( m.orgs && isArrayExists( m.orgs ) && _find( m.orgs, (o) => ( authData.orgs && !_isEmpty( authData.orgs ) && authData.orgs.indexOf(o.id) >= 0 ? true : false ) ) ? true : false ));
        } // end - authData

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            medias = doArraySearch( medias, searchterms, ['name'] );
            total = _size( medias );
        } // end - searchterms

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( medias ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    medias = _sortBy( medias, ['created_on'] );
                    medias = _reverse( medias );
                    break;
                case 'date-asc':
                    medias = _sortBy( medias, ['created_on'] );
                    break;
                case 'name-desc':
                    medias = _sortBy( medias, ['name'] );
                    medias = _reverse( medias );
                    break;
                case 'name-asc':
                    medias = _sortBy( medias, ['name'] );
                    break;
            }
        } // end - sortBy

        // do pagination
        medias = doPagination( medias, perPage, page );

        return { medias, total };
    }

    getFileIcon = (media) => {
        // get file icon
        if ( media.type && _endsWith( media.type, 'pdf' ) ) {
            return 'fa fa-file-pdf-o';
        } else if ( media.type && _startsWith( media.type, 'image' ) ) {
            return 'fa fa-picture-o';
        } else {
            return 'fa fa-file-o';
        }
    }

    getFileSize = (size) => {
        var label = '---';
        if ( (size/1000000) > 1 ) {
            label = _round( (size/1000000), 1 ) + ' Mb';
        } else if ( (size/1000) > 1 ) {
            label = _round( (size/1000), 1 ) + ' Kb';
        } else {
            label = size + ' b';
        }

        return label;
    }

    isChecked = (media) => {
        const { checked } = this.state;
        return ( checked && isArrayExists( checked ) && media && media.id && _find( checked, (c) => ( c == media.id ) ) ? true : false );
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderTableTopBar = () => {
        const { checked } = this.state;
        return (
        <Grid container spacing={2} style={{ marginBottom: "20px" }} alignItems="center">
            <Grid item xs={4}>
                { checked && isArrayExists( checked ) ? (
                <div>
                    <GreyButton style={{ padding: "10px 45px", borderRadius: "45px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                    <Tooltip title={<span style={{ fontSize: "12px" }}>Delete Selected File(s)</span>}>
                        <ErrorButton style={{ padding: "10px 15px" }} minWidth="0px" onClick={() => this.setState({ openDelete: true })}><i className="fa fa-trash" style={{ marginRight: "0px" }}></i></ErrorButton>
                    </Tooltip>
                </div>
                ) : null }
            </Grid>
            <Grid item xs={8} style={{ textAlign: "right" }}>
                <Dropzone onDrop={this.handleFileUpload} accept="image/*, application/pdf">
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ style: {
                        display: "inline-block"
                    } })}>
                        <input {...getInputProps()} />
                        <InfoButton style={{ padding: "10px 45px", borderRadius: "45px" }}><i className="fa fa-cloud-upload"></i>Upload File</InfoButton>
                    </div>
                )}
                </Dropzone>
            </Grid>
        </Grid>
        );
    }

    renderTableActions = () => {
        const { sortBy, perPage, searchterms, checked } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Entries first' },
                    { value: 'date-asc', label: 'Oldest Entries first' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' }
                ]}
                leftWidth="50%"
                leftButtons={ checked && isArrayExists( checked ) ? [
                    <div key="check_option" style={{ marginLeft: "15px", paddingTop: "20px" }}>
                        <GreyButton style={{ padding: "10px 45px", borderRadius: "45px", marginRight: "10px" }} onClick={() => this.setState({ checked: [], allCheck: false })}><i className="fa fa-remove" style={{ marginRight: "10px" }}></i>{_size(checked) + ' selected'}</GreyButton>
                        <Tooltip title={<span style={{ fontSize: "12px" }}>Delete Selected File(s)</span>}>
                            <ErrorButton style={{ padding: "10px 15px" }} minWidth="0px" onClick={() => this.setState({ openDelete: true })}><i className="fa fa-trash" style={{ marginRight: "0px" }}></i></ErrorButton>
                        </Tooltip>
                    </div>
                    ] : null }
                rightWidth="50%"
                rightButtons={[
                    <div key="upload_new" style={{ paddingRight: "15px", paddingTop: "5px" }}>
                        <Dropzone onDrop={this.handleFileUpload} accept="image/*, application/pdf">
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ style: {
                                display: "inline-block"
                            } })}>
                                <input {...getInputProps()} />
                                <InfoButton style={{ padding: "10px 45px", borderRadius: "45px" }}><i className="fa fa-cloud-upload"></i>Upload File</InfoButton>
                            </div>
                        )}
                        </Dropzone>
                    </div>
                ]}
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderFileRow = (media) => {
        const { classes } = this.props;
        return (
        <TableRow hover key={media.id}>
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={this.isChecked(media)}
                    onClick={this.handleCheckboxClick(media)} />
            </TableCell>
            <TableCell className={classes.bodycell}>
                { media && media.type && _startsWith( media.type, 'image' ) && media.url && !_isEmpty( media.url ) ? <img src={media.url} style={{ display: "inline-block", maxWidth: "50px", marginRight: "10px", verticalAlign: "middle" }} /> : <i className={this.getFileIcon(media)} style={{ marginRight: "10px", verticalAlign: "middle" }}></i> }
                {media && media.name || ''}
            </TableCell>
            <TableCell className={classes.bodycell}>{ media && media.url && !_isEmpty( media.url ) ? (
                <CopyToClipboard text={media.url}
                    onCopy={() => {
                        triggerSuccessAlert("Copied to clipboard!")
                    }}>
                    <InfoButton style={{ padding: "5px 15px" }}><i className="fa fa-link"></i>Get Link</InfoButton>
                </CopyToClipboard>
            ) : '---' }</TableCell>
            <TableCell className={classes.bodycell}>{ media && media.size ? this.getFileSize(media.size) : '---'}</TableCell>
            <TableCell className={classes.bodycell}>{ media && media.type || '---'}</TableCell>
            <TableCell className={classes.bodycell}>{ media && media.created_on ? getMomentTime( media.created_on, 'YYYY-MM-DD' ) : '---' }</TableCell>
        </TableRow>
        );
    }

    render() {
        const { classes } = this.props;
        const { allCheck, openDelete } = this.state;
        const { medias, total } = this.reorganizeData();
        return (
        <div>
            {this.renderTableActions()}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox" className={classes.headcell}>
                            <Checkbox
                                color="primary"
                                checked={allCheck}
                                onClick={this.handleAllCheck(medias)} />
                        </TableCell>
                        <TableCell className={classes.headcell}>Name</TableCell>
                        <TableCell className={classes.headcell}>Link</TableCell>
                        <TableCell className={classes.headcell}>Size</TableCell>
                        <TableCell className={classes.headcell}>Type</TableCell>
                        <TableCell className={classes.headcell}>Created On</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { medias && isArrayExists( medias ) ? _map( medias, this.renderFileRow) : <TableRow><TableCell className={classes.bodycell} colSpan={5}>No file(s) found.</TableCell></TableRow> }
                </TableBody>
            </Table>
            {this.renderPagination(total)}

            <ModelDelete
                open={openDelete}
                title={"Are you sure you want to delete the selected file(s)?"}
                onClose={() => this.setState({ openDelete: false })}
                onDelete={this.handleFileDelete} />

        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        mediaList: state.media_library && state.media_library.list || null,
        randNum: state.media_library && state.media_library.rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles)
)(MediaTable);