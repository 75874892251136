import React from 'react';
import styled from "styled-components";
import { compose } from "recompose";
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';

import { InfoLink } from '../../styles/button';

import theme from '../../theme';


const FakeSnackBarWrapper = styled.div`
    display: inline-block;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 9999;
`;

const FakeSnackBar = styled.div`
    display: block;
    padding: 15px 40px 15px 25px;
    vertical-align: middle;
`;


class SnackBarSave extends React.Component {

    state = {
        open: false,
        randNum: false
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    handleClick = (event) => {
        event.preventDefault();
        if ( this.props.onSave )
            this.props.onSave(event);
    }

    renderSnackBar() {
        return (
        <FakeSnackBarWrapper>
            <Paper elevation={2} style={{ background: "#333", borderRadius: "9px" }}>
                <FakeSnackBar>
                    <i className="fa fa-exclamation-triangle" style={{ marginRight: "10px", color: red['500'], fontSize: "18px", verticalAlign: 'middle' }}></i>
                    <span style={{ fontSize: "14px", fontWeight: "700", textTransform: "uppercase", color: "#fff", marginRight: "25px" }}>Changes Not Saved</span>
                    <InfoLink onClick={this.handleClick} style={{ fontSize: "14px", fontWeight: "700", textTransform: "uppercase" }}>Save Now</InfoLink>
                </FakeSnackBar>
            </Paper>
        </FakeSnackBarWrapper>
        );
    }

    render() {
        const { changesMade } = this.props;
        return <Grow in={changesMade} timeout={350}>{this.renderSnackBar()}</Grow>;
    }

}

const mapStateToProps = state => {
    return {
        changesMade: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps)
)(SnackBarSave);