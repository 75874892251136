import Alert from 'react-s-alert';

import {
	MISC_CHANGES_NOT_SAVED,
	MISC_CHANGES_RESET,
	RESET_LIST,
	RESET_REPORT_EMAIL_BROADCAST,
	RESET_EMAIL_BROADCAST,
	TOOLS_RESET_EXPORT
} from './types';

export const appChangesMade = () => {
	return dispatch => {
  		dispatch({ type: MISC_CHANGES_NOT_SAVED });
	}
}

export const appChangesReset = () => {
	return dispatch => {
  		dispatch({ type: MISC_CHANGES_RESET });
	}
}

export const resetRedux = (type) => {
	return dispatch => {
		switch( type ) {
			case 'list':
				setTimeout(() => {
					dispatch({ type: RESET_LIST });
				}, 150);
				break;
			case 'report_email_broadcast':
				setTimeout(() => {
					dispatch({ type: RESET_REPORT_EMAIL_BROADCAST });
				}, 150);
				break;
			case 'email_broadcast':
				setTimeout(() => {
					dispatch({ type: RESET_EMAIL_BROADCAST });
				}, 150);
				break;
			case 'tools_export':
					setTimeout(() => {
						dispatch({ type: TOOLS_RESET_EXPORT });
					}, 150);
					break;
		}
	}
}