import _forEach from 'lodash/forEach';
import _sortBy from 'lodash/sortBy';
import _concat from 'lodash/concat';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _slice from 'lodash/slice';
import _filter from 'lodash/filter';
import _size from 'lodash/size';
import _toLower from 'lodash/toLower';
import _split from 'lodash/split';

import { isArrayExists, safeUnescape } from './validation';

// get cloned collections
export const cloneCollections = function(collections) {
    return _cloneDeep( collections );
}

// do search in array
export const doArraySearch = function( data, keywords, compares ) {
    var newData = [],
        splitKeywords = keywords.split(' ');

    if ( isArrayExists( data ) ) {
        newData = _filter( data, function(single) {
            var found = false;

            if ( isArrayExists( splitKeywords ) ) {
                var splitCount = _size( splitKeywords ),
                    keywordCount = 0;
                _forEach( splitKeywords, function(keyword) {
                    if ( isArrayExists( compares ) ) {
                        var valid = false;
                        _forEach( compares, function(key) {
                            if ( _toLower( single[key] ).indexOf(_toLower(keyword)) !== -1 )
                                valid = true;
                        });

                        if ( valid )
                            keywordCount++;
                    } // end - compares
                });
                if ( keywordCount == splitCount )
                    found = true;
            }

            return found;
        });
    } // end - data

    return newData;
}

// do pagination
export const doPagination = function( list, per_page, page ) {
    var start = per_page*(page-1),
        end = (per_page*page);
    return ( isArrayExists( list ) ? _slice( list, start, end ) : [] );
}

// replace all keywords on a given string
export const replaceAll = function( string, search, replacement ) {
	return string.replace(new RegExp(search, 'g'), replacement);
};

// get selectable options
export const getSelectOptions = function( props ) {
    const { list, options, keys, sortBy, unescape } = props;
    var returnData = []; 

    if ( isArrayExists( list ) ) {
        _forEach( list, function(single) {
            var label = ( single[keys.label] || '' );
            returnData.push({ value: single[keys.value], label: ( unescape ? safeUnescape(label) : label ) });
        });
    }

    if ( isArrayExists( returnData ) && sortBy ) {
        returnData = _sortBy( returnData, [ sortBy ] );
    }

    if ( isArrayExists( options ) ) {
        return _concat( options, returnData );
    } else {
        return returnData;
    }
    
}

// get select values
export const getSelectValues = (options,key) => {
    var values = [];

    if ( options && isArrayExists( options ) ) {
        options.forEach(option => {
            if ( option[key] )
                values.push(option[key]);
        });
    } // end- options

    return values;
}

// reverse select values
export const reverseSelectValues = (options,key) => {
    var values = [];

    if ( options && isArrayExists( options ) ) {
        options.forEach(option => {
            var val = {};
            val[key] = option;
            values.push(val);
        });
    } // end- options

    return values;
}