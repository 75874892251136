import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Papa from 'papaparse';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";
import _find from 'lodash/find';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _slice from 'lodash/slice';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import _forEach from 'lodash/forEach';

import Pagination from '../../components/Pagination';
import TableBar from '../../components/TableBar';
import ModelDelete from '../../components/ModalDelete';
import ModalView from '../../components/ModalView';
import FormMultiSelect from '../../components/FormMultiSelect';
import FormInput from '../../components/FormInput';

import { InfoButton, ErrorButton, ButtonGroup, AInfoLink, IndigoButton } from '../../styles/button';
import { SuccessTag, ErrorTag } from '../../styles/tag';
import { WrapWord } from '../../styles/misc';

import { isArrayExists, validateEmail } from '../../helpers/validation';
import { triggerErrorAlert } from '../../helpers/alert';
import { cloneCollections, doPagination, doArraySearch, getSelectOptions } from '../../helpers/data';

import { addNewContact, deleteContact, deleteContactFromList } from '../../actions/contacts';
import { appChangesReset } from '../../actions/misc';

const useStyles = theme => ({
    headcell: {
        fontSize: '16px',
        fontWeight: "700",
        color: theme.palette.background
    },
    bodycell: {
        fontSize: '16px',
        verticalAlign: 'top'
    }
});


class ListContacts extends React.Component {
    
    state = {
        searchterms: '',
        sortBy: 'date-desc',
        perPage: 20,
        page: 1,
        openViewModal: false,
        modalData: false,
        openDeleteModal: false,
        deleteModal: false,
        openDeleteFromListModal: false,
        deleteFromListModal: false
    }

    handleExport = (event) => {
        event.preventDefault();
        const { searchterms, sortBy } = this.state;
        const { contacts, list_id } = this.props;
        var items = ( contacts ? cloneCollections( contacts ) : [] );

        // do search filter
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email','subscribed'] );
        } // end - searchterms

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    //items = _sortBy( items, ['created_on'] );
                    break;
                case 'date-asc':
                    //items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, [(o) => o.email.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, [(o) => o.email.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        var results = { fields: ['firstname','lastname','email','subscribed'], data: [] };

        if ( items && isArrayExists( items ) ) {
            _forEach( items, contact => {
                let item = [];
 
                item.push( contact.first_name || '' );
                item.push( contact.last_name || '' );
                item.push( contact.email || '' );
                item.push( contact.subscribed || '' );

                results.data.push(item);
            });
        } // end - events

        var csv = Papa.unparse( results, { delimiter: ',' } );
        var blob = new Blob( [csv] , { type: 'text/csv;charset=utf-8;' }); //new way
        var uri = window.URL.createObjectURL(blob);
        var link = document.createElement("a");

        link.setAttribute("href", uri);
        link.setAttribute("target", '_blank');
        link.setAttribute("download", "export_contacts_from_"+list_id+".csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    handleFormUpdate = (newValue,key) => {
        const { modalData } = this.state;
        var newData = ( modalData ? cloneCollections( modalData ) : {} );
        newData[key] = newValue;
        this.setState({ modalData: newData });
    }

    handleAddNew = () => {
        const { history } = this.props;
        const { modalData } = this.state;
        var error = false,
            formData = cloneCollections( modalData );

        if ( !( modalData && modalData.lists && isArrayExists( modalData.lists ) ) ) {
            error = 'Please select at least one list';
        } // end - modalData.email

        if ( !( modalData && modalData.email && validateEmail( modalData.email ) ) ) {
            error = 'Please enter a valid email address';
        } // end - modalData.email

        if ( error ) {
            triggerErrorAlert(error);
        } else {
            // re-compile the lists for API
            formData.lists = [];
            _forEach( modalData.lists, list => {
                formData.lists.push({ id: list });
            });

            this.props.dispatch(addNewContact(formData,history,this.getQueryProps()));
        } // end - error
    }

    handleEdit = (id,event) => {
        const { changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            var answer = window.confirm("You have unsaved changes that will be lost if you decide to continue.\n\nAre you sure you want to leave this page?");
            // reset status if answer is yes
            if ( answer ) {
                this.props.dispatch(appChangesReset());
                this.props.history.push("/contacts/"+id);
            }
        } else {
            this.props.history.push("/contacts/"+id);
        }
        
    }

    handleDelete = () => {
        const { list_id } = this.props;
        const { deleteModal } = this.state;
        // perform delete
        this.props.dispatch(deleteContact(deleteModal.id,list_id,this.getQueryProps()));
    }

    handleRemoveFromList = () => {
        const { list_id } = this.props;
        const { deleteFromListModal } = this.state;
        // perform delete from list
        this.props.dispatch(deleteContactFromList(deleteFromListModal.id,list_id,this.getQueryProps()));
    }

    handleDeleteClicked(item,event) {
        const { changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            alert("Please save changes first before proceed");
        } else {
            this.setState({ openDeleteModal: true, deleteModal: item });
        } // end = changesNotSaved
    }

    handleRemoveFromListClicked(item,event) {
        const { changesNotSaved } = this.props;
        event.preventDefault();

        // if changes not saved - trigger warning        
        if ( changesNotSaved ) {
            alert("Please save changes first before proceed");
        } else {
            this.setState({ openDeleteFromListModal: true, deleteFromListModal: item });
        } // end = changesNotSaved
    }

    getQueryProps = () => {
        return false;
    }

    reorganizeData() {
        const { searchterms, sortBy, filterBy, perPage, page } = this.state;
        const { contacts } = this.props;
        var items = ( contacts ? cloneCollections( contacts ) : [] ),
            total = _size( items );

        // do search
        if ( searchterms && !_isEmpty( searchterms ) ) {
            items = doArraySearch( items, searchterms, ['name','email','subscribed'] );
			total = _size( items );
        } // end - searchterms

        // do sort
        if ( sortBy && !_isEmpty( sortBy ) && !_isEmpty( items ) ) {
            switch( sortBy ) {
                case 'date-desc':
                    //items = _sortBy( items, ['created_on'] );
                    break;
                case 'date-asc':
                    //items = _sortBy( items, ['created_on'] );
                    items = _reverse( items );
                    break;
                case 'name-desc':
                    items = _sortBy( items, ['name'] );
                    items = _reverse( items );
                    break;
                case 'name-asc':
                    items = _sortBy( items, ['name'] );
                    break;
                case 'email-desc':
                    items = _sortBy( items, [(o) => o.email.toLowerCase()] );
                    items = _reverse( items );
                    break;
                case 'email-asc':
                    items = _sortBy( items, [(o) => o.email.toLowerCase()] );
                    break;
            }
        } // end - sortBy

        // do pagination
        items = doPagination( items, perPage, page );

        return { items, total };
    }

    renderTableActions = (total) => {
        const { sortBy, perPage, searchterms } = this.state;
        return <TableBar
                sortBy={sortBy}
                perPage={perPage}
                searchterms={searchterms}
                sortByOptions={[
                    { value: 'date-desc', label: 'Recent Entries first' },
                    { value: 'date-asc', label: 'Oldest Entries first' },
                    { value: 'name-asc', label: 'Name ( A - Z)' },
                    { value: 'name-desc', label: 'Name ( Z - A )' },
                    { value: 'email-asc', label: 'Email ( A - Z)' },
                    { value: 'email-desc', label: 'Email ( Z - A )' }
                ]}
                rightButtons={ total ? [
                    <InfoButton minWidth="128px" key="export" style={{ marginRight: "5px", paddingLeft: "25px", paddingRight: "25px" }} onClick={this.handleExport}><i className="fa fa-file-excel-o"></i>Export {total} contact(s) to CSV</InfoButton>
                ] : null }
                onEntriesChange={(newPerPage) => this.setState({ perPage: newPerPage, page: 1 })}
                onSearchChange={(terms) => this.setState({ searchterms: terms, page: 1 })}
                onSortByChange={(newSortBy) => this.setState({ sortBy: newSortBy, page: 1 })}
                style={{ marginBottom: "20px" }} />
    }

    renderPagination = (totalCount) => {
        const { perPage, page } = this.state;
        return <Pagination 
                    total={totalCount}
                    perPage={perPage} 
                    page={page}
                    style={{ marginTop: "20px" }}
                    onPageChange={(newPage) => this.setState({ page: newPage }) } />
    }

    renderBody = (items) => {
        const { classes } = this.props;
        return (
        <TableBody>
            { items && isArrayExists( items ) ? items.map(item => {
                return (
                <TableRow key={item.id}>
                    <TableCell className={classes.bodycell}>
                        <WrapWord>{item.email || ''}</WrapWord>
                    </TableCell>
                    <TableCell className={classes.bodycell}>{item.name || ''}</TableCell>
                    <TableCell className={classes.bodycell}>{item.subscribed && item.subscribed == 'yes' ? <SuccessTag>YES</SuccessTag> : <ErrorTag>NO</ErrorTag>}</TableCell>
                    <TableCell className={classes.bodycell}>{item.bounced && item.bounced == 'yes' ? <ErrorTag>YES</ErrorTag> : <SuccessTag>NO</SuccessTag>}</TableCell>
                    <TableCell className={classes.bodycell}>
                        <ButtonGroup>
                            <AInfoLink href={"/contacts/"+item.id} size="small" onClick={this.handleEdit.bind(this,item.id)}><i className="fa fa-edit"></i>Edit</AInfoLink>
                            <IndigoButton size="small" onClick={this.handleRemoveFromListClicked.bind(this,item)}><i className="fa fa-trash"></i>Remove From List</IndigoButton>
                            <ErrorButton size="small" onClick={this.handleDeleteClicked.bind(this,item)}><i className="fa fa-trash"></i>Delete Permanently</ErrorButton>
                        </ButtonGroup>
                    </TableCell>
                </TableRow>
                )
            }) : (
                <TableRow>
                    <TableCell className={classes.bodycell}>No Contact(s) found.</TableCell>
                </TableRow>
            ) }
        </TableBody>
        )
    }

    renderHeader = () => {
        const { classes } = this.props;
        return (
        <TableHead>
            <TableRow>
                <TableCell className={classes.headcell}>Email</TableCell>
                <TableCell className={classes.headcell}>Name</TableCell>
                <TableCell className={classes.headcell}>Subscribed?</TableCell>
                <TableCell className={classes.headcell}>Bounced?</TableCell>
                <TableCell className={classes.headcell} style={{ width: "20%" }}>Actions</TableCell>
            </TableRow>
        </TableHead>
        )
    }

    render() {
        const { openDeleteModal, deleteModal, openDeleteFromListModal, deleteFromListModal } = this.state;
        const { items, total } = this.reorganizeData();
        return (
        <div>

            <ModelDelete
                open={openDeleteFromListModal}
                title={( deleteFromListModal && deleteFromListModal.name ? `Are you sure you want to remove this contact ( ${deleteFromListModal.name} ) from this list?` : false )}
                onClose={() => this.setState({ openDeleteFromListModal: false, deleteFromListModal: false })}
                onDelete={this.handleRemoveFromList} />

            <ModelDelete
                open={openDeleteModal}
                title={( deleteModal && deleteModal.name ? `Are you sure you want to delete this contact ( ${deleteModal.name} )?` : false )}
                onClose={() => this.setState({ openDeleteModal: false, deleteModal: false })}
                onDelete={this.handleDelete} />

            {this.renderTableActions(total)}
            <Paper elevation={2} style={{ backgroundColor: "#fff" }}>
                <Table>
                    {this.renderHeader()}
                    {this.renderBody(items)}
                </Table>
            </Paper>
            {this.renderPagination(total)}

        </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        changesNotSaved: state.misc && state.misc.changes_made || null
    }
}

export default compose(
    connect(mapStateToProps),
    withStyles(useStyles),
    withRouter
)(ListContacts);