import _random from 'lodash/random';

import { 
    MISC_CHANGES_NOT_SAVED,
    MISC_CHANGES_RESET,
} from '../actions/types';

const initialState = {
    changes_made: false
};

export const misc = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case MISC_CHANGES_RESET:
            return {
                ...state,
                changes_made: false,
            };
        case MISC_CHANGES_NOT_SAVED:
            return {
                ...state,
                changes_made: true,
            };
        default:
            return state;
    }
}