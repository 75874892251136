import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import Templates from './templates';

import { getEmailTemplates } from '../../actions/m_email_templates';
import { getOrgs } from '../../actions/m_orgs';

class EmailTemplates extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        const { orgsList } = this.props;

        // get templates
        this.props.dispatch(getEmailTemplates());

        // get orgs
        if ( !orgsList ) {
            this.props.dispatch(getOrgs());
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { emailTemplates, randNum } = this.props;

        if ( emailTemplates && randNum && randNum != this.state.randNum )
            this.setState({ randNum });

    }

    renderContents() {
        const { emailTemplates, orgsList, authData } = this.props;
        return <Templates 
                orgs={orgsList} 
                templates={emailTemplates}
                authData={authData} />;
    }

    render() {
        const { emailTemplates, randNum } = this.props;
        return <AppWrapper 
                title="Email Templates"
                subtitle="Maintenance"
                onLoad={( !( emailTemplates && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        emailTemplates: state.emailtemplates && state.emailtemplates.list || null,
        randNum: state.emailtemplates && state.emailtemplates.rand || null,
        orgsList: state.maintenance && state.maintenance.orgs || false,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(EmailTemplates);