import _random from 'lodash/random';

import { 
    GET_REPORT_EMAIL_BROADCAST,
    RESET_REPORT_EMAIL_BROADCAST,
    GET_REPORT_RECENT_EMAIL_BROADCAST
} from '../actions/types';

const initialState = {
    email_broadcast: null,
    rand: false
};

export const reports = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_REPORT_EMAIL_BROADCAST:
            return {
                ...state,
                email_broadcast: ( payload.report || {} ),
                rand: _random(1,9999)
            };
        case GET_REPORT_RECENT_EMAIL_BROADCAST:
            return {
                ...state,
                recent_email_broadcast: ( payload.report || {} ),
                rand: _random(1,9999)
            };
        case RESET_REPORT_EMAIL_BROADCAST:
                return {
                    ...state,
                    email_broadcast: null,
                    rand: false
                };
        default:
            return state;
    }
}