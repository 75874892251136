import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	TOOLS_IMPORT_CONTACTS,
	GLOBAL_ERROR_TRIGGER
} from './types';

import { toggleLoader } from './global';
import { triggerErrorAlert, triggerSuccessAlert } from '../helpers/alert';
import { appChangesReset } from './misc';

import { fb_performImport } from '../firebase/import_contacts';

export const importContacts = (formData,type) => {
	return dispatch => {
        dispatch(toggleLoader(true));

		fb_performImport(formData,type)
		.then(returnData => {
            // push to list
            dispatch({ type: TOOLS_IMPORT_CONTACTS });
            dispatch(toggleLoader(false));
            dispatch(appChangesReset());
            triggerSuccessAlert("Data Imported");
        })
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to export data' ) );
			triggerErrorAlert(errMsg);
		});

	}
}