import axios from 'axios';
import _isEmpty from 'lodash/isEmpty';
import _isObject from 'lodash/isObject';
import _forEach from 'lodash/forEach';
import _random from 'lodash/random';
import qs from 'qs';

import { FIREBASE_CLOUD_API } from '../constants';
import { isArrayExists } from './validation';

/* perform axios via promise */
export const doPromise = function( props ) {
    const { idToken, method, url, formData, params, api_url } = props;
    return new Promise((resolve,reject) => {

        var paramString = '';
        if ( isArrayExists( params ) ) {
            var paramCount = 1;
            _forEach( params, function(param) {
                paramString += ( paramCount > 1 ? '&' : '' ) + param.key + "=" + param.value;
                paramCount++;
            });
        }

        var query = { 
            method: method, 
            baseURL: api_url || FIREBASE_CLOUD_API, 
            url: '/' + url + ( idToken ? '?token=' + idToken + ( !_isEmpty( paramString ) ? '&' + paramString : '' ) : ( !_isEmpty( paramString ) ? '?' + paramString : '' ) ),
        };

        if ( formData && !_isEmpty( formData ) ) {
            query['headers'] = { 'Content-Type': 'application/x-www-form-urlencoded' };
            query['data'] = qs.stringify(formData)
        }

        axios(query)
        .then(function(response) {
            resolve(response);
        })
        .catch(function(error) {
            reject(error);
        });

    });
};