import React from 'react';
import styled from "styled-components";
import GrapesJS from 'grapesjs';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import _isEmpty from 'lodash/isEmpty';
import _random from 'lodash/random';
import _last from 'lodash/last';

import Controller from './controller';
import TemplateEditor from '../MaintenanceEmailTemplate/editor';

import MediaLibrary from '../../components/MediaLibrary';
import FormInput from '../../components/FormInput';

import { triggerErrorAlert } from '../../helpers/alert';
import { isArrayExists } from '../../helpers/validation';
import { cloneCollections } from '../../helpers/data';

class StepCreate extends React.Component {

    handleFormUpdate = (newValue,name) => {
        const { formData } = this.props;
        var newData = ( formData && !_isEmpty(formData) ? cloneCollections(formData) : {} );
        newData[name] = newValue;

        // push updates to parent
        if ( this.props.onFormUpdate ) {
            this.props.onFormUpdate(newData);
        }
    }

    handleStepChange = (direction) => {
        const { formData } = this.props;
        
        if ( direction === 'back' ) {
            this.props.goBack();
        } else if ( direction === 'next' ) {
            // do error check before proceed
            var error = false;

            // if ( !( formData.label && !_isEmpty( formData.label ) ) ) {
            //     error = 'Please insert a message label';
            // } // end - formData

            if ( error ) {
                triggerErrorAlert(error);
            } else {
                const editor = ( GrapesJS.editors && isArrayExists( GrapesJS.editors ) ? _last( GrapesJS.editors ) : false );

                // update the template contents
                var newData = cloneCollections( formData );
                newData['template_html'] = editor.getHtml();
                newData['template_css'] = editor.getCss();
                newData['template_js'] = editor.getJs();

                this.props.onFormUpdate(newData);

                this.props.goNext();
            } // end - error

        }
    }

    render() {
        const { formData, step, randNum } = this.props;
        return (
        <div style={{ maxWidth: "100%", marginLeft: "auto", marginRight: "auto" }}>
            
            <Paper elevation={2} style={{ padding: "30px", background: "#fff" }}>

                <Typography variant="h4" style={{ textAlign: "center", marginBottom: "30px" }}>Modify Email Content</Typography>

                <div style={{ margin: "20px 0px" }}>
                    <MediaLibrary />
                </div>
                
                <TemplateEditor 
                    template={{
                        contents_html: ( formData.template_html || '' ),
                        contents_css: ( formData.template_css || '' ),
                        contents_js: ( formData.template_js || '' )
                    }}
                    randNum={randNum} />

            </Paper>

            <Controller step={step} onClick={this.handleStepChange} />

        </div>
        );
    }

}

export default StepCreate;