import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_LISTS,
	GET_LIST,
	GET_LIST_CONTACTS,
	ADD_LIST,
	EDIT_LIST,
	DELETE_LIST,
	LISTS_RESET,
	IMPORT_CONTACTS,
	GLOBAL_ERROR_TRIGGER
} from './types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from './global';
import { triggerErrorAlert, triggerSuccessAlert } from '../helpers/alert';
import { appChangesReset } from './misc';

import { fb_getLists, fb_getList, fb_getListContacts, fb_addList, fb_updateList, fb_deleteList, fb_importContacts, fb_removeContactsFromList } from '../firebase/lists';

export const getLists = () => {
	return dispatch => {
		// reset the reducer
		dispatch({ type: LISTS_RESET });

		fb_getLists()
		.then(lists => {
			dispatch({
				type: GET_LISTS,
				payload: { list: lists }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_LISTS,
				payload: { list: [] }
			});
		});

	}
}

export const getList = (id) => {
	return dispatch => {

		fb_getList(id)
		.then(list => {
			dispatch({
				type: GET_LIST,
				payload: { single: list }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list data' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_LIST,
				payload: { single: null }
			});
		});

	}
}

export const getListContacts = (id) => {
	return dispatch => {

		fb_getListContacts(id)
		.then(contacts => {
			dispatch({
				type: GET_LIST_CONTACTS,
				payload: { list_contacts: contacts }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list data' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_LIST_CONTACTS,
				payload: { list_contacts: [] }
			});
		});

	}
}

export const addNewList = (formData,history) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addList(formData)
		.then( results => {
			if ( results && results.new_list_id && !_isEmpty( results.new_list_id ) ) {
				fb_getLists()
				.then(lists => {
					// push to list
					dispatch({ type: GET_LISTS, payload: { list: lists } });

					// updated
					dispatch({ type: ADD_LIST, payload: { new_list_id: results.new_list_id } });
					dispatch(toggleModalProcessing(false));
					triggerSuccessAlert("List Added");

					// redirect to list page
					history.push("/lists/" + results.new_list_id);
				})
			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new list ID');
			}
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new list' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editList = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateList(formData)
		.then( results => {
			fb_getList(formData.id)
			.then(list => {
				// push to list
				dispatch({ type: GET_LIST, payload: { single: list } });

				// updated
				dispatch({ type: EDIT_LIST });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Data Updated");

				// update
				fb_getLists()
				.then(lists => {
					dispatch({ type: GET_LISTS, payload: { list: lists } });
				})
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update list' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteList = (id) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteList(id)
		.then( results => {
			fb_getLists()
			.then(lists => {
				// push to list
				dispatch({ type: GET_LISTS, payload: { list: lists } });

				// trigger updated
				dispatch({ type: DELETE_LIST });
				dispatch(toggleModalDeleting(false));
				triggerSuccessAlert("List Deleted");
			})
		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete list' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const importContacts = (id,formData,replace) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_importContacts(id,formData,replace)
		.then( results => {

			fb_getList(id)
			.then(list => {
				// push to list
				dispatch({ type: GET_LIST, payload: { single: list } });

				// updated
				dispatch({ type: IMPORT_CONTACTS });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Contacts import process started");
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update list' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const removeContactsFromList = (id,list_contacts) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_removeContactsFromList(id,list_contacts)
		.then( results => {

			fb_getList(id)
			.then(list => {
				// push to list
				dispatch({ type: GET_LIST, payload: { single: list } });

				// updated
				dispatch({ type: IMPORT_CONTACTS });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Contacts removal process started");
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to remove contacts' ) );
			triggerErrorAlert(errMsg);
		});

	}
}