import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _split from 'lodash/split';

import { isArrayExists } from './validation';

// get current user orgs
export const getUserOrgs = function(authData) {
    var orgs = [];

    if ( authData && authData.orgs && !_isEmpty( authData.orgs ) ) {
        var splitData = _split( authData.orgs, ',' );
        if ( splitData && isArrayExists( splitData ) ) {
            _forEach( splitData, org => {
                orgs.push({ id: org });
            });
        } // end - splitData
    } // end - orgs

    return orgs;
}

// get first org of user orgs
export const getUserFirstOrg = function(authData,data) {
    var orgs = getUserOrgs(authData);
    if ( data == 'obj' ) {
        return ( orgs && isArrayExists( orgs ) && orgs[0] && !_isEmpty( orgs[0] ) ? orgs[0] : null );
    } else {
        return ( orgs && isArrayExists( orgs ) && orgs[0] && orgs[0].id && !_isEmpty( orgs[0].id ) ? orgs[0].id : null );
    }
}

// check if current user is superadmin
export const isSuperAdmin = function(authData) {
    return ( authData && authData.type && authData.type === 'superadmin' ? true : false );
}

// check if current user is admin
export const isAdmin = function(authData) {
    return ( authData && authData.type && ( authData.type === 'superadmin' || authData.type === 'admin' ) ? true : false );
}

// check if current user have the access rights
export const hasAccessRights = function(authData,accessRights) {
    return ( authData && !_isEmpty( authData ) && (
        ( authData.type === 'admin' || authData.type === 'superadmin' || !accessRights ) || 
        ( accessRights && authData.accesses && !_isEmpty( authData.accesses ) && _find( accessRights, (r) => authData.accesses.indexOf(r) >= 0 ) )
    ) ? true : false );
}