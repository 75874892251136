import _random from 'lodash/random';

import { 
	GET_CONTACTS,
	GET_CONTACT,
	ADD_CONTACT,
	EDIT_CONTACT,
	DELETE_CONTACT
} from '../actions/types';

const initialState = {
    contacts: null,
    contact: null,
    new_contact_id: null,
    updated: false,
    rand: false
};

export const contacts = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CONTACTS:
            return {
                ...state,
                contacts: ( payload.list || [] ),
                rand: _random(1,9999)
            };
        case GET_CONTACT:
            return {
                ...state,
                contact: ( payload.single || {} ),
                rand: _random(1,9999)
            };
        case ADD_CONTACT:
            return {
                ...state,
                new_contact_id: ( payload.id || false ),
                rand: _random(1,9999)
            };
        case EDIT_CONTACT:
        case DELETE_CONTACT:
            var randNum = _random(1,9999);
            return {
                ...state,
                updated: randNum,
                rand: randNum
            };
        default:
            return state;
    }
}