import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { GEditor } from 'grapesjs-react';
import GrapesJS from 'grapesjs';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _forEach from 'lodash/forEach';
import _last from 'lodash/last';

import './editor.css';

import DotsLoader from '../../components/DotsLoader';

import { isArrayExists } from '../../helpers/validation';

import { getEmailTemplate } from '../../actions/m_email_templates';
import { appChangesMade } from '../../actions/misc';

import { FIREBASE_CLOUD_API } from '../../constants';

class TemplateEditor extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        this.setComponents();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ( this.isUpdated(prevState) ) {
            this.setComponents();
        }
    }

    componentWillUnmount() {
        const editor = ( GrapesJS.editors && isArrayExists( GrapesJS.editors ) ? _last( GrapesJS.editors ) : false );
        if ( editor ) {
            // remove listener
            editor.off('update', this.listenForUpdates);

            // destory editor
            //editor.destroy();
        } // end - editor
    }

    setComponents = () => {
        const { template, randNum, isPreview } = this.props;

        setTimeout(() => {

            // set html components
            var htmlContents = ( template && template.contents_css && !_isEmpty( template.contents_css ) ? '<style type="css">'+template.contents_css+'</style>' : '' ) + ( template && template.contents_html && !_isEmpty( template.contents_html ) ? template.contents_html : '' );

            //console.log( GrapesJS.editors );

            // get editor
            const editor = ( GrapesJS.editors && isArrayExists( GrapesJS.editors ) ? _last( GrapesJS.editors ) : false );

            if ( editor ) {
                // remove changes listener
                editor.off('update', this.listenForUpdates);

                editor.setComponents(htmlContents);

                // trigger preview mode
                if ( isPreview ) {
                    editor.runCommand('core:preview');
                } // end - isPreview

                this.setState({ randNum });

                // add changes listener
                setTimeout(() => {
                    editor.on('update', this.listenForUpdates);
                },250);
            } // end - editor

        },500);
                    
    }

    listenForUpdates = () => {
        const { isPreview } = this.props;
        if ( !isPreview )
            this.props.dispatch(appChangesMade());
    }

    isUpdated = (prevState) => {
        return ( this.props.randNum && this.props.randNum != prevState.randNum ? true : false );
    }

    isLoaded = () => {
        return ( this.props.randNum && this.props.randNum == this.state.randNum ? true : false );
    }

    render() {
        return (
        <div style={( !this.isLoaded() ? {
            opacity: '0.3',
            cursor: 'default',
            pointerEvents: 'none'
        } : null )}>
            <GEditor
                storageManager={{
                    autosave: false,
                    autoload: false,  
                }}
                newsletter={true} />
        </div>
        );
    }

}

export default compose(
    connect(),
    withRouter
)(TemplateEditor);