import * as firebase from 'firebase/app';
import shortid from 'shortid';
import _isEmpty from 'lodash/isEmpty';

import { getIDToken } from './auth';
import { doPromise } from '../helpers/action';
import { isArrayExists } from '../helpers/validation';

/* helpers start */
const deleteFileFromStorage = (path) => {
    return new Promise((resolve,reject) => {
        if ( path && !_isEmpty(path) ) {
            const storageRef = firebase.storage().ref();
            storageRef.child(path).delete()
            .then(function() {
                resolve('deleted'); 
            }).catch(function(error) {
                resolve('deleted');
            });
        } else {
            resolve('deleted');
        } // end - path
    })
}


/* helper end */

export const fb_getAllMedia = (callback) => {

    firebase.database().ref("file_storage").on("value", snapshot => {
        var list = [];

        if ( snapshot.exists() && snapshot.hasChildren() ) {
            snapshot.forEach( childSnapshot => {
                var val = childSnapshot.val(),
                    file = {
                        id: childSnapshot.key,
                        name: val.name || '',
                        url: val.url || '',
                        path: val.path || '',
                        size: val.size || '',
                        type: val.type || '',
                        created_on: val.created_on || false,
                        orgs: []
                    };

                if ( childSnapshot.child("orgs").exists() && childSnapshot.child("orgs").hasChildren() ) {
                    childSnapshot.child("orgs").forEach( orgSnapshot => {
                        file.orgs.push({ id: orgSnapshot.key });
                    });
                } // end - childnsnapshot

                list.push(file);
            });
        } // end - snapshot

        callback( list );
    });
    
}

export const fb_uploadNewMedia = (file,user) => {
    return new Promise((resolve,reject) => {

        const storageRef = firebase.storage().ref();

        var file_id = shortid.generate(),
            filePath = 'file_storage/' + file_id + '_' + file.name,
            metaData = {
                uid: user.uid,
                contentType: ( file.type || '' ),
                orgs: ( user.orgs || '' )
            };

        const uploadTask = storageRef.child(filePath).put(file, metaData);

        uploadTask.on('state_changed', function(snapshot){
            // Observe state change events such as progress, pause, and resume
        }, function(error) {
            reject(error);
        }, function() {
            // Handle successful uploads on complete
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {

                getIDToken()
                .then(idToken => {

                    doPromise({
                        idToken,
                        method: 'post',
                        url: 'mediaLibrary',
                        params: [{ key: 'action', value: 'add' }],
                        formData: {
                            id: file_id,
                            name: file.name,
                            url: downloadURL,
                            path: filePath,
                            size: file.size || 0,
                            type: ( file.type || '' )
                        }
                    })
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(error => {
                        reject(error);
                    });

                })
                .catch(error => {
                    reject(error);
                });              


            });
        });

    })
}

export const fb_editMedia = () => {
    return new Promise((resolve,reject) => {

        

    })
}

export const fb_deleteMedia = (file) => {
    return new Promise((resolve,reject) => {

        getIDToken()
        .then(idToken => {

            doPromise({
                idToken,
                method: 'post',
                url: 'mediaLibrary',
                params: [{ key: 'action', value: 'delete' }],
                formData: { id: file.id }
            })
            .then(res => {
                deleteFileFromStorage( file.path )
                .then( results => {
                    resolve('done');
                });
            })
            .catch(error => {
                reject(error);
            });

        })
        .catch(error => {
            reject(error);
        });

    })
}