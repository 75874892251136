import _random from 'lodash/random';
import _isEmpty from 'lodash/isEmpty';

import {
	GET_CONTACTS,
	GET_CONTACT,
	ADD_CONTACT,
	EDIT_CONTACT,
	DELETE_CONTACT,
	GET_LISTS,
	GET_LIST_CONTACTS,
	GLOBAL_ERROR_TRIGGER
} from './types';

import { toggleModalDeleting, toggleModalProcessing, toggleLoader } from './global';
import { triggerErrorAlert, triggerSuccessAlert } from '../helpers/alert';
import { appChangesReset } from './misc';

import { fb_getContacts, fb_getContactsLive, fb_getContact , fb_addContact, fb_updateContact, fb_deleteContact, fb_deleteContactFromList } from '../firebase/contacts';
import { fb_getLists, fb_getListContacts } from '../firebase/lists';

export const getContactsLive = () => {
	return dispatch => {

		fb_getContactsLive(list => {
			dispatch({
				type: GET_CONTACTS,
				payload: { list }
			});
		});  

	}
}

export const getContacts = (props) => {
	return dispatch => {

		fb_getContacts(props)  
		.then(list => {
			dispatch({
				type: GET_CONTACTS,
				payload: { list }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve list' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_CONTACTS,
				payload: { list: [] }
			});
		});

	}
}

export const getContact = (id) => {
	return dispatch => {

		fb_getContact(id)  
		.then(single => {
			dispatch({
				type: GET_CONTACT,
				payload: { single }
			});
		})
		.catch(error => {
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to retrieve contact data' ) );
			triggerErrorAlert(errMsg);
			dispatch({
				type: GET_CONTACT,
				payload: { single: {} }
			});
		});

	}
}

export const addNewContact = (formData,history,props) => {
	return dispatch => {
		var apiNum = _random(1,9999);
		dispatch(toggleModalProcessing(true,apiNum));

		fb_addContact(formData)
		.then( results => {
			if ( results && results.new_contact_id && !_isEmpty( results.new_contact_id ) ) {

				fb_getContact( results.new_contact_id )
				.then( single => {

					// updated
					dispatch({ type: GET_CONTACT, payload: { single } });
					dispatch(toggleModalProcessing(false));
					triggerSuccessAlert("Contact Added");

					// redirect to contact page
					history.push("/contacts/" + results.new_contact_id);

					// get updated lists
					fb_getLists()
					.then(lists => {
						dispatch({ type: GET_LISTS, payload: { list: lists } });
					});

				});

				// don't need this - since we're using live data
				// fb_getContacts(props)
				// .then(list => {
				// 	// push to list
				// 	dispatch({ type: GET_CONTACTS, payload: { list } });
				// })

			} else {
				dispatch(toggleModalProcessing(false,apiNum));
				triggerErrorAlert('Missing new contact ID');
			}
		})
		.catch( error => {
			dispatch(toggleModalProcessing(false,apiNum));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to add new contact' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const editContact = (formData) => {
	return dispatch => {
		dispatch(toggleLoader(true));

		fb_updateContact(formData)
		.then( results => {
			fb_getContact(formData.id)
			.then(single => {
				// push to list
				dispatch({ type: GET_CONTACT, payload: { single } });

				// updated
				dispatch({ type: EDIT_CONTACT });
				dispatch(toggleLoader(false));
				dispatch(appChangesReset());
				triggerSuccessAlert("Contact Updated");

				// get updated lists
				fb_getLists()
				.then(lists => {
					dispatch({ type: GET_LISTS, payload: { list: lists } });
				});

				// don't need this - since we're using live data
				// get updated contacts
				// fb_getContacts(false)
				// .then(list => {
				// 	dispatch({ type: GET_CONTACTS, payload: { list } });
				// });
			})
		})
		.catch( error => {
			dispatch(toggleLoader(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to update contact' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteContact = (id,list_id,props) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteContact(id)
		.then( results => {
			// get updated list's contacts if list_id provided
			return ( list_id && !_isEmpty( list_id ) ? fb_getListContacts(list_id) : false );
		})
		.then( contacts => {

			// push updated contacts if found
			if ( contacts )
				dispatch({ type: GET_LIST_CONTACTS, payload: { list_contacts: contacts } });

			// trigger updated
			dispatch({ type: DELETE_CONTACT });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Contact Deleted");

			// get updated lists
			fb_getLists()
			.then(lists => {
				dispatch({ type: GET_LISTS, payload: { list: lists } });
			});

			// don't need this - since we're using live data
			// if ( props ) {
			// 	fb_getContacts(props)
			// 	.then(list => {
			// 		// push to list
			// 		dispatch({ type: GET_CONTACTS, payload: { list } });			
			// 	})
			// } // end - props

		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete list' ) );
			triggerErrorAlert(errMsg);
		});

	}
}

export const deleteContactFromList = (id,list_id,props) => {
	return dispatch => {
		dispatch(toggleModalDeleting(true));

		fb_deleteContactFromList(id,list_id)
		.then( results => {
			// get updated list's contacts if list_id provided
			return ( list_id && !_isEmpty( list_id ) ? fb_getListContacts(list_id) : false );
		})
		.then( contacts => {

			// push updated contacts if found
			if ( contacts )
				dispatch({ type: GET_LIST_CONTACTS, payload: { list_contacts: contacts } });

			// trigger updated
			dispatch({ type: DELETE_CONTACT });
			dispatch(toggleModalDeleting(false));
			triggerSuccessAlert("Contact Deleted From List");

			// get updated lists
			fb_getLists()
			.then(lists => {
				dispatch({ type: GET_LISTS, payload: { list: lists } });
			});

			// don't need this - since we're using live data
			// if ( props ) {
			// 	fb_getContacts(props)
			// 	.then(list => {
			// 		// push to list
			// 		dispatch({ type: GET_CONTACTS, payload: { list } });			
			// 	})
			// } // end - props

		})
		.catch( error => {
			dispatch(toggleModalDeleting(false));
			var errMsg = ( error && error.response && error.response.data && error.response.data.message ? error.response.data.message : ( error.message || 'Unable to delete list' ) );
			triggerErrorAlert(errMsg);
		});

	}
}