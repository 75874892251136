import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import Roles from './roles';

import { getRoles } from '../../actions/m_roles';

class MaintenanceRoles extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {

        // get roles
        this.props.dispatch(getRoles());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { rolesList, randNum } = this.props;

        if ( rolesList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });

    }

    renderContents() {
        const { rolesList } = this.props;
        return <Roles roles={rolesList} />;
    }

    render() {
        const { rolesList, randNum } = this.props;
        return <AppWrapper 
                title="Roles & Privilege"
                subtitle="Maintenance"
                onLoad={( !( rolesList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        rolesList: state.maintenance && state.maintenance.roles || null,
        randNum: state.maintenance && state.maintenance.rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceRoles);