import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import Organisations from './orgs';

import { getOrgs } from '../../actions/m_orgs';

class MaintenanceOrganisations extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {

        // get regions
        this.props.dispatch(getOrgs());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { orgsList, randNum } = this.props;

        if ( orgsList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    renderContents() {
        const { orgsList } = this.props;
        return <Organisations regions={orgsList} />;
    }

    render() {
        const { orgsList, randNum } = this.props;
        return <AppWrapper 
                title="Organisations"
                subtitle="Maintenance"
                onLoad={( !( orgsList && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        orgsList: state.maintenance && state.maintenance.orgs || null,
        randNum: state.maintenance && state.maintenance.rand || null,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(MaintenanceOrganisations);