import React from 'react';
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import AppWrapper from '../../components/AppWrapper';
import Broadcasts from './broadcasts';

import { cloneCollections } from '../../helpers/data';
import { isArrayExists } from '../../helpers/validation';
import { isSuperAdmin } from '../../helpers/auth';

import { getBroadcasts } from '../../actions/email_broadcast';
import { getOrgs } from '../../actions/m_orgs';

class EmailBroadcastPage extends React.Component {

    state = {
        randNum: false
    };

    componentDidMount() {
        const { orgsList } = this.props;

        // get broadcasts
        this.props.dispatch(getBroadcasts());

        // get orgs
        if ( !orgsList ) {
            this.props.dispatch(getOrgs());
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { emailBroadCasts, orgsList, randNum } = this.props;

        // once all the data is loaded
        if ( emailBroadCasts && orgsList && randNum && randNum != this.state.randNum )
            this.setState({ randNum });
    }

    handleRefresh = () => {
        // get lists
        this.props.dispatch(getBroadcasts());
        this.setState({ rand: false });
    }

    renderContents() {
        const { emailBroadCasts, orgsList, authData } = this.props;
        return (
        <Broadcasts 
            broadcasts={emailBroadCasts}
            orgs={orgsList} 
            authData={authData}
            onRefresh={this.handleRefresh} />
        );
    }

    render() {
        const { emailBroadCasts } = this.props;
        const { randNum } = this.state;
        return <AppWrapper 
                title="Email Broadcast"
                onLoad={( !( emailBroadCasts && randNum ) ? true : false )}
                contents={this.renderContents()} />;
    }

}

const mapStateToProps = state => {
    return {
        authData: state.auth && state.auth.user || null,
        emailBroadCasts: state.email_broadcasts && state.email_broadcasts.broadcasts || null,
        randNum: state.email_broadcasts && state.email_broadcasts.rand || null,
        orgsList: state.maintenance && state.maintenance.orgs || false,
    }
}

export default compose(
    connect(mapStateToProps),
    withRouter
)(EmailBroadcastPage);