import _endsWith from 'lodash/endsWith';
import _replace from 'lodash/replace';
import _isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import _isUndefined from 'lodash/isUndefined';
import _isInteger from 'lodash/isInteger';


// validate email
export const validateEmail = function(email) {
	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

// check whether string provided is valid phone number
export const isPhoneNumber = function(string) {
	var re = /^\+?([0-9 -]+)$/;
	return re.test(string);
}

// check whether string provided is valid string id
export const isStringID = function(string) {
	var re = /^([a-zA-Z0-9_-]+)$/;
	return re.test(string);
}

// check whether string provided is valid string name
export const isStringName = function(string) {
	var re = /^([a-zA-Z0-9 _-]+)$/;
	return re.test(string);
}

// check whether string provided is alphanumeric
export const isAlphanumeric = function(string) {
	var re = /^([a-zA-Z0-9]+)$/;
	return re.test(string);
}

// check whether string provided is numeric
export const isNumeric = function(string) {
	var re = /^([0-9]+)$/;
	return re.test(string);
}

// check whether value provided is timestamp
export const isTimeStamp = function(value) {
	return ( ( new Date(value) ).getTime() > 0 ? true : false );
};

// is array exists
export const isArrayExists = function(array) {
	return ( array && !_isEmpty( array ) && _isArray( array ) ? true : false );
}

// check whether value provided is empty or not array
export const arrayNotExists = function(array) {
	return ( !array || _isEmpty( array ) || !_isArray( array ) ? true : false );
}

// is array exists
export const isObjectExists = function(object) {
	return ( object && !_isEmpty( object ) && _isObject( object ) ? true : false );
}

// check whether string provided is price (max 2 decimal)
export const isPrice = function(string) {
	var valid = false,
		re = /^([0-9.]+)$/;
	if ( re.test(string) ) {
		valid = true;
	}

	return valid;
}

// safe unescape
export const  safeUnescape = function(string) {

	if ( string && !_isEmpty( string ) ) {
		string = _replace( string, '&amp;', '&' );
	} // end - string

	return string;
}